import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import { ChartData, Metric } from "./AnalyticsChart";

interface AnalyticsLineChartProps {
  chartData: ChartData[];
  versionIds: string[];
  metric: Metric;
  hexColors: string[];
}

export const AnalyticsLineChart: React.FC<AnalyticsLineChartProps> = ({
  chartData,
  versionIds,
  hexColors,
}) => {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          type="number"
          scale="time"
          domain={["dataMin", "dataMax"]}
          tickFormatter={(date) => new Date(date).toLocaleDateString()}
          ticks={generateXTicks(chartData)}
          tick={<CustomXAxisTick />}
        />
        <YAxis tick={<CustomYAxisTick />} axisLine={false} tickLine={false} />
        <Tooltip labelFormatter={(label) => new Date(label).toLocaleString()} />
        {versionIds.map((versionId, index) => (
          <Line
            key={versionId}
            type="monotone"
            dataKey={versionId}
            stroke={hexColors[index % hexColors.length]}
            dot={{
              r: 2,
              fill: hexColors[index % hexColors.length],
            }}
            strokeWidth={2}
            connectNulls
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

const CustomYAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-10}
        textAnchor="end"
        fill="#666"
        fontSize={14}
        fontFamily="Inter, sans-serif"
      >
        {payload.value}
      </text>
    </g>
  );
};

export const CustomXAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        fontSize={14}
        fontFamily="Inter, sans-serif"
      >
        {new Date(payload.value).toLocaleDateString()}
      </text>
    </g>
  );
};

export const generateXTicks = (data: ChartData[]): number[] => {
  const dataLength = data.length;
  const step = Math.max(1, Math.floor(dataLength / 5)); // Ensure at least 1 step
  const ticks: number[] = [];

  for (let i = 0; i < dataLength; i += step) {
    ticks.push(data[i].date);
  }

  // Ensure the last tick is always included
  if (ticks[ticks.length - 1] !== data[dataLength - 1]?.date) {
    ticks.push(data[dataLength - 1].date);
  }

  return ticks;
};
