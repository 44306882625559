import React from "react";

interface NavigationNoteCardProps {
  badge: string;
  message: string;
  cta: string;
  ctaLink: string;
  isCollapsed: boolean;
  clickedExit: () => void;
}

const NavigationNoteCard: React.FC<NavigationNoteCardProps> = ({
  badge,
  message,
  cta,
  ctaLink,
  isCollapsed,
  clickedExit,
}) => {
  return (
    <div
      className={`${
        isCollapsed ? "invisible" : ""
      } h-40 w-full p-3 bg-yellow-50 rounded-lg justify-start items-center gap-3 inline-flex`}
    >
      <div className="grow shrink basis-0 flex-col justify-start items-start gap-3 inline-flex">
        <div className="w-full justify-between items-center inline-flex">
          <div className="px-2.5 py-1 bg-yellow-600 rounded-md justify-center items-center gap-1 flex">
            <div className="text-center text-yellow-50 text-xs font-medium font-gooper leading-none">
              {badge}
            </div>
          </div>
          <button
            className="justify-end items-center flex"
            onClick={clickedExit}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path
                d="M5.90741 5L9.30409 1.60332C9.36538 1.54412 9.41427 1.47331 9.4479 1.39502C9.48153 1.31672 9.49924 1.23252 9.49998 1.14731C9.50072 1.0621 9.48448 0.977595 9.45221 0.898729C9.41995 0.819863 9.3723 0.748212 9.31204 0.687958C9.25179 0.627705 9.18014 0.580054 9.10127 0.547787C9.0224 0.515521 8.9379 0.499284 8.85269 0.500024C8.76748 0.500765 8.68328 0.518468 8.60498 0.5521C8.52669 0.585733 8.45588 0.634621 8.39668 0.695913L5 4.09259L1.60332 0.695913C1.48229 0.579017 1.32019 0.514333 1.15193 0.515796C0.983666 0.517258 0.822712 0.584748 0.70373 0.70373C0.584748 0.822712 0.517258 0.983666 0.515796 1.15193C0.514333 1.32019 0.579017 1.48229 0.695913 1.60332L4.09259 5L0.695913 8.39668C0.634621 8.45588 0.585733 8.52669 0.5521 8.60498C0.518468 8.68328 0.500765 8.76748 0.500024 8.85269C0.499284 8.9379 0.515521 9.0224 0.547787 9.10127C0.580054 9.18014 0.627705 9.25179 0.687958 9.31204C0.748212 9.3723 0.819863 9.41995 0.898729 9.45221C0.977595 9.48448 1.0621 9.50072 1.14731 9.49998C1.23252 9.49924 1.31672 9.48153 1.39502 9.4479C1.47331 9.41427 1.54412 9.36538 1.60332 9.30409L5 5.90741L8.39668 9.30409C8.51771 9.42098 8.67981 9.48567 8.84807 9.4842C9.01633 9.48274 9.17729 9.41525 9.29627 9.29627C9.41525 9.17729 9.48274 9.01633 9.4842 8.84807C9.48567 8.67981 9.42098 8.51771 9.30409 8.39668L5.90741 5Z"
                fill="#6A551B"
              />
            </svg>
          </button>
        </div>
        <div className="self-stretch h-24 flex-col justify-start items-start gap-3 flex">
          <div className="self-stretch text-gray-500 text-xs font-normal font-['General Sans'] leading-none">
            {message}
          </div>
          <a
            href={ctaLink}
            className="self-stretch text-blue-500 text-xs font-medium font-gooper underline leading-none"
          >
            {cta}
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavigationNoteCard;
