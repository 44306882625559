import React from "react";
import AuthFooter from "./Auth/AuthFooter";

const CatchAll: React.FC = () => {
  return (
    <div className="bg-general-desktop bg-cover bg-center min-h-screen flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center pt-24 gap-4">
        <div className="text-center text-indigo-50 text-9xl font-semibold font-gooper leading-none">
          404
        </div>
        <div className="text-center text-blue-950 text-4xl font-bold font-gooper leading-none">
          Something's missing.
        </div>
        <div className="text-center text-gray-50 text-lg font-normal leading-relaxed tracking-tight">
          Sorry, we can't find that page. You'll find lots to explore on the
          &nbsp;
          <a
            href="https://www.easybeam.ai"
            className="text-gray-300 hover:underline"
          >
            home page.
          </a>
        </div>
      </div>
      <AuthFooter textColor="text-gray-50" textHoverColor="text-gray-600" />
    </div>
  );
};

export default CatchAll;
