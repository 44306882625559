import { Portal } from "../../../models/Portal";
import { PortalVersion } from "../../../models/PortalVersion";
import Icon from "../../Icon";
import { ScoreBadge } from "../ScoreBadge";
import { StatusBadge } from "../StatusBadge";

export const VersionCard: React.FC<{
  portal: Portal;
  version: PortalVersion;
  selectedVersion: (version: PortalVersion) => void;
  currentlySelected?: PortalVersion;
  teamId: string;
}> = ({ portal, version, selectedVersion, currentlySelected, teamId }) => {
  const formatter = new Intl.DateTimeFormat("default", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
  const isSelected = currentlySelected?.id == version.id;
  let editedAt = "unknown";
  try {
    editedAt = formatter.format(version.modifiedAt);
  } catch (error) {
    console.error("Date error", error);
  }
  const className = isSelected
    ? "border-2 border-indigo-400"
    : "border border-gray-200";

  return (
    <div
      className={`w-48 hover:scale-110 bg-gray-0 items-center rounded-lg flex flex-col p-2 gap-4 shadow-lg ${className} transition-all duration-100 `}
    >
      <div className={`flex flex-row justify-between w-full`}>
        <StatusBadge portal={portal} version={version} />
        {version.averageReviewScore != null && (
          <ScoreBadge
            score={version.averageReviewScore}
            portalId={portal.id}
            versionId={version.id ?? ""}
            teamId={teamId}
          />
        )}
      </div>
      <div
        className={`opacity-100 text-transparent py-3 bg-clip-text bg-gradient-to-br from-green-500 to-blue-400 text-7xl font-bold font-gooper`}
      >
        {`${version.number ?? 0}`}
      </div>
      <div className={`font-medium text-xs px-2 line-clamp-3`}>
        {version.name}
      </div>
      <div className="w-full">
        <button
          className={`px-3 w-full bg-blue-500 text-xs fill-gray-0 text-gray-0 py-2 flex flex-row justify-center items-center gap-1 rounded-md transition-colors duration-200`}
          onClick={() => selectedVersion(version)}
        >
          <svg className="w-3 h-3" viewBox="0 0 24 24">
            <Icon type={"eye"} />
          </svg>
          View Version
        </button>
        <div
          className={`text-center pt-2 text-gray-500 bg-red text-xs font-light font-['General Sans'] leading-none`}
        >
          {`Edited ${editedAt}`}
        </div>
      </div>
    </div>
  );
};
