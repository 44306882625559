import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Workflow } from "../../../models/Workflow";

import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Controls,
  Node,
  ReactFlowInstance,
  Connection,
  Background,
  BackgroundVariant,
  Edge,
} from "reactflow";

import "reactflow/dist/style.css";
import {
  DecisionNode,
  DecisionNodeData,
  DecisionNodeType,
} from "./Nodes/DecisionNode";
import { PortalNodeData, PortalNode, PortalNodeType } from "./Nodes/PortalNode";

import {
  createStartNode,
  StartNode,
  StartNodeData,
  StartNodeType,
} from "./Nodes/StartNode";
import { SelectorNode, SelectorNodeType } from "./Nodes/SelectorNode";
import {
  WorkflowAddNodeEdge,
  WorkflowAddNodeEdgeType,
} from "./Edges/WorkflowAddNodeEdge";
import { useDebounce } from "../../../utils/Debounce";
import {
  WebsiteNode,
  WebsiteNodeData,
  WebsiteNodeType,
} from "./Nodes/WebsiteNode";
import { SearchNode, SearchNodeData, SearchNodeType } from "./Nodes/SearchNode";
import {
  WorkflowNode,
  WorkflowNodeData,
  WorkflowNodeType,
} from "./Nodes/WorkflowNode";

export type SelectedStartNode = {
  type: string;
};

export type SelectableNodeData =
  | StartNodeData
  | PortalNodeData
  | DecisionNodeData
  | WebsiteNodeData
  | SearchNodeData
  | WorkflowNodeData;

export const WorkflowMap: React.FC<{
  workflow: Workflow;
  uiString?: string;
  saveMap: (ui: string) => void;
  selectedNode: (data: SelectableNodeData | undefined) => void;
  teamId: string;
}> = ({ workflow, uiString, teamId, selectedNode, saveMap }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const nodeTypes = useMemo(
    () => ({
      [StartNodeType]: StartNode,
      [SelectorNodeType]: SelectorNode,
      [PortalNodeType]: PortalNode,
      [DecisionNodeType]: DecisionNode,
      [WebsiteNodeType]: WebsiteNode,
      [SearchNodeType]: SearchNode,
      [WorkflowNodeType]: WorkflowNode,
    }),
    []
  );
  const edgeTypes = useMemo(
    () => ({
      [WorkflowAddNodeEdgeType]: WorkflowAddNodeEdge,
      default: WorkflowAddNodeEdge,
    }),
    []
  );
  const instanceRef = useRef<ReactFlowInstance>();

  const nodesRef = useRef(nodes);
  const edgesRef = useRef(edges);

  const onInit = (rfInstance: ReactFlowInstance) => {
    instanceRef.current = rfInstance;
  };

  const updateNode = (nodeId: string, newData: any) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  useEffect(() => {
    nodesRef.current = nodes;
    edgesRef.current = edges;
  }, [nodes, edges]);

  // Set up map from saved data
  useEffect(() => {
    if (uiString) {
      const flow = JSON.parse(uiString);
      setNodes(flow.nodes || []);
      setEdges(flow.edges || []);
    } else {
      setNodes([createStartNode(teamId, workflow)]);
    }
  }, []);

  useEffect(() => {
    sendNodeData();
    debounceUpdate();
  });

  const onElementClick = (_: React.MouseEvent, node: Node) => {
    sendNodeData();
    updateNode(node.id, { hasProblem: undefined });
  };

  const sendNodeData = () => {
    const selectedNodes = nodes.filter((node) => node.selected);
    const node = selectedNodes[0];
    if (node?.data?.type == PortalNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == DecisionNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == WebsiteNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == SearchNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == WorkflowNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == StartNodeType) {
      selectedNode(node.data);
    } else {
      selectedNode(undefined);
    }
  };

  const getDataString = (nodes: Node[], edges: Edge[]): string => {
    const sortedNodes = [...nodes].sort((a, b) => a.id.localeCompare(b.id));
    const nodesDataString = JSON.stringify(
      sortedNodes.map((node) => node.data)
    );
    const sortedEdges = [...edges].sort((a, b) => a.id.localeCompare(b.id));
    const sortedEdgesDatString = JSON.stringify(
      sortedEdges.map((edge) => edge.source + edge.target)
    );

    return `nodes: ${nodesDataString} edges: ${sortedEdgesDatString}`;
  };

  const debounceUpdate = useDebounce(() => {
    const flow = JSON.parse(uiString ?? "{}");
    const oldNodes = flow.nodes ?? [];
    const oldEdges = flow.edges ?? [];

    const newData = getDataString(nodes, edges);
    const oldData = getDataString(oldNodes, oldEdges);

    if (newData !== oldData) {
      const uiDataString = JSON.stringify(instanceRef.current?.toObject());
      saveMap(uiDataString);
    }
  }, 1000);

  const onPaneClick = () => {
    setNodes((nodes) => nodes.filter((n) => n.id != SelectorNodeType));
    selectedNode(undefined);
  };

  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeClick={onElementClick}
        onInit={onInit}
        onPaneClick={onPaneClick}
      >
        <Background color="#ccc" variant={BackgroundVariant.Dots} />
        <Controls />
      </ReactFlow>
    </>
  );
};
