import React, { useState, useRef, useEffect } from "react";
import Icon from "../../Icon";

export interface Item {
  id: string;
  title: string;
}

interface CommonDropdownProps {
  items: Item[];
  placeholder: string;
  selectedItem: Item | undefined;
  setSelectedItem: (item: Item) => void;
}

const CommonDropdown: React.FC<CommonDropdownProps> = ({
  placeholder,
  items,
  setSelectedItem,
  selectedItem,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (item: Item) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="h-10 w-full px-3 py-2 bg-gray-50 rounded-lg border border-gray-300 flex items-center justify-between cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex-grow min-w-0">
          <div className="text-gray-700 text-sm font-normal font-gooper truncate">
            {selectedItem ? selectedItem.title : placeholder}
          </div>
        </div>
        <Icon
          type="chevron-up"
          className={`flex-shrink-0 ml-2 ${
            isOpen ? "rotate-180" : ""
          } text-gray-400 transition-all`}
        />
      </div>
      {isOpen && (
        <div className="absolute w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
          {items.map((item) => (
            <div
              key={item.id}
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm font-normal font-['Gooper Trial SemiCondensed'] truncate"
              onClick={() => handleSelect(item)}
            >
              {item.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CommonDropdown;
