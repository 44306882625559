import React, { useState } from "react";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { Team } from "../../models/Team";
import { ChatConfigurationTemplate } from "../../models/ChatConfigurationTemplate";
import { AIProviderLogo } from "../Portals/Portal Detail/AIProvider/AiProviderLogo";
import ReactMarkdown, { Components } from "react-markdown";
import { useTeams } from "../../contexts/TeamContext";
import { useNavigate } from "react-router-dom";
import { CredentialInput } from "./CredentialInput";
import { ProviderStatusBadge } from "./ProviderStatusBadge";
import { STORAGE_KEYS } from "../../utils/StorageKeys";

interface TeamSettingsAIProviderSetUpModalInput {
  shows: boolean;
  setShows: (shows: boolean) => void;
  config: ChatConfigurationTemplate;
  team: Team;
}

export const TeamSettingsAIProviderSetUpModal: React.FC<
  TeamSettingsAIProviderSetUpModalInput
> = ({ shows, setShows, config, team }) => {
  const teamService = useTeams();
  const navigate = useNavigate();

  const [confirmState, setConfirmState] = useState<AnimationState>("ready");
  const [secrets, setSecrets] = useState<{ [key: string]: string }>({});

  const handleSave = async () => {
    setConfirmState("loading");
    try {
      await teamService.addSecrets(team.id!, secrets, config.id);
      localStorage.setItem(STORAGE_KEYS.COMPLETED_API, "true");
      setSecrets({});
      setConfirmState("success");
    } catch (e) {
      setConfirmState("error");
    }
  };

  const renderers: Components = {
    a: ({ href, children }) => (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
  };

  if (!shows) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-scroll">
      <div className="bg-white rounded-lg gap-4 flex flex-col shadow-lg p-6 w-96">
        <div className="flex items-center justify-center">
          <ProviderStatusBadge team={team} config={config} />
        </div>
        <div className="flex items-center justify-center">
          <AIProviderLogo
            providerId={config.id}
            className="text-gray-400 w-36 h-12"
          />
        </div>

        <h2 className="text-xl font-semibold font-gooper text-center text-gray-700">
          Set up your API Integration
        </h2>
        <p className="text-center text-gray-500 text-sm ">
          {config.setupOverview}
        </p>

        {Object.keys(config.secrets).map((key) => {
          return (
            <CredentialInput
              key={key}
              value={secrets[key] ?? ""}
              placeholder={`${key} goes here`}
              onChange={(e) => setSecrets({ ...secrets, [key]: e })}
            />
          );
        })}
        <h2 className="text-xl font-semibold font-gooper text-center text-gray-700">
          {`How to set up ${config.name}`}
        </h2>

        <div className="flex flex-col gap-2">
          {config.setupSteps.map((step, index) => {
            return (
              <div
                key={index}
                className="bg-blue-25 px-2 py-4 rounded flex flex-row items-center gap-6"
              >
                <div
                  className={`pl-6 text-transparent bg-clip-text bg-gradient-to-br from-green-500 to-blue-400 text-3xl font-bold font-gooper`}
                >
                  {index + 1}
                </div>
                <div className="flex flex-col gap-1">
                  <div className="text-gray-900 text-sm font-medium font-gooper">
                    {step.title}
                  </div>
                  <ReactMarkdown
                    components={renderers}
                    className="prose text-gray-500 text-sm prose-a:text-blue-600"
                  >
                    {step.text}
                  </ReactMarkdown>
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-2 w-full gap-2">
          <AnimatedButton
            title={`Cancel`}
            onClick={() => {
              setSecrets({});
              setShows(false);
            }}
            buttonState={"ready"}
            style={"normal"}
            key="cancel"
            font="font-sans"
            id="cancelButton"
          />
          <AnimatedButton
            title={`Connect`}
            onClick={() => handleSave()}
            buttonState={confirmState}
            setButtonState={setConfirmState}
            style={"action"}
            key="action"
            font="font-sans"
            id="confirmButton"
            after={(success) => {
              if (success) {
                navigate(config.id);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
