import React, { useState } from "react";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { useTeams } from "../../contexts/TeamContext";
import { Team } from "../../models/Team";
import ClearableInput from "../Onboarding/ClearableInput";

interface TeamSettingsVariableInputProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  team: Team;
}

export const TeamSettingsVariableInput: React.FC<
  TeamSettingsVariableInputProps
> = ({ shows, setShows, team }) => {
  const [saveState, setSaveState] = useState<AnimationState>("ready");
  const [newVariable, setNewVariable] = useState("");
  const [error, setError] = useState("");

  const teamService = useTeams();

  const variables = Object.keys(team.variables ?? {});

  const handleCreateVariable = async () => {
    if (variables.includes(newVariable)) {
      setError("You've already saved this variable");
      return;
    }

    const newVariables = team.variables ?? {};
    newVariables[newVariable] = { id: newVariable };
    setSaveState("loading");
    try {
      await teamService.teamRepo.update(
        { variables: newVariables },
        teamService.teamPath(),
        team.id!
      );
      setSaveState("success");
    } catch {
      setSaveState("error");
    }
  };

  const handleTextChange = (newText: string) => {
    setError("");
    setNewVariable(newText.replace(/ /g, "_"));
  };

  if (!shows) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[496px] flex flex-col gap-3">
        <h2 className="text-2xl font-bold font-gooper text-center text-gray-900">
          Add a variable
        </h2>
        <p className="text-center text-gray-500 ">
          Variables are placeholders for getting data from your application into
          a prompt. You use variables when creating Portals.
        </p>

        <ClearableInput
          text={newVariable}
          setText={handleTextChange}
          error={error}
          placeholder="e.g. user_age"
        />
        <div className="grid grid-cols-2 w-full gap-2">
          <AnimatedButton
            title={`Close`}
            onClick={() => setShows(false)}
            buttonState={"ready"}
            style={"normal"}
            key="cancel"
            font="font-sans"
            id="cancelButton"
          />
          <AnimatedButton
            title={`Save Variable`}
            onClick={() => handleCreateVariable()}
            buttonState={saveState}
            setButtonState={setSaveState}
            style={"action"}
            key="action"
            font="font-sans"
            id="confirmButton"
            after={(success) => {
              if (success) {
                setShows(false);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
