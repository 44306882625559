import { useState } from "react";
import { WorkflowDemoStartResult } from "../../../../../models/WorkflowDemoResult";
import { StatusBadge } from "../../../../TestCenter/StatusBadge";
import { StartBadge } from "../../../Map/Nodes/StartNode";
import { StartResultModal } from "./StartResultModal";

export const StartResultCard: React.FC<{
  result: WorkflowDemoStartResult;
}> = ({ result }) => {
  const [shows, setShows] = useState(false);
  return (
    <div
      key={result.id}
      onClick={() => setShows(true)}
      className="h-24 shadow-sm cursor-pointer hover:shadow-md flex flex-col rounded-lg bg-gray-50 p-2 gap-2 border border-gray-200 transition-all"
    >
      <StartResultModal
        shows={shows}
        onClose={() => setShows(false)}
        result={result}
      />
      <div className="flex flex-row justify-between">
        <StartBadge />
        <StatusBadge status={result.status} />
      </div>
      <div className="text-2xl font-gooper text-gray-900">{result.name}</div>
    </div>
  );
};
