import React, { useState, useEffect } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import { CommonSpinner } from "../Common/CommonLoading";
import { Invite } from "../../models/Invite";
import { Team, TeamRole } from "../../models/Team";
import { useTeams } from "../../contexts/TeamContext";
import { TeamSettingsSections } from "./TeamSettingsSections";
import FailureModal from "../FailureModal";
import AnimatedButton from "../AnimatedButton";
import { TeamSettingsMemberInput } from "./TeamSettingsMemberInput";
import Icon, { IconType } from "../Icon";
import { CommonBadge } from "../Common/CommonBadge";
import { TeamSettingsMemberMenu } from "./TeamSettingsMemberMenu";

interface PortalsProps {}

interface ListMember {
  id?: string;
  email: string;
  date: Date;
  name: string | undefined;
  status: "invited" | "active";
  role: TeamRole;
}

export const TeamSettingsMembers: React.FC<PortalsProps> = ({}) => {
  const { teamId } = useParams<{ teamId: string }>();

  const teamService = useTeams();

  const [invites, setInvites] = useState<Invite[]>([]);
  const [team, setTeam] = useState<Team>();
  const [loading, setLoading] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [error, setError] = useState("");
  const [activeMenuIndex, setActiveMenuIndex] = useState<number>();

  const loadData = async () => {
    setLoading(true);
    const getInvites = teamService.inviteRepo.getList(
      teamService.invitePath(teamId!),
      { name: "createdAt", descending: true },
      [{ key: "accepted", filter: "==", value: false }],
      undefined
    );
    const getTeam = teamService.teamRepo.get(teamService.teamPath(), teamId!);
    try {
      const [invites, team] = await Promise.all([getInvites, getTeam]);
      if (!team) {
        throw new Error("No team found!");
      }
      setInvites(invites);
      setTeam(team);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("Something went very wrong!");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [teamService]);

  const teamMembers = team?.members ?? {};
  const memberList: ListMember[] = Object.keys(teamMembers).map((key) => {
    const member = teamMembers[key];
    return {
      id: key,
      name: member.name,
      date: member.joinedAt,
      status: "active",
      email: member.email,
      role: member.role,
    };
  });
  const inviteList: ListMember[] = invites.map((invite) => {
    return {
      name: "Pending",
      email: invite.email,
      date: invite.createdAt,
      status: "invited",
      role: invite.role,
      id: invite.email,
    };
  });

  const allMembers = memberList
    .concat(inviteList)
    .sort((a, b) => a.date.getTime() - b.date.getTime());
  return (
    <CommonContainer>
      <CommonHeader
        title={`Members`}
        subtitle="Manage your Beamers with ease."
        sections={[
          { name: "Team Settings", link: `/${teamId}/settings` },
          { name: "Members", link: `/${teamId}/settings/members` },
        ]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            id="addTeamMemberButton"
            key="invite"
            style="action"
            title="Invite Members"
            buttonState="ready"
            onClick={() => setShowInvite(true)}
          />,
        ]}
      />
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Dismiss"}
        shows={error != ""}
        closed={() => setError("")}
      />

      <TeamSettingsMemberInput
        teamId={teamId!}
        setShows={(newValue) => {
          loadData();
          setShowInvite(newValue);
        }}
        shows={showInvite}
        pendingInviteCount={invites.length}
      />
      <TeamSettingsSections selectedId="members" />

      <div className="">
        <table className="border  rounded-3xl border-gray-200 w-full bg-gray-200">
          <thead className="border-b border-gray-200">
            <tr className="">
              <Header title="Email" />
              <Header title="Name" />
              <Header title="Date Joined" />
              <Header title="Status" className="" />
              <Header title="Role" className="" />
              <Header title="Action" className="w-1" padding="p-2" />
            </tr>
            {loading && memberList.length == 0 && (
              <tr className="bg-gray-0">
                <td className="border-b border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b  border-gray-200 p-3 font-medium text-sm ">
                  <CommonSpinner />
                </td>
                <td className="border-b  border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-r border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
                <td className="border-b border-r border-gray-200 p-3 font-medium text-sm">
                  <CommonSpinner />
                </td>
              </tr>
            )}
          </thead>
          {allMembers.map((member, index) => {
            return (
              <tbody key={index} id={`memberCell${index}`}>
                <tr className="bg-gray-0">
                  <td className="border-b text-gray-500 border-gray-200 p-3 font-normal text-sm">
                    {member.email}
                  </td>
                  <td className="border-b text-gray-800 border-gray-200 p-3 font-medium text-sm">
                    {member.name}
                  </td>
                  <td className="border-b p-3 text-sm text-gray-500 font-normal">
                    {new Date(member.date)
                      .toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(",", "")}
                  </td>
                  <td className="border-b border-gray-200 p-3 font-medium text-sm">
                    <div className="flex">
                      <MemberStatusBadge status={member.status} />
                    </div>
                  </td>
                  <td className="border-b border-gray-200 p-3 font-medium text-sm">
                    <div className="flex">
                      <CommonBadge
                        text={
                          member.role.charAt(0).toUpperCase() +
                          member.role.slice(1)
                        }
                      />
                    </div>
                  </td>
                  <td className="relative border-b border-gray-200 pl-6 font-medium text-sm">
                    <button
                      id={`memberMenuButton${index}`}
                      onClick={() => setActiveMenuIndex(index)}
                      className="size-7 rounded-full bg-blue-50 hover:bg-blue-100 items-end justify-center"
                    >
                      <Icon
                        type="dots-horizontal"
                        className="size-7 text-blue-500"
                      />
                    </button>
                    <TeamSettingsMemberMenu
                      memberId={member.id ?? member.email}
                      teamId={teamId!}
                      active={member.status == "active"}
                      shows={activeMenuIndex == index}
                      updated={() => loadData()}
                      close={(index) => {
                        if (index == activeMenuIndex) {
                          setActiveMenuIndex(undefined);
                        }
                      }}
                      memberRole={member.role}
                      index={index}
                      memberName={member.name ?? ""}
                    />
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </CommonContainer>
  );
};

const Header: React.FC<{
  title: string;
  className?: string;
  padding?: string;
}> = ({ title, className, padding }) => {
  return (
    <th
      className={`${
        padding ?? "p-3"
      } bg-gray-50 text-left p4 text-xs font-medium text-gray-500 uppercase tracking-widest ${className}`}
    >
      {title}
    </th>
  );
};

const MemberStatusBadge: React.FC<{
  status: string;
}> = ({ status }) => {
  const active = status == "active";
  const icon: IconType = active ? "portals" : "clock";
  const className = active
    ? "text-green-500 bg-green-50"
    : "text-pink-500 bg-pink-50";
  return (
    <div
      className={`${className} self-stretch p-1 rounded justify-center items-center gap-1 flex`}
    >
      <Icon type={icon} className="size-3" />
      <div className="text-center  text-xs font-medium leading-none">
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </div>
    </div>
  );
};
