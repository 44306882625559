import React from "react";
import { PortalTest } from "../../models/PortalTest";
import Icon from "../Icon";
import Pagination from "./Pagination";
import { CommonSpinner } from "../Common/CommonLoading";

export const TestTable: React.FC<{
  tests: PortalTest[];
  handleTestClick: (test: PortalTest) => void;
  selectedTests: string[];
  setSelectedTests: (selected: string[]) => void;
  currentPage: number;
  selectedPage: (direction: "prev" | "next") => void;
  hasMore: boolean;
  loading: boolean;
}> = ({
  tests,
  handleTestClick,
  selectedTests,
  setSelectedTests,
  currentPage,
  selectedPage,
  hasMore,
  loading,
}) => {
  return (
    <div className="flex flex-col gap-6">
      {loading ? (
        <div className="flex items-center justify-center">
          <CommonSpinner />
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <div className="font-gooper text-xl text-gray-700">
              Test Selection
            </div>
            <div className="text-sm text-gray-500">
              Lorem ipsum dolor sit amet consectetur. Velit leo bibendum
              sollicitudin quis felis auctor. Learn more here.
            </div>
          </div>
          <table className="border rounded-lg border-gray-500 w-full overflow-hidden bg-gray-200">
            <tbody>
              {tests?.map((test) => {
                return (
                  <tr
                    className="bg-gray-0 border border-gray-200"
                    key={test.id}
                  >
                    <td className="w-4 p-3">
                      <div className="flex flex-row gap-4 w-full justify-between items-center">
                        <input
                          type="checkbox"
                          checked={selectedTests.includes(test.id ?? "")}
                          onChange={() => {
                            if (selectedTests.includes(test.id ?? "")) {
                              setSelectedTests(
                                selectedTests.filter((t) => t != test.id)
                              );
                            } else {
                              setSelectedTests([
                                ...selectedTests,
                                test.id ?? "",
                              ]);
                            }
                          }}
                          className="size-4 bg-gray-100 border border-gray-300 rounded checked:bg-blue-500"
                          id={`${test.id}Button`}
                        />{" "}
                        <div className=" w-full text-gray-700 text-sm font-medium">
                          {test.name}
                        </div>
                        <button
                          onClick={() => handleTestClick(test)}
                          className="bg-blue-50 rounded-full p-1.5 hover:bg-blue-100"
                        >
                          <Icon
                            type="search"
                            className="size-4  text-blue-500"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        hasMore={hasMore}
        onPageChange={selectedPage}
      />
    </div>
  );
};
