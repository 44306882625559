import { useAuth } from "../../contexts/AuthContext";

import { Button, Card, Checkbox, Label } from "flowbite-react";
import { useState, type FC, useRef } from "react";
import AuthPage from "./AuthPage";
import { ValidatedInputState, ValidatedInput } from "./ValidatedInput";
import { GoogleLogInButton } from "./GoogleLogInButton";

const Login: FC = function () {
  const auth = useRef(useAuth());
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);

  const [password, setPassword] = useState("");
  const [passwordState, setPasswordState] =
    useState<ValidatedInputState>("default");
  const [passwordError, setPasswordError] = useState("");

  const [email, setEmail] = useState("");
  const [emailState, setEmailState] = useState<ValidatedInputState>("default");
  const [emailError, setEmailError] = useState("");

  const handleGoogleSignIn = async () => {
    try {
      await auth.current.setPersistence(rememberMe);
      await auth.current.signInWithGoogle();
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Google login failed");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailSignIn = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!email.match(/.+@.+/)) {
      setEmailError("Please enter a valid email");
      setEmailState("error");
      return;
    }
    setIsLoading(true);

    try {
      await auth.current.setPersistence(rememberMe);
      await auth.current.signIn(email, password);
    } catch (e) {
      if (e instanceof Error) {
        setError(`\n${e.message}`);
      } else {
        setPasswordError("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const runAndResetEmailError = (
    value: string,
    action: (input: string) => void
  ) => {
    action(value);
    setEmailState("default");
    setEmailError("");
  };

  const runAndResetPasswordError = (
    value: string,
    action: (input: string) => void
  ) => {
    action(value);
    setPasswordError("");
    setPasswordState("default");
  };

  const handleEmailUnfocus = () => {
    if (!email.match(/.+@.+/)) {
      setEmailError("Please enter a valid email");
      setEmailState("error");
      return;
    }
    setEmailError("");
    setEmailState("validated");
  };

  const handlePasswordUnfocus = async () => {
    try {
      const result = await auth.current.validatePassword(password);
      if (result.length > 0) {
        setPasswordError(result[0]);
        setPasswordState("error");
      } else {
        setPasswordError("");
        setPasswordState("validated");
      }
    } catch (e) {
      if (e instanceof Error) {
        setPasswordError(e.message);
        setPasswordState("error");
      }
    }
  };

  return (
    <AuthPage
      error={error}
      modalBackTitle="return to log in"
      setError={setError}
      isLoading={isLoading}
      successMessage={""}
      setSuccessMessage={() => undefined}
    >
      <Card className="shadow-none w-[344px] md:w-[512px] rounded-2xl">
        <div className="space-y-4">
          <h1 className="text-2xl font-gooper font-black text-gray-800 sm:text-5xl">
            Welcome back
          </h1>
          <p className="text-sm font-medium text-gray-700">
            Don’t have an account?&nbsp;
            <a
              href="signup"
              className="font-medium text-blue-600 hover:underline"
            >
              Sign up
            </a>
          </p>
          <ValidatedInput
            title="Email"
            inputId="email"
            inputType="email"
            placeholder="name@company.com"
            error={emailError}
            state={emailState}
            onUnfocus={() => handleEmailUnfocus()}
            onChange={(text) => runAndResetEmailError(text, setEmail)}
          />
          <ValidatedInput
            title="Password"
            inputId="password"
            inputType="password"
            placeholder="••••••••"
            error={passwordError}
            state={passwordState}
            onUnfocus={() => handlePasswordUnfocus()}
            onChange={(text) => runAndResetPasswordError(text, setPassword)}
          />
          <div className="flex items-center">
            <div className="h-0.5 w-full bg-gray-200"></div>
            <div className="px-5 font-gooper font-medium text-center text-gray-500">
              or
            </div>
            <div className="h-0.5 w-full bg-gray-200"></div>
          </div>
          <form className="space-y-4">
            <GoogleLogInButton
              title="Log in with Google"
              onClick={() => handleGoogleSignIn()}
              isDisabled={false}
            />
            <div>
              <p className="text-sm pt-2 font-gooper text-gray-500">
                We'll never share your details. See our &nbsp;
                <a
                  href="https://www.easybeam.ai/privacy"
                  className="font-gooper text-gray-900 hover:underline"
                >
                  Privacy Policy.
                </a>
              </p>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-start">
                <div className="flex h-5 items-center text-green-50">
                  <Checkbox
                    className="text-green-500 focus:ring-0"
                    id="remember-social"
                    required
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <Label
                    htmlFor="remember-social"
                    className="text-gray-600 font-normal"
                  >
                    Remember me
                  </Label>
                </div>
              </div>
              <a
                className="text-sm font-medium text-blue-500 hover:underline"
                href="forgotpassword"
              >
                Forgot password?
              </a>
            </div>
            <Button
              type="submit"
              className="w-full"
              onClick={handleEmailSignIn}
              color="primary"
            >
              Log in
            </Button>
          </form>
        </div>
      </Card>
    </AuthPage>
  );
};

export default Login;
