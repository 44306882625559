import { useNavigate } from "react-router-dom";
import { ChatConfigurationTemplate } from "../../../models/ChatConfigurationTemplate";
import { Portal } from "../../../models/Portal";
import { PortalVersion } from "../../../models/PortalVersion";
import { CommonCard } from "../../Common/CommonCard";
import { CommonInputSegment } from "../../Common/CommonInput";
import Icon from "../../Icon";
import { StatusBadge } from "../StatusBadge";
import { AIProviderConfiguration } from "./AIProvider/AIProviderConfiguration";
import { AIProviderList } from "./AIProvider/AIProviderList";
import { PromptInput } from "./PromptInput/PromptInput";
import { MutableRefObject, ReactNode, useState } from "react";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { extractDefaultConfig } from "../../../utils/ExtractDefaultChatConfig";
import { deepCopy } from "../../../utils/DeepClone";
import { ScoreBadge } from "../ScoreBadge";
import { Team } from "../../../models/Team";

export const VersionDetailCard: React.FC<{
  portal?: Portal;
  version: PortalVersion;
  team: Team;
  updatedVersion: (version: PortalVersion) => void;
  updatePrompt: (prompt: string) => void;
  variables: string[];
  templates: ChatConfigurationTemplate[];
  setIsLoading?: (isLoading: boolean) => void;
  fetchedVersionRef?: MutableRefObject<PortalVersion | undefined>;
  handleSave?: () => void;
  saveState?: AnimationState;
  setSaveState?: (state: AnimationState) => void;
  nameError?: string;
  setNameError?: (error: string) => void;
  buttonsDisabled?: boolean;
  setPromptError?: (error: string) => void;
  promptError?: string;
  addedVariable: (newVariable: string) => void;
  teamId: string;
  hideCard?: boolean;
}> = ({
  portal,
  version,
  updatedVersion,
  variables,
  templates,
  setIsLoading,
  fetchedVersionRef,
  saveState,
  setSaveState,
  nameError,
  setNameError,
  handleSave,
  buttonsDisabled,
  promptError,
  setPromptError,
  addedVariable,
  teamId,
  updatePrompt,
  team,
  hideCard,
}) => {
  const navigate = useNavigate();

  const [configOpen, setConfigOpen] = useState(false);
  const [discardState, setDiscardState] = useState<AnimationState>("ready");

  const handleSelectedProvider = (providerId: string) => {
    const template = templates!.filter((t) => t.id == providerId)[0];
    const defaultConfig = extractDefaultConfig(template);
    updatedVersion({
      ...version!,
      configId: providerId,
      config: defaultConfig,
    });
  };

  const isLive =
    portal?.currentVersionId == version.id &&
    portal?.currentVersionId != undefined;

  const handleConfigUpdate = (
    id: string,
    value: number | string | string[]
  ) => {
    const config = version?.config;
    if (!config || !version) {
      return;
    }
    config[id] = value;
    updatedVersion({ ...version, config });
  };

  const currentTemplate = templates?.filter(
    (t) => t.id == version?.configId ?? "openai"
  )[0];

  const handleNavigateToCurrentVersion = () => {
    if (!portal) {
      return;
    }
    setIsLoading?.(true);
    const paths = location.pathname.split("/").filter(Boolean);
    paths.pop();
    paths.push(portal.currentVersionId!);
    const newPath = "/" + paths.join("/");
    navigate(newPath);
  };

  const handleDiscard = async () => {
    const original = fetchedVersionRef?.current;
    if (!original) {
      return;
    }
    const copy: PortalVersion = deepCopy(original);
    setDiscardState("success");
    updatedVersion(copy);
  };

  const handleSetConfigOpen = (newState: boolean) => {
    setConfigOpen(newState);
    updatedVersion({ ...version });
  };

  const content = (): ReactNode => {
    return (
      <div className={`flex flex-col gap-6`}>
        {portal && (
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-1">
              <Icon type={"versions"} className={"size-4 text-gray-400"} />
              <div>
                <span className="text-gray-400 text-sm">{`Version No`}</span>
                <span className="text-gray-400 text-sm font-medium">{` ${version.number}`}</span>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between gap-2">
              {!isLive && portal?.currentVersionId && (
                <button
                  onClick={handleNavigateToCurrentVersion}
                  className="text-blue-400 text-sm underline font-normal"
                >
                  Go to live version
                </button>
              )}
              {version.averageReviewScore != null && portal && (
                <ScoreBadge
                  score={version.averageReviewScore}
                  portalId={portal.id!}
                  versionId={version.id!}
                  teamId={teamId}
                />
              )}
              {portal && <StatusBadge portal={portal} version={version} />}
            </div>
          </div>
        )}

        {!configOpen && (
          <>
            {portal && (
              <CommonInputSegment
                title="Name"
                onChange={(name) => updatedVersion({ ...version, name: name })}
                value={version.name ?? ""}
                placeholder="Version Name"
                error={nameError}
                setError={setNameError}
                id="versionName"
              />
            )}

            <AIProviderList
              clickedProvider={handleSelectedProvider}
              selectedProvider={version.configId ?? ""}
              providers={templates ?? []}
              team={team}
            />
            <PromptInput
              updated={updatePrompt}
              prompt={version.prompt ?? ""}
              variables={variables}
              error={promptError}
              setError={setPromptError}
              addedVariable={addedVariable}
              teamId={teamId}
            />
          </>
        )}
        <AIProviderConfiguration
          updated={handleConfigUpdate}
          template={currentTemplate}
          version={version}
          isOpen={configOpen}
          setIsOpen={handleSetConfigOpen}
        />
        {handleSave && saveState && (
          <div className="flex flex-col gap-1">
            <div className="grid grid-cols-2 gap-1 fill-transparent">
              <AnimatedButton
                title={`Discard changes`}
                onClick={() => handleDiscard()}
                buttonState={discardState}
                setButtonState={setDiscardState}
                style={"secondary"}
                key="discard"
                disabled={buttonsDisabled}
                font="font-sans font-medium"
                id="discardButton"
              />
              <AnimatedButton
                title={`Save changes`}
                onClick={() => handleSave()}
                buttonState={saveState}
                setButtonState={setSaveState}
                style={"action"}
                key="save"
                disabled={buttonsDisabled}
                font="font-sans font-medium"
                id="saveButton"
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return hideCard ? content() : <CommonCard>{content()} </CommonCard>;
};
