import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import OnboardingPage from "./OnboardingPage";
import SelectionBoard from "./SelectionBoard";
import { OnboardingStep } from "./UserOnboarding";
import { NextButton } from "./NextButton";

const roles = [
  "Developer",
  "Product Owner",
  "Manager",
  "Quality Assurance",
  "Prompt Engineer",
  "CTO",
  "Founder",
  "Other",
];

const UserRole: React.FC<{
  setStep: (step: OnboardingStep) => void;
  skipSurvey: () => void;
  progress: number;
}> = ({ setStep, skipSurvey, progress }) => {
  const authService = useAuth();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>("");

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const currentUser = await authService.currentUser();
      if (!currentUser) return;

      await authService.userRepo.update(
        { role: selectedRole ?? "SKIPPED" },
        authService.userPath(),
        currentUser.id
      );
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setIsLoading(false);
    }
    setStep(OnboardingStep.OrgSize);
  };

  return (
    <OnboardingPage
      error={error}
      title={"Question 1/4"}
      headline="What do you do?"
      message="Help us understand how we can serve you best"
      isLoading={isLoading}
      progress={progress}
      setError={() => undefined}
      didSkip={skipSurvey}
    >
      <SelectionBoard
        items={roles}
        selected={selectedRole}
        setSelected={setSelectedRole}
        columns={2}
        textSize="text-base"
      />
      <NextButton onClick={handleSubmit} />
    </OnboardingPage>
  );
};

export default UserRole;
