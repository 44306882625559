import React from "react";
import { WorkflowDemoDecisionResult } from "../../../../../models/WorkflowDemoResult";
import Icon, { IconType } from "../../../../Icon";
import { formatDate, formatTime } from "../../../../../utils/DateUtils";
import {
  InputType,
  WorkflowSwitchOperator,
} from "../../../../../models/Workflow";

interface DecisionResultModalProps {
  result: WorkflowDemoDecisionResult;
  shows: boolean;
  onClose: () => void;
}

export const DecisionResultModal: React.FC<DecisionResultModalProps> = ({
  result,
  onClose,
  shows,
}) => {
  const statusColor = (): string => {
    switch (result.status) {
      case "COMPLETED":
        return "bg-green-50 text-green-400";
      case "ERROR":
        return "bg-yellow-50 text-yellow-400";
      case "IN_PROGRESS":
        return "bg-red-50 text-red-400";
      default:
        return "";
    }
  };

  const statusTitle = (): string => {
    switch (result.status) {
      case "COMPLETED":
        return "success";
      case "ERROR":
        return "error";
      case "IN_PROGRESS":
        return "security";
      default:
        return "";
    }
  };

  if (!shows) {
    return null;
  }
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-scroll"
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <div className="bg-white rounded-lg flex flex-col shadow-lg w-[544px] relative overflow-y-auto">
        <div className="flex justify-end">
          <button
            className="text-gray-200 hover:text-gray-300 pt-8 pr-6"
            onClick={onClose}
          >
            <Icon type="x" className="size-5" />
          </button>
        </div>
        <div className="flex flex-col w-full overflow-y-auto p-6 gap-6">
          <div className="flex justify-between items-center">
            <div className="flex justify-between text-xs text-gray-500 gap-2">
              <span> Created</span>
              <span>{formatDate(result.createdAt)}</span>
              <span>{formatTime(result.createdAt)}</span>
            </div>
            <div
              className={`text-xs font-medium px-2 py-1 rounded ${statusColor()}`}
            >
              {statusTitle()}
            </div>
          </div>

          <div className="flex flex-col gap-1 justify-center items-center">
            <div className="text-gray-500 text-xs ">{`Decision Result`}</div>
            <span className="text-gray-900 text-2xl font-gooper">
              {result.name}
            </span>
          </div>

          <OperandBlock
            type={result.inputType}
            name={result.inputName}
            value={result.input}
          />

          <OperatorBlock result={result.result} operator={result.operator} />

          <OperandBlock
            type={result.operandType}
            name={result.operandName}
            value={result.operand}
          />
        </div>
      </div>
    </div>
  );
};

const OperandBlock: React.FC<{
  type: InputType;
  name: string;
  value: string;
}> = ({ type, name, value }) => {
  const icon: IconType =
    type == "variable" ? "workflows" : type == "static" ? "quote" : "reply-all";
  return (
    <div className="border border-gray-200 w-full bg-gray-50 rounded-lg flex-col gap-2 inline-flex">
      <div className="grid grid-cols-2 w-full border-gray-200 border-b">
        <div className="flex flex-row items-center justify-center border-gray-200 border-r gap-2 py-3">
          <Icon type={icon} className="text-gray-500 size-4" />
          <div className="font-gooper text-gray-700 text-sm">{`${type}`}</div>
        </div>
        <div className="flex flex-row items-center justify-center border-gray-200 border-r gap-2 py-3">
          <div className="text-gray-700 font-medium text-sm text-center justify-center items-center">{`${name}`}</div>
        </div>
      </div>
      <div className="px-2 pb-2">
        <div className=" text-gray-700 text-base leading-normal">{`${value}`}</div>
      </div>
    </div>
  );
};

const OperatorBlock: React.FC<{
  operator: WorkflowSwitchOperator;
  result: boolean | undefined;
}> = ({ operator, result }) => {
  const operatorTitle = (): string => {
    switch (operator) {
      case "contains":
        return "contains";
      case "!contains":
        return "doesn't contain";
      case "==":
        return "is";
      case "!=":
        return "is not";
      case "<":
        return "less than";
      case ">":
        return "greater than";
    }
  };
  const iconType: IconType = result ? "check" : "x";
  return (
    <div
      className={`${
        result
          ? "border-green-300 bg-green-50 text-green-500"
          : "border-red-200 bg-red-50 text-red-500"
      } border w-full p-2 rounded-lg flex-col justify-center items-center gap-2 inline-flex`}
    >
      <div className="flex flex-row gap-2 items-center">
        <div className=" text-sm font-medium">{`${operatorTitle()}`}</div>
        <Icon type={iconType} className=" size-4" />
      </div>
    </div>
  );
};
