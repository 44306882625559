import React from "react";
import {
  ChatConfigurationModelInput,
  ChatConfigurationTemplate,
  InputTypes,
} from "../../../../models/ChatConfigurationTemplate";
import Icon from "../../../Icon";
import { PortalConfig, PortalVersion } from "../../../../models/PortalVersion";
import ConfigSlider from "./ConfigSlider";
import { ModelSelector } from "./ModelSelector";
import { ArrayInput } from "./ArrayInput";

export const AIProviderConfiguration: React.FC<{
  updated: (id: string, value: number | string | string[]) => void;
  template: ChatConfigurationTemplate;
  version: PortalVersion;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}> = ({ template, updated, isOpen, setIsOpen, version }) => {
  return (
    <div
      className={`bg-gray-0 transition-all duration-300 rounded flex flex-col ${
        isOpen ? "" : "overflow-hidden"
      }`}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${
          isOpen ? "rounded-t-lg" : "rounded-lg"
        } px-2 py-3 flex flex-row items-center justify-between w-full text-left font-gooper text-blue-600 text-md font-medium bg-blue-25`}
      >
        Provider Settings
        <Icon
          type={"chevron"}
          className={`size-6 transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      <div
        className={`transition-all duration-300 ease-in-out ${
          isOpen ? "opacity-100 max-h-fit" : "max-h-0 opacity-0"
        }`}
        style={{ transitionProperty: "max-height, opacity" }}
      >
        <div className="p-4 flex flex-col gap-4 bg-blue-25 rounded-b-lg">
          {Object.entries(template.inputs)
            .sort(([_, inputA], [__, inputB]) => inputA.number - inputB.number)
            .map(([key, input]) => (
              <InputField
                key={key}
                input={input}
                updated={updated}
                inputKey={key}
                value={(version.config ?? {})[key]}
                config={version.config ?? {}}
                inputs={template.inputs}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const InputField: React.FC<{
  inputKey: string;
  input: InputTypes;
  updated: (id: string, value: number | string | string[]) => void;
  value: number | string | string[];
  config: PortalConfig;
  inputs: {
    [key: string]: InputTypes;
  };
}> = ({ input, updated, inputKey, value, config, inputs }) => {
  if (input.input == "slider") {
    return (
      <ConfigSlider
        description={input.description}
        min={input.minValue}
        max={input.maxValue}
        onChange={(v) => updated(inputKey, v)}
        rangeLabels={input.rangeLabels}
        value={value as number}
        name={input.name}
        shortDescription={input.shortDescription}
      />
    );
  } else if (input.input == "model") {
    return (
      <ModelSelector
        input={input}
        value={value as string}
        update={(v) => updated(inputKey, v)}
      />
    );
  } else if (input.input == "maxLengthSlider") {
    const seletedModel = config["model"] as string;
    const modelInput = inputs["model"] as ChatConfigurationModelInput;
    const models = modelInput.values.filter((m) => m.id == seletedModel);
    if (models.length < 1) return;
    const model = models[0];

    return (
      <ConfigSlider
        description={input.description}
        min={10}
        max={model.maxLength}
        onChange={(v) => updated(inputKey, v)}
        rangeLabels={model.rangeLabels}
        value={value as number}
        name={input.name}
        shortDescription={input.shortDescription}
      />
    );
  } else if (input.input == "array" && Array.isArray(value)) {
    return (
      <ArrayInput
        input={input}
        values={value as string[]}
        update={(v) => updated(inputKey, v)}
      />
    );
  }
};
