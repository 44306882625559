import { useNavigate } from "react-router-dom";
import { Portal } from "../../../models/Portal";
import {
  PortalVersion,
  PortalVersionStatus,
} from "../../../models/PortalVersion";
import { useState } from "react";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { usePortalService } from "../../../contexts/PortalContext";
import { LiveConfirmationModal } from "./LiveConfirmationModal";
import Icon from "../../Icon";
import { PortalDetalActionMenu } from "./PortalDetailActionMenu";
import { CommonConfirmationModal } from "../../Common/CommonConfirmationModal";
import { Team } from "../../../models/Team";

export const PortalDetailActions: React.FC<{
  portal: Portal | undefined;
  version: PortalVersion | undefined;
  team: Team | undefined;
  updatedVersion: (version: PortalVersion) => void;
  loadData: () => Promise<void>;
}> = ({ portal, version, updatedVersion, loadData, team }) => {
  const navigate = useNavigate();
  const portalService = usePortalService();

  const [publishState, setPublishState] = useState<AnimationState>("ready");
  const [newVersionState, setNewVersionState] =
    useState<AnimationState>("ready");
  const [testcenterState, setTestcenterState] =
    useState<AnimationState>("ready");

  const [goLiveModal, setGoLiveModal] = useState(false);
  const [apiKeyModal, setAPIKeyModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  if (!portal || !version || !team) {
    return <></>;
  }

  const setStatus = async (
    status: PortalVersionStatus,
    isLive: boolean,
    setStatus: (value: AnimationState) => void
  ) => {
    setStatus("loading");
    await portalService.updateVersion(
      { ...version!, status },
      version.id!,
      team.id!,
      portal.id!,
      isLive
    );
    await handleVersionUpdate({ ...version, status });
    setStatus("success");
  };

  const handleVersionUpdate = async (newVersion: PortalVersion) => {
    await loadData();
    updatedVersion({ ...newVersion });
  };

  const handlePublish = async () => {
    let setLive = isLive;
    let newStatus: PortalVersionStatus = version.status;
    switch (version.status) {
      case "ARCHIVED":
        newStatus = "DRAFT";
        break;
      case "DRAFT":
        newStatus = "READY";
        break;
      case "READY":
        setLive = true;
        break;
    }

    if (
      newStatus == "READY" &&
      team.secretsUsed?.[version.configId] == undefined
    ) {
      setAPIKeyModal(true);
      return;
    }

    await setStatus(newStatus, setLive, setPublishState);
  };
  const isLive =
    portal.currentVersionId == version.id &&
    portal.currentVersionId != undefined;
  let publishAction = handlePublish;

  let publishName: string | undefined;
  switch (version.status) {
    case "READY":
      publishName = "Go Live";
      publishAction = async () => {
        setGoLiveModal(true);
      };
      break;
    case "DRAFT":
      publishName = "Mark as Ready";
      break;
    case "ARCHIVED":
      publishName = "Unarchive";
  }
  if (isLive) {
    publishName = undefined;
  }

  const handleNewVersion = async () => {
    setNewVersionState("loading");
    const newVersion = await portalService.createVersion(team.id!, portal.id);
    setNewVersionState("success");
    navigate(
      `/teams/${team.id!}/portals/${portal.id!}/version/${newVersion.id}`
    );
  };

  const handleTestCenter = async () => {
    setNewVersionState("loading");
    navigate(`testcenter`);
  };

  const handleAddAPIKey = async () => {
    setAPIKeyModal(false);
    navigate(`/teams${team.id!}/settings/api`);
  };

  return (
    <div className="flex flex-col gap-1 w-60 pr-4 pb-2 -mt-2">
      <LiveConfirmationModal
        isOpen={goLiveModal}
        onCancel={() => setGoLiveModal(false)}
        onConfirm={() => handlePublish()}
        currentLiveVersion={portal?.currentVersionData}
      />

      <CommonConfirmationModal
        isOpen={apiKeyModal}
        title="Almost! Api Key needs to be set."
        message="We are missing your API Key to make this version live. You will have to set to draft in order to take it online."
        onCancel={() => setAPIKeyModal(false)}
        onConfirm={() => handleAddAPIKey()}
        confirmStyle={"action"}
        confirmIcon="portals"
      />
      {publishName && (
        <AnimatedButton
          title={publishName}
          onClick={() => publishAction()}
          buttonState={publishState}
          setButtonState={setPublishState}
          style={"action"}
          key="publish"
          id="publishButton"
        />
      )}
      <div className="flex flex-row gap-1 w-full relative">
        <AnimatedButton
          title={"Create New Version"}
          onClick={() => handleNewVersion()}
          buttonState={newVersionState}
          setButtonState={setNewVersionState}
          style={"secondary"}
          key="NewVersion"
          leftIcon="simple-plus"
          classNameIn="w-full"
          id="newVersionButton"
        />

        <PortalDetalActionMenu
          version={version}
          portal={portal}
          teamId={team.id!}
          shows={showMenu}
          setShows={setShowMenu}
          updatedVersion={handleVersionUpdate}
          isLive={isLive}
        />

        <button
          id="actionMenuButton"
          className="bg-blue-50 hover:bg-blue-100 transition-all duration-200 w-12 rounded-lg items-center justify-center flex"
          onClick={() => setShowMenu(true)}
        >
          <Icon type="dots-horizontal" />
        </button>
      </div>

      <AnimatedButton
        title={"Open Test Center"}
        onClick={() => handleTestCenter()}
        buttonState={testcenterState}
        setButtonState={setTestcenterState}
        style={"transparent-action"}
        key="analytics"
        leftIcon="adjustments"
      />
    </div>
  );
};
