import React from "react";
import { PortalService } from "../services/PortalService";

const PortalContext = React.createContext<PortalService | undefined>(undefined);

export const usePortalService = (): PortalService => {
  const context = React.useContext(PortalContext);
  if (!context) {
    throw new Error(
      "usePortalService must be used within a PortalServiceProvider"
    );
  }
  return context;
};

export default PortalContext;
