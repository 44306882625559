import { PortalVersion } from "./PortalVersion";
import { TeamVariable } from "./Team";

export interface Portal {
  id: string;
  name: string;
  currentVersionData?: PortalVersion;
  currentVersionId?: string;
  parameters?: string[];
  createdAt: Date;
  modifiedAt: Date;
  currentABTestId?: string;
  lastEditedBy: string;
  usedVariables?: { [id: string]: TeamVariable };
}

interface PortalErrorResult {
  versionNameError?: string;
  portalNameError?: string;
  promptError?: string;
}

export const checkForErrors = (
  portal: Portal,
  version: PortalVersion
): PortalErrorResult | undefined => {
  const result: PortalErrorResult = {};
  if (version!.name.length < 1) {
    result.versionNameError = "Version needs a name";
  }

  if (portal!.name.length < 1) {
    result.portalNameError = "Portal needs a name";
  }

  if (version!.prompt.length < 1) {
    result.promptError = "Prompt needs content";
  }

  return Object.keys(result).length > 0 ? result : undefined;
};
