import { ReactNode } from "react";

export const Tooltip: React.FC<{
  title: string;
  description: string;
  children: ReactNode;
}> = ({ title, description, children }) => {
  return (
    <div className="group relative flex max-w-max flex-col items-center justify-center">
      {children}
      <div className="absolute left-1/2 bottom-1 ml-auto mr-auto min-w-max -translate-x-1/2 scale-0 transform rounded-lg px-3 py-2 text-xs transition-all duration-100 group-hover:scale-100">
        <div className="flex max-w-xs flex-col gap-0 items-center">
          <div className="rounded bg-gray-800 text-gray-0 p-2 text-left flex flex-col max-w-full gap-2">
            <div className="font-gooper text-base">{title}</div>
            <div className="text-xs text-gray-400 font-thin">{description}</div>
          </div>
          <div
            style={{
              backgroundColor: "#333",
              clipPath: "polygon(50% 100%, 0 0%, 100% 0%)",
              width: "16px",
              height: "10px",
              position: "absolute",
              bottom: "0px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
        </div>
      </div>
    </div>
  );
};
