import { getFunctions, httpsCallable } from "firebase/functions";
import firebaseApp from "../Firebase";
import { FunctionRepository } from "./FunctionRepository";

export class FirebaseFunctionRepository implements FunctionRepository {
  private functions = getFunctions(firebaseApp);

  async callFunction(name: string, data: any): Promise<any> {
    const fn = httpsCallable(this.functions, name);
    const result = await fn(data);
    return result.data;
  }
}
