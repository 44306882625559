import { getFirestore } from "firebase/firestore";
import firebaseApp from "../Firebase";
import { Repository } from "../repos/Repository";
import { FirestoreRepository } from "../repos/FirestoreRepository";
import { ChangeLog } from "../models/ChangeLog";

export interface ChangeLogService {
  changeLogRepo: Repository<ChangeLog>;
  portalChangeLogPath(teamId: string, portalId: string): string;
  versionChangeLogPath(
    teamId: string,
    portalId: string,
    versionId: string
  ): string;
}

export class FirestoreChangeLogService implements ChangeLogService {
  changeLogRepo = new FirestoreRepository<ChangeLog>(getFirestore(firebaseApp));
  portalChangeLogPath(teamId: string, portalId: string): string {
    return `teams/${teamId}/portals/${portalId}/changes`;
  }
  versionChangeLogPath(
    teamId: string,
    portalId: string,
    versionId: string
  ): string {
    return `teams/${teamId}/portals/${portalId}/versions/${versionId}/changes`;
  }
}
