export const Explainer: React.FC<{
  headline: string;
  items: string[];
}> = ({ headline, items }) => {
  return (
    <div className={`flex flex-col gap-2`}>
      <div className="font-gooper text-sm text-gray-700">{headline}</div>

      <div className="flex flex-col gap-1">
        {items.map((item, index) => {
          return (
            <div
              key={item}
              className={`pl-2 font-gooper text-sm text-gray-500`}
            >
              {`${index + 1}. ${item}`}
            </div>
          );
        })}
      </div>
    </div>
  );
};
