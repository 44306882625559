import React from "react";
import { useNavigate } from "react-router-dom";
import Icon, { IconType } from "../Icon";

interface NavigationLinkProps {
  title: string;
  icon: IconType;
  destination: string;
  isActive: boolean;
  teamId: string;
  isCollapsed: boolean;
  actionLink?: string;
  id?: string;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  title,
  destination,
  icon,
  isActive,
  teamId,
  isCollapsed,
  actionLink,
  id,
}) => {
  const navigate = useNavigate();

  const handleActionClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (actionLink) {
      navigate(actionLink);
    }
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };
  return (
    <div
      role="button"
      tabIndex={0}
      className={`flex items-center ${
        isCollapsed ? "justify-center" : "justify-between"
      } gap-2 py-2 px-1 hover:bg-blue-50 rounded-lg font-gooper ${
        isActive ? "text-blue-500" : "text-gray-800"
      }`}
      onClick={() => handleNavigation(`/teams/${teamId}/${destination}`)}
      id={id}
    >
      <div className="flex relative items-center gap-2">
        <Icon
          type={icon}
          className={`size-5 ${
            isCollapsed && isActive ? "text-blue-400" : "text-gray-300"
          }`}
        />
        {!isCollapsed && (
          <span className={`text-base font-medium leading-normal`}>
            {title}
          </span>
        )}
      </div>
      {actionLink && !isCollapsed && (
        <button onClick={handleActionClick} className="ml-aut relative z-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
          >
            <path
              d="M6 12.6489C4.81331 12.6489 3.65328 12.297 2.66658 11.6377C1.67989 10.9785 0.910851 10.0414 0.456725 8.94503C0.00259969 7.84867 -0.11622 6.64227 0.115291 5.47839C0.346802 4.3145 0.918247 3.2454 1.75736 2.40629C2.59648 1.56717 3.66557 0.995729 4.82946 0.764217C5.99335 0.532706 7.19974 0.651527 8.2961 1.10565C9.39246 1.55978 10.3295 2.32881 10.9888 3.31551C11.6481 4.3022 12 5.46224 12 6.64893C11.9982 8.23966 11.3654 9.76472 10.2406 10.8895C9.11579 12.0144 7.59074 12.6471 6 12.6489ZM8.67916 6.01735H6.63158V3.96977C6.63158 3.80227 6.56504 3.64162 6.44659 3.52318C6.32815 3.40473 6.16751 3.33819 6 3.33819C5.8325 3.33819 5.67185 3.40473 5.55341 3.52318C5.43496 3.64162 5.36842 3.80227 5.36842 3.96977V6.01735H3.32084C3.15334 6.01735 2.99269 6.08389 2.87425 6.20233C2.75581 6.32078 2.68927 6.48142 2.68927 6.64893C2.68927 6.81643 2.75581 6.97708 2.87425 7.09552C2.99269 7.21397 3.15334 7.28051 3.32084 7.28051H5.36842V9.32808C5.36842 9.49559 5.43496 9.65623 5.55341 9.77468C5.67185 9.89312 5.8325 9.95966 6 9.95966C6.16751 9.95966 6.32815 9.89312 6.44659 9.77468C6.56504 9.65623 6.63158 9.49559 6.63158 9.32808V7.28051H8.67916C8.84666 7.28051 9.00731 7.21397 9.12575 7.09552C9.2442 6.97708 9.31074 6.81643 9.31074 6.64893C9.31074 6.48142 9.2442 6.32078 9.12575 6.20233C9.00731 6.08389 8.84666 6.01735 8.67916 6.01735Z"
              className="fill-blue-500"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default NavigationLink;
