import React, { useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import { TeamSettingsSections } from "./TeamSettingsSections";
import { useTeams } from "../../contexts/TeamContext";
import { Team } from "../../models/Team";
import FailureModal from "../FailureModal";
import { CommonSpinner } from "../Common/CommonLoading";
import Icon from "../Icon";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { TeamSettingsNewTokenModal } from "./TeamSettingsNewTokenModal";
import { CommonConfirmationModal } from "../Common/CommonConfirmationModal";

export const TeamSettingsTokens: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();

  const teamService = useTeams();

  const [team, setTeam] = useState<Team>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [createState, setCreateState] = useState<AnimationState>("ready");
  const [newToken, setNewToken] = useState("");

  const [deletionModalIndex, setDeletionModalIndex] = useState<number>();

  const loadData = async () => {
    try {
      const fetchedTeam = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId!
      );
      if (fetchedTeam) {
        setTeam(fetchedTeam);
      }
    } catch (e) {
      setError("Failed to load team");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [teamService]);

  const tokenData = team?.tokenMetadata ?? {};
  const listData = Object.keys(tokenData)
    .map((key) => {
      return { id: key, data: tokenData[key] };
    })
    .sort((a, b) => b.data.createdAt.getTime() - a.data.createdAt.getTime());
  const teamMembers = team?.members ?? {};

  const handleCreateToken = async () => {
    setCreateState("loading");
    try {
      const newToken = await teamService.createNewToken(teamId!);
      setCreateState("success");
      setNewToken(newToken);
      loadData();
    } catch {
      setCreateState("error");
    }
  };

  const handleDeleteToken = async (tokenId: string) => {
    try {
      setDeletionModalIndex(undefined);
      await teamService.revokeToken(teamId!, tokenId);
      setCreateState("success");
      loadData();
    } catch {
      setCreateState("error");
    }
  };

  return (
    <CommonContainer>
      <CommonHeader
        title={`Access Tokens`}
        subtitle="Easily manage your access tokens for enhanced security and seamless authentication."
        sections={[
          { name: "Team Settings", link: `/${teamId}/settings` },
          { name: "Access Tokens", link: `/${teamId}/settings/tokens` },
        ]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            title={`Create Token`}
            onClick={handleCreateToken}
            buttonState={createState}
            setButtonState={setCreateState}
            style={"action"}
            key="create"
            id="createButton"
          />,
        ]}
      />
      <FailureModal
        shows={error != ""}
        message={error}
        closed={() => setError("")}
      />
      <TeamSettingsSections selectedId="tokens" />

      <TeamSettingsNewTokenModal
        shows={newToken != ""}
        token={newToken}
        setShows={() => {
          setNewToken("");
        }}
      />

      <table className="border  rounded-3xl border-gray-200 w-full bg-gray-200">
        <thead className="border-b border-gray-200">
          <tr className="">
            <Header title="Created By" />
            <Header title="Ends With" />
            <Header title="Created At" />
            <Header title="Action" className="w-1" padding="p-2" />
          </tr>
          {loading && listData.length == 0 && (
            <tr className="bg-gray-0">
              <td className="border-b border-gray-200 p-3 font-medium text-sm">
                <CommonSpinner />
              </td>
              <td className="border-b border-gray-200 p-3 font-medium text-sm">
                <CommonSpinner />
              </td>
              <td className="border-b border-gray-200 p-3 font-medium text-sm">
                <CommonSpinner />
              </td>
              <td className="border-b border-r border-gray-200 p-3 font-medium text-sm">
                <CommonSpinner />
              </td>
            </tr>
          )}
        </thead>
        {listData.map((token, index) => {
          return (
            <tbody key={index} id={`tokenCell${index}`}>
              <tr className="bg-gray-0">
                <td className="border-b text-gray-500 border-gray-200 p-3 font-normal text-sm">
                  {teamMembers[token.data.createdBy].name ??
                    "former team member"}
                </td>
                <td className="border-b p-3 text-sm text-gray-500 font-normal">
                  {`******${token.data.endsWith}`}
                </td>
                <td className="border-b p-3 text-sm text-gray-500 font-normal">
                  {new Date(token.data.createdAt)
                    .toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })
                    .replace(",", "")}
                </td>
                <td className="relative border-b border-gray-200 pl-6 font-medium text-sm">
                  <CommonConfirmationModal
                    title="Are you sure?"
                    message="Deleting this token will break your Application if this token is still in use."
                    onCancel={() => setDeletionModalIndex(undefined)}
                    isOpen={deletionModalIndex == index}
                    onConfirm={() => handleDeleteToken(token.id)}
                    confirmStyle="destructive"
                  />
                  <button
                    id={`deleteButton${index}`}
                    onClick={() => setDeletionModalIndex(index)}
                    className="size-7 rounded-full bg-red-50 hover:bg-blue-100 items-center flex justify-center"
                  >
                    <Icon type="trash" className="size-5 text-red-500" />
                  </button>
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </CommonContainer>
  );
};

const Header: React.FC<{
  title: string;
  className?: string;
  padding?: string;
}> = ({ title, className, padding }) => {
  return (
    <th
      className={`${
        padding ?? "p-3"
      } bg-gray-50 text-left p4 text-xs font-medium text-gray-500 uppercase tracking-widest ${className}`}
    >
      {title}
    </th>
  );
};
