import React, { useEffect, useState } from "react";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import Icon from "../Icon";
import { useTeams } from "../../contexts/TeamContext";
import { TeamRole, TeamRoles } from "../../models/Team";
import { EnteredMembers } from "../Teams/TeamOnboarding";
import MemberInput from "../Teams/MemberInput";

interface TeamSettingsMemberInputProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  teamId: string;
  pendingInviteCount: number;
}

export const TeamSettingsMemberInput: React.FC<
  TeamSettingsMemberInputProps
> = ({ shows, setShows, teamId, pendingInviteCount }) => {
  const [sendState, setSendState] = useState<AnimationState>("ready");

  const [members, setMembers] = useState<EnteredMembers>([]);

  const teamService = useTeams();

  const loadData = async () => {
    const getTeam = teamService.teamRepo.get(teamService.teamPath(), teamId);
    const getSubscription = teamService.teamSubscriptionRepo.get(
      teamService.teamSubscriptionPath(),
      teamId
    );
    const [team, subscription] = await Promise.all([getTeam, getSubscription]);
    const foundMembers = availableMembers(
      subscription?.currentEvent?.seats ?? 1,
      Object.keys(team?.members ?? {}).length
    );
    setMembers(foundMembers);
  };

  useEffect(() => {
    loadData();
  }, [teamService]);

  const handleSendInvite = async () => {
    setSendState("loading");
    const validMembers = members.filter((member) =>
      member.email.match(/.+@.+/)
    );
    const inviteOps = validMembers.map((member) =>
      teamService.invite(teamId, member.email, member.role, window.origin)
    );
    try {
      await Promise.all(inviteOps);
    } catch (e) {
      setSendState("error");
    }
    setSendState("success");
  };

  const availableMembers = (
    seats: number,
    usedSeats: number
  ): EnteredMembers => {
    return Array.from(
      { length: seats - usedSeats - pendingInviteCount },
      () => ({
        email: "",
        role: TeamRoles.EDITOR,
      })
    );
  };

  const handleSetEmail = (index: number, newEmail: string) => {
    const newTeamMembers = [...members];
    newTeamMembers[index].email = newEmail;
    setMembers(newTeamMembers);
  };

  const handleSetRole = (index: number, newRole: TeamRole) => {
    const newTeamMembers = [...members];
    newTeamMembers[index].role = newRole;
    setMembers(newTeamMembers);
  };

  if (!shows) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[496px] flex flex-col gap-3">
        <h2 className="text-2xl font-bold font-gooper text-center text-gray-900">
          Invite your colleagues
        </h2>
        <p className="text-center text-gray-500 ">
          Invite your team members and start collaborating right away. Select
          respective roles from the dropdown.
        </p>
        <div
          className={`grid gap-2 ${
            members.length > 5 ? "grid-cols-2" : "grid-cols-1"
          }`}
        >
          {members.map((member, index) => (
            <MemberInput
              key={`memberInput${index}`}
              selectedRole={member.role}
              email={member.email}
              setEmail={(email) => handleSetEmail(index, email)}
              setSelectedRole={(role) => handleSetRole(index, role)}
              index={index}
            />
          ))}
        </div>
        <div className="grid grid-cols-2 w-full gap-2">
          <AnimatedButton
            title={`Close`}
            onClick={() => {
              loadData();
              setShows(false);
            }}
            buttonState={"ready"}
            style={"normal"}
            key="cancel"
            font="font-sans"
            id="cancelButton"
          />
          <AnimatedButton
            title={`Send Invite`}
            onClick={() => handleSendInvite()}
            buttonState={sendState}
            setButtonState={setSendState}
            style={"action"}
            key="action"
            leftChild={<Icon type="paper-plane" className="rotate-90 size-4" />}
            font="font-sans"
            id="confirmButton"
            after={(success) => {
              if (success) {
                setShows(false);
                loadData();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
