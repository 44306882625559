import React from "react";
import Icon from "../Icon";
import { useNavigate } from "react-router-dom";
import { AppPath } from "../../models/AppPath";

interface NavigationResubButtonProps {
  isCollapsed: boolean;
  teamId: string;
}

export const NavigationResubButton: React.FC<NavigationResubButtonProps> = ({
  isCollapsed,
  teamId,
}) => {
  const navigate = useNavigate();
  if (isCollapsed) {
    return (
      <div
        onClick={() => navigate(AppPath.billing(teamId))}
        className="flex items-center justify-center hover:bg-blue-50 rounded-lg -mx-2 p-2"
      >
        <button className={`text-red-500 `}>
          <Icon type="alert" />
        </button>
      </div>
    );
  }
  return (
    <button
      onClick={() => navigate(AppPath.billing(teamId))}
      className={`${
        isCollapsed ? "invisible" : ""
      } py-2.5 w-full px-3 bg-gray-100 border border-red-500 hover:bg-blue-100 hover:border-gray-50 rounded-lg justify-center items-center gap-2 flex`}
    >
      <div className="text-center text-red-500 text-xs font-semibold leading-none">
        Fix Billing!
      </div>
    </button>
  );
};
