import React, { ReactNode } from "react";
import TeamContext from "../contexts/TeamContext";
import { TeamService } from "../services/TeamService";

interface TeamProviderProps {
  service: TeamService;
  children: ReactNode;
}

const TeamProvider: React.FC<TeamProviderProps> = ({ service, children }) => {
  return (
    <TeamContext.Provider value={service}>{children}</TeamContext.Provider>
  );
};

export default TeamProvider;
