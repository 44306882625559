import React, { useEffect } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useParams } from "react-router-dom";
import { User } from "../../models/User";
import { STORAGE_KEYS } from "../../utils/StorageKeys";
import BannerCareousel from "./BannerCarousel";
import { useAuth } from "../../contexts/AuthContext";
import GetStartedPortals from "./GetStartedPortals";
import GetStartedTeams from "./GetStartedTeams";
import GetStartedAPI from "./GetStartedAPI";
import PortalsCard from "./PortalsCard";
import LogsCard from "./LogsCard";
import CommonContainer from "../Common/CommonContainer";

interface HomePageProps {
  currentUser: User | undefined;
}

const HomePage: React.FC<HomePageProps> = ({ currentUser }) => {
  const { teamId } = useParams<{ teamId: string }>();
  const auth = useAuth();

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    localStorage.setItem(STORAGE_KEYS.LAST_ACCESSED_TEAM, teamId!);
    auth.userRepo.update(
      { inProgressTeam: undefined },
      auth.userPath(),
      currentUser?.id
    );
  }, [teamId]);

  const setUp =
    localStorage.getItem(STORAGE_KEYS.COMPLETED_API) == "true" &&
    localStorage.getItem(STORAGE_KEYS.COMPLETED_MEMBERS) == "true" &&
    localStorage.getItem(STORAGE_KEYS.COMPLETED_PORTALS) == "true";

  return (
    <CommonContainer>
      <CommonHeader
        title={`Welcome ${currentUser?.name ?? "Someone"}`}
        subtitle="Get started by completing the 3 easy steps below. Or explore easybeam while waiting for your team members to join."
        sections={[]}
        teamId={teamId!}
        actions={[]}
      />
      <div className="">
        <BannerCareousel currentUser={currentUser} />
      </div>
      {setUp ? (
        <div className="flex flex-col">
          <div className="text-lg text-gray-500"></div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row -mx-2 gap-y-4">
          <div className="md:w-1/3 px-2">
            <GetStartedPortals teamId={teamId!} />
          </div>
          <div className="md:w-1/3 px-3">
            <GetStartedTeams teamId={teamId!} />
          </div>
          <div className="md:w-1/3 px-2">
            <GetStartedAPI teamId={teamId!} />
          </div>
        </div>
      )}
      <div className="">
        <PortalsCard teamId={teamId!} />
      </div>
      <div className="">
        <LogsCard teamId={teamId!} />
      </div>
    </CommonContainer>
  );
};

export default HomePage;
