import React, { ReactNode } from "react";

interface CommonContainerProps {
  children: ReactNode;
  className?: string;
}
// TODO: This padding needs to be updated after removing
const CommonContainer: React.FC<CommonContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={`flex overflow-y-scroll flex-col p-2 ${className} md:p-16 w-full h-svh bg-gray-50 gap-4`}
    >
      {children}
    </div>
  );
};

export default CommonContainer;
