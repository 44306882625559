import React from "react";
import { useReactFlow } from "reactflow";
import Icon from "../../../Icon";
import { createSelectorNode } from "./SelectorNode";
import { AddNodeHandler } from "../Handlers/AddNodeHandler";
import { Workflow } from "../../../../models/Workflow";
import { SelectedInputs } from "./PortalNode";

export const WorkflowNodeType = "WorkflowlNodeType";

export interface WorkflowNodeData {
  id: string;
  teamId: string;
  type: typeof WorkflowNodeType;
  title: string;

  selectedInputs?: SelectedInputs;

  currentWorkflow?: Workflow;
}

export interface WorkflowNodeParams {
  data: WorkflowNodeData;
}

export const WorkflowNode: React.FC<WorkflowNodeParams> = ({ data }) => {
  const { getNode, setNodes, getEdges } = useReactFlow();

  const handleAdd = () => {
    const currentNode = getNode(data.id);
    if (!currentNode) return;

    const selectionNode = createSelectorNode(currentNode, data.teamId);

    setNodes((nds) => nds.concat(selectionNode));
  };

  const sourceConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.source === data.id);
  };

  const targetConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.target === data.id);
  };

  const selected = getNode(data.id)?.selected;
  const nodeClasses = `shadow group box-border border-2 ${
    selected ? "border-blue-500" : "border-transparent hover:border-blue-500"
  } flex flex-col gap-1 rounded-lg p-3 bg-gray-0 w-56`;

  return (
    <div className={nodeClasses}>
      <AddNodeHandler
        type="target"
        isConnected={targetConnected()}
        id={`${data.id}_target`}
      />
      <div className="h-6 justify-start items-start inline-flex">
        <div className="px-2.5 py-0.5 bg-green-50 rounded justify-center items-center gap-1 flex">
          <Icon type="workflows" className="size-3 text-green-400" />
          <div className="text-center text-green-400 text-xs font-medium leading-[18px]">
            Workflow
          </div>
        </div>
      </div>
      <div className="text-2xl font-gooper text-gray-900 h-12 truncate">
        {data.title}
      </div>
      <div className="flex flex-row justify-between">
        <div className="text-sm text-gray-500 truncate">
          {data.currentWorkflow?.name}
        </div>
        <div className="text-sm text-gray-500 truncate"></div>
      </div>
      <AddNodeHandler
        onClick={handleAdd}
        type="source"
        isConnected={sourceConnected()}
        id={`${data.id}_source`}
      />
    </div>
  );
};
