import { useReactFlow } from "reactflow";
import { PortalNodeData } from "../../Map/Nodes/PortalNode";
import { CommonInputSegment } from "../../../Common/CommonInput";
import { useEffect, useState } from "react";
import { VersionDetailCard } from "../../../Portals/Portal Detail/VersionDetailCard";
import { Team } from "../../../../models/Team";
import { ChatConfigurationTemplate } from "../../../../models/ChatConfigurationTemplate";
import { useTeams } from "../../../../contexts/TeamContext";
import Switch from "./Switch";
import { usePortalService } from "../../../../contexts/PortalContext";
import { CommonSpinner } from "../../../Common/CommonLoading";
import { extractVariableValues } from "../../../../utils/PromptUtil";

export const SidebarPortalDetail: React.FC<{
  team: Team;
  setTeam: (team: Team) => void;
  data: PortalNodeData;
  templates: ChatConfigurationTemplate[];
}> = ({ team, data, templates, setTeam }) => {
  const { setNodes } = useReactFlow();

  const teamService = useTeams();
  const portalService = usePortalService();

  const [promptError, setPromptError] = useState("");

  const updateNode = (newData: Partial<PortalNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  const handledAddedVariable = async (newVariable: string) => {
    const newVariables = team?.variables ?? {};
    newVariables[newVariable] = { id: newVariable };
    await teamService.teamRepo.update(
      { variables: newVariables },
      teamService.teamPath(),
      team.id!
    );
    setTeam({ ...team!, variables: newVariables });
  };

  useEffect(() => {
    const loadData = async () => {
      if (data.currentVersion != undefined) {
        return;
      }
      const newVersion = await portalService.createVersion(team.id!);
      updateNode({ currentVersion: newVersion });
    };
    loadData();
  }, [data.currentVersion]);

  return (
    <div className="flex flex-col gap-4">
      <CommonInputSegment
        title="Portal Beam Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Beam Title"
        error={undefined}
        setError={undefined}
        id="beamTitle"
        className="font-sans text-gray-700"
      />

      {!data.currentVersion && <CommonSpinner />}

      {data.currentVersion && (
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col">
            <div className="text-sm font-medium text-gray-700">
              Include Messages
            </div>
            <div className="text-xs text-gray-500">Send messages to the ai</div>
          </div>
          <Switch
            isOn={data?.includeMessages == true}
            onToggle={(n) => updateNode({ includeMessages: n })}
          />
        </div>
      )}
      {data.currentVersion && (
        <VersionDetailCard
          version={data.currentVersion}
          team={team}
          teamId={team.id!}
          updatePrompt={(prompt) =>
            updateNode({
              currentVersion: { ...data.currentVersion!, prompt },
              demoVariables: extractVariableValues(prompt ?? ""),
            })
          }
          templates={templates}
          updatedVersion={(currentVersion) => updateNode({ currentVersion })}
          variables={
            Object.keys(team?.variables ?? {}).map(
              (key) => (team?.variables ?? {})[key].id
            ) ?? []
          }
          promptError={promptError}
          setPromptError={setPromptError}
          addedVariable={handledAddedVariable}
          hideCard={true}
        />
      )}
    </div>
  );
};
