import React from "react";
import Icon, { IconType } from "../Icon";

interface CommonButtonProps {
  title: string;
  onClick: () => void;
  leftIcon?: IconType;
  rightIcon?: IconType;
}

const CommonButton: React.FC<CommonButtonProps> = ({
  title,
  onClick,
  leftIcon,
  rightIcon,
}) => {
  return (
    <button
      key={title}
      className="h-12 px-3 py-2 flex flex-row justify-center items-center gap-2 bg-blue-500 rounded-lg"
      onClick={() => onClick()}
    >
      {leftIcon && (
        <svg className="w-3 h-3 fill-gray-0" viewBox="0 0 12 12">
          <Icon type={leftIcon} />
        </svg>
      )}
      <div className="text-gray-0  font-gooper">{title}</div>
      {rightIcon && (
        <svg className="w-3 h-3 fill-gray-0" viewBox="0 0 12 12">
          <Icon type={rightIcon} />
        </svg>
      )}
    </button>
  );
};

export default CommonButton;
