import React from "react";
import { WorkflowConverter } from "../services/WorkflowConverter";
import WorkflowConverterContext from "../contexts/WorkflowConverterContext";

type Props = {
  children: React.ReactNode;
  workflowConverter: WorkflowConverter;
};

export const WorkflowConverterProvider: React.FC<Props> = ({
  children,
  workflowConverter: workflowConverter,
}) => {
  return (
    <WorkflowConverterContext.Provider value={workflowConverter}>
      {children}
    </WorkflowConverterContext.Provider>
  );
};

export default WorkflowConverterProvider;
