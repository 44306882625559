import { ChatConfigurationModelInput } from "../../../../models/ChatConfigurationTemplate";
import Icon from "../../../Icon";
import { Tooltip } from "./Tooltip";

export const ModelSelector: React.FC<{
  input: ChatConfigurationModelInput;
  value: string;
  update: (model: string) => void;
}> = ({ input, value, update }) => {
  let gridCols = "grid-cols-5";
  switch (input.values.length) {
    case 1:
      gridCols = "grid-cols-1";
      break;
    case 2:
      gridCols = "grid-cols-2";
      break;
    case 3:
      gridCols = "grid-cols-3";
      break;
    case 4:
      gridCols = "grid-cols-4";
      break;
  }
  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row gap-1 items-center">
        <div className={`text-sm font-gooper text-gray-700`}>{input.name}</div>
        <Tooltip title={input.name} description={input.description}>
          <svg
            data-tooltip-target="tooltip-default"
            className="w-3 h-3 fill-current text-gray-300"
            viewBox="0 0 24 24"
          >
            <Icon type={"info"} />
          </svg>
        </Tooltip>
      </div>
      <div className="text-gray-500 text-xs">{input.shortDescription}</div>
      <div className={`grid gap-2 ${gridCols}`}>
        {input.values.map((model, index) => (
          <button
            key={index}
            onClick={() => update(model.id)}
            className={`bg-gray-0 p-2 font-gooper text-sm rounded-lg ${
              model.id === value ? "text-blue-500" : "text-gray-500"
            }`}
          >
            {model.name}
          </button>
        ))}
      </div>
    </div>
  );
};
