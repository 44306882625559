import { Modal } from "flowbite-react";
import { type FC } from "react";

const FailureModal: FC<{
  title?: string;
  message?: string;
  backButtonTitle?: string;
  shows: boolean;
  closed: () => void;
}> = function ({ title, message, backButtonTitle, shows, closed }) {
  return (
    <>
      <Modal onClose={closed} popup size="md" show={shows}>
        <Modal.Body className="outline outline-transparent space-y-6 p-4 focus:ring-0 focus:outline-none relativetext-center bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="flex justify-center items-center">
            <img src="/images/warning-exclamation.svg" />
          </div>
          <div>
            <div className="text-2xl text-center font-semibold font-gooper text-gray-900">
              {title ?? "Something went wrong!"}
            </div>
            <div className="text-m pt-1 text-center font-normal text-gray-500">
              {message ?? "Please try again later"}
            </div>
          </div>
          <button
            type="submit"
            className="flex bg-transparent text-blue-500 items-center justify-center w-full px-4 py-2 text-sm font-medium text-center rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
            onClick={() => closed()}
          >
            <svg
              className="w-4 h-4 mr-2 -ml-1"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M19 12H5"></path> {/* Stem of the arrow */}
              <path d="M12 19l-7-7 7-7"></path> {/* Arrowhead */}
            </svg>
            {backButtonTitle ?? "Back"}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FailureModal;
