import React from "react";
import { TestService } from "../services/TestService";

const TestContext = React.createContext<TestService | undefined>(undefined);

export const useTestService = (): TestService => {
  const context = React.useContext(TestContext);
  if (!context) {
    throw new Error("useTestService must be used within a TestServiceProvider");
  }
  return context;
};

export default TestContext;
