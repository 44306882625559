import React, { ReactNode } from "react";
import AuthContext from "../contexts/AuthContext";
import { AuthService } from "../services/AuthService";

interface AuthProviderProps {
  service: AuthService;
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ service, children }) => {
  return (
    <AuthContext.Provider value={service}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
