import { getFirestore } from "firebase/firestore";
import { UserEvent, UserActivity } from "../models/UserEvent";
import { FirestoreRepository } from "../repos/FirestoreRepository";
import { AuthService } from "./AuthService";

import firebaseApp from "../Firebase";
import { FirestorePath } from "../models/FirestorePath";

export interface UserEventService {
  createEvent(auth: AuthService, activity: UserActivity): Promise<void>;
}

export class FirestoreUserEventService implements UserEventService {
  userEventRepo = new FirestoreRepository<UserEvent>(getFirestore(firebaseApp));

  async createEvent(auth: AuthService, activity: UserActivity): Promise<void> {
    const user = await auth.currentUser();

    if (!user?.email) {
      console.error("No user email");
      return;
    }

    const newEvent: UserEvent = {
      createdAt: new Date(),
      activity,
      email: user.email,
    };

    await this.userEventRepo.create(
      newEvent,
      FirestorePath.userEvents(user.id)
    );
  }
}
