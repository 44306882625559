import React, { useEffect, useRef, useState } from "react";
import Icon from "../Icon";
import Datepicker from "react-tailwindcss-datepicker";
import { DateValueType } from "react-tailwindcss-datepicker";

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface DateRangeFilterParams {
  dateRange: DateRange | null;
  selectedDateRange: (range: DateRange | null) => void;
}

const DateRangeFilter: React.FC<DateRangeFilterParams> = ({
  dateRange,
  selectedDateRange,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleClick = () => {
    setShowDropdown(true);
  };

  const handleValueChange = (value: DateValueType) => {
    const startDate = value?.startDate;
    const endDate = value?.endDate;
    if (
      typeof startDate != "string" ||
      !startDate ||
      typeof endDate != "string" ||
      !endDate
    ) {
      selectedDateRange(null);
      return;
    }

    selectedDateRange({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    });
  };

  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // JavaScript months are 0-indexed
    const year = date.getFullYear().toString();

    return `${year}-${month}-${day}`;
  };

  const dateString = !dateRange
    ? "all Dates"
    : `${formatDate(dateRange!.startDate)}~${formatDate(dateRange!.endDate)}`;

  return (
    <div className="flex gap-2 flex-col " ref={wrapperRef}>
      <div
        onClick={() => handleClick()}
        className="w-full py-2 px-3 relative bg-gray-100 border border-gray-200 rounded flex justify-between items-center cursor-pointer"
      >
        {showDropdown && (
          <div className="absolute flex flex-col p-2 top-0 w-full text-left border-1 border-gray-300 bg-gray-50 right-0 z-50 rounded-md shadow-lg">
            <Datepicker
              inputClassName="font-gooper text-sm"
              containerClassName="font-gooper font-semibold"
              value={dateRange}
              onChange={handleValueChange}
              primaryColor={"lime"}
            />
          </div>
        )}
        <span className="text-gray-500 font-gooper text-sm">{`${dateString}`}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="10"
          className="stroke-gray-500"
        >
          <Icon type="chevron" />
        </svg>
      </div>
    </div>
  );
};

export default DateRangeFilter;
