import React from "react";
import { useNavigate } from "react-router-dom";

export interface BreadcrumbSection {
  name: string;
  link: string;
}

interface BreadcrumbProps {
  sections: BreadcrumbSection[];
  teamId: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ sections, teamId }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center w-full gap-2.5">
      <button
        className="flex items-center gap-2.5"
        onClick={() => navigate(`/teams/${teamId}/home`)}
      >
        <svg className="w-3 h-3 fill-gray-200" viewBox="0 0 12 12">
          <path d="M11.8314 5.57573L10.6299 4.37572L6.42472 0.175674C6.31207 0.0631898 6.15929 0 6 0C5.84071 0 5.68793 0.0631898 5.57528 0.175674L1.37011 4.37572L0.168634 5.57573C0.059205 5.68889 -0.00134602 5.84046 2.27095e-05 5.99778C0.00139144 6.1551 0.0645704 6.30559 0.175952 6.41683C0.287334 6.52808 0.438006 6.59118 0.595518 6.59255C0.75303 6.59391 0.904778 6.53344 1.01808 6.42414L1.19409 6.24834V10.8C1.19409 11.1182 1.32068 11.4235 1.546 11.6485C1.77132 11.8736 2.07692 12 2.39557 12H4.19779C4.35711 12 4.50991 11.9368 4.62257 11.8243C4.73523 11.7117 4.79852 11.5591 4.79852 11.4V8.99997C4.79852 8.84084 4.86182 8.68822 4.97448 8.5757C5.08714 8.46318 5.23994 8.39996 5.39926 8.39996H6.60074C6.76006 8.39996 6.91286 8.46318 7.02552 8.5757C7.13818 8.68822 7.20148 8.84084 7.20148 8.99997V11.4C7.20148 11.5591 7.26477 11.7117 7.37743 11.8243C7.49009 11.9368 7.64289 12 7.80221 12H9.60443C9.92308 12 10.2287 11.8736 10.454 11.6485C10.6793 11.4235 10.8059 11.1182 10.8059 10.8V6.24834L10.9819 6.42414C11.0952 6.53344 11.247 6.59391 11.4045 6.59255C11.562 6.59118 11.7127 6.52808 11.824 6.41683C11.9354 6.30559 11.9986 6.1551 12 5.99778C12.0013 5.84046 11.9408 5.68889 11.8314 5.57573Z" />
        </svg>
        <div className="text-xs text-gray-500">Home</div>
      </button>
      {sections.map((section) => (
        <button
          key={section.name}
          className="flex items-center gap-2.5"
          onClick={() => navigate(section.link)}
        >
          <svg
            className="w-3 h-3 fill-gray-200"
            viewBox="0 0 12 12"
            aria-hidden="true"
          >
            <path d="M4.36963 11.4C4.17228 11.3999 3.97937 11.3366 3.81529 11.2181C3.65121 11.0996 3.52333 10.9311 3.44781 10.7341C3.37229 10.537 3.35253 10.3201 3.39102 10.1109C3.42951 9.90171 3.52452 9.70953 3.66405 9.55868L6.95044 6.0066L3.66405 2.45453C3.56873 2.35502 3.4927 2.236 3.4404 2.10439C3.3881 1.97279 3.36056 1.83125 3.35941 1.68802C3.35826 1.5448 3.38351 1.40276 3.43369 1.27019C3.48387 1.13763 3.55798 1.01719 3.65168 0.915912C3.74538 0.814632 3.85681 0.734537 3.97946 0.6803C4.10211 0.626064 4.23353 0.598772 4.36604 0.600016C4.49855 0.601261 4.62951 0.631018 4.75127 0.68755C4.87303 0.744082 4.98315 0.826258 5.07521 0.929282L9.06718 5.24398C9.25427 5.44626 9.35938 5.72058 9.35938 6.0066C9.35938 6.29263 9.25427 6.56694 9.06718 6.76923L5.07521 11.0839C4.88809 11.2862 4.63429 11.3999 4.36963 11.4Z" />
          </svg>
          <div className="text-xs text-gray-500">{section.name}</div>
        </button>
      ))}
    </div>
  );
};

export default Breadcrumb;
