import { useAuth } from "../../contexts/AuthContext";

import { useState, type FC, useRef, useEffect } from "react";
import AuthPage from "./AuthPage";

const VerifyEmail: FC = function () {
  const auth = useRef(useAuth());
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    auth.current
      .currentUser()
      .then((user) => {
        setEmail(user?.email ?? "");
        return auth.current.reloadAuth();
      })
      .catch((e) => {
        console.error("Failed to reload user:", e);
      });
  }, [auth]);

  const resendEmail = async () => {
    setIsLoading(true);
    try {
      await auth.current.sendValidationEmail(window.location.origin);
      setSuccessMessage("Sent again!");
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const logOut = async () => {
    setIsLoading(true);
    try {
      await auth.current.signOut();
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthPage
      error={error}
      modalBackTitle="return to verify email"
      setError={setError}
      isLoading={isLoading}
      successMessage={successMessage}
      setSuccessMessage={() => setSuccessMessage("")}
    >
      <div className="w-[344px] md:w-[512px] col-span-6 mx-auto space-y-6 p-6 rounded-2xl bg-white shadow">
        <div className="flex justify-center items-center">
          <img src="/images/mailbox-icon.svg" />
        </div>
        <div>
          <div className="text-2xl text-center font-semibold font-gooper text-gray-900">
            {"Verify your email"}
          </div>
          <div className="text-m pt-1 text-center font-normal text-gray-500">
            {`We've sent a message to ${email} with a link to activate your account.`}
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-2 justify-center items-center w-full mt-6">
          <a
            href="https://mail.google.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-1/2 sm:w-1/3 px-2 py-2 bg-gray-100 rounded-lg border border-gray-200 text-xs font-medium text-blue-500 hover:bg-gray-100"
          >
            <img
              src="/images/gmail-logo.svg"
              alt="Outlook"
              className="w-4 h-4 mr-2"
            />
            Gmail
          </a>
          <a
            href="message://%00"
            className="flex items-center justify-center w-1/2 sm:w-1/3 px-2 py-2 bg-gray-100 rounded-lg border border-gray-200 text-xs font-medium text-blue-500 hover:bg-gray-100"
          >
            <img
              src="/images/apple-mail-logo.svg"
              alt="Outlook"
              className="w-4 h-4 mr-2"
            />
            Apple Mail
          </a>
          <a
            href="https://outlook.live.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-1/2 sm:w-1/3 px-2 py-2 bg-gray-100 rounded-lg border border-gray-200 text-xs font-medium text-blue-500 hover:bg-gray-100"
          >
            <img
              src="/images/outlook-logo.svg"
              alt="Outlook"
              className="w-4 h-4 mr-2"
            />
            Outlook
          </a>
        </div>
      </div>
      <a
        onClick={() => resendEmail()}
        className="flex bg-transparent border-0 items-center justify-center w-1/3 px-2 py-4 bg-gray-100 rounded-lg border-gray-200 text-sm font-medium text-blue-500 hover:bg-gray-100"
      >
        Resend email
        <img
          src="/images/arrow-right.svg"
          alt="Outlook"
          className="w-4 h-4 ml-2"
        />
      </a>

      <a
        onClick={() => logOut()}
        className="flex bg-transparent border-0 items-center justify-center w-1/3 px-2 py-4 bg-gray-100 rounded-lg border-gray-200 text-xs font-medium text-blue-500 hover:bg-gray-100"
      >
        Exit
      </a>
    </AuthPage>
  );
};

export default VerifyEmail;
