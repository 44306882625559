import React from "react";
import { WorkflowConverter } from "../services/WorkflowConverter";

const WorkflowConverterContext = React.createContext<
  WorkflowConverter | undefined
>(undefined);

export const useWorkflowConverter = (): WorkflowConverter => {
  const context = React.useContext(WorkflowConverterContext);
  if (!context) {
    throw new Error(
      "useWorkflowService must be used within a WorkflowServiceProvider"
    );
  }
  return context;
};

export default WorkflowConverterContext;
