import React from "react";
import { PortalLogService } from "../services/PortalLogService";
import PortalLogContext from "../contexts/PortalLogContext";

type Props = {
  children: React.ReactNode;
  portalLogService: PortalLogService;
};

export const PortalLogServiceProvider: React.FC<Props> = ({
  children,
  portalLogService,
}) => {
  return (
    <PortalLogContext.Provider value={portalLogService}>
      {children}
    </PortalLogContext.Provider>
  );
};

export default PortalLogServiceProvider;
