import { ReactNode, useEffect } from "react";
import Icon, { IconType } from "./Icon";
import { CommonSpinner } from "./Common/CommonLoading";

export type AnimationState =
  | "ready"
  | "loading"
  | "success"
  | "shake"
  | "error";
export type AnimatedButtonStyle =
  | "action"
  | "secondary"
  | "normal"
  | "destructive"
  | "transparent"
  | "transparent-action"
  | "transparent-destructive"
  | "transparent-black"
  | "live";

interface AnimatedButtonProps {
  title: string;
  buttonState: AnimationState;
  setButtonState?: (state: AnimationState) => void;
  onClick: (event: React.MouseEvent) => void;
  leftIcon?: IconType;
  rightIcon?: IconType;
  disabled?: boolean;
  style: AnimatedButtonStyle;
  classNameIn?: string;
  leftChild?: ReactNode;
  font?: string;
  id?: string;
  after?: (success: boolean) => void;
}

export const AnimatedButton: React.FC<AnimatedButtonProps> = ({
  title,
  buttonState,
  setButtonState,
  onClick,
  leftIcon,
  rightIcon,
  style,
  disabled,
  classNameIn,
  leftChild,
  font,
  id,
  after,
}) => {
  useEffect(() => {
    if (buttonState === "shake") {
      const timer = setTimeout(() => {
        setButtonState?.("ready");
      }, 820);
      return () => clearTimeout(timer);
    } else if (buttonState === "success" || buttonState == "error") {
      const timer = setTimeout(() => {
        after?.(buttonState === "success");
        setButtonState?.("ready");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [buttonState, setButtonState]);

  let buttonText = title;
  let className = "";

  switch (style) {
    case "action":
      if (disabled) {
        className = "bg-gray-200 text-gray-400 stroke-gray-300";
      } else {
        className = "bg-blue-500 hover:bg-blue-600 text-gray-0 stroke-gray-0";
      }
      break;
    case "secondary":
      if (disabled) {
        className = "bg-gray-200 text-gray-400 stroke-gray-300";
      } else {
        className = "bg-blue-50 hover:bg-blue-100 text-blue-500";
      }
      break;
    case "normal":
      if (disabled) {
        className = "bg-gray-200 text-gray-400 stroke-gray-300";
      } else {
        className =
          "bg-gray-100 text-gray-500 hover:bg-gray-200 stroke-gray-500";
      }
      break;
    case "destructive":
      if (disabled) {
        className = "bg-red-300 text-gray-0 stroke-gray-0";
      } else {
        className =
          "bg-red-500 hover:bg-red-600 text-gray-0 stroke-gray-0 fill-transparent";
      }
      break;
    case "transparent":
      if (disabled) {
        className = "bg-transparent text-gray-200 ";
      } else {
        className = "bg-transparent text-gray-400 ";
      }
      break;
    case "transparent-action":
      if (disabled) {
        className = "bg-transparent text-blue-200 ";
      } else {
        className = "bg-transparent hover:bg-blue-50 text-blue-600 ";
      }
      break;
    case "transparent-black":
      if (disabled) {
        className = "bg-transparent text-gray-200 ";
      } else {
        className = "bg-transparent hover:bg-blue-50 text-gray-900 ";
      }
      break;
    case "transparent-destructive":
      if (disabled) {
        className = "bg-transparent text-red-200 ";
      } else {
        className = "bg-transparent text-red-400 ";
      }
      break;
    case "live":
      if (disabled) {
        className = "bg-green-200 text-gray-0 ";
      } else {
        className = "bg-green-500 hover:bg-green-600 text-gray-0 ";
      }
      break;
  }

  switch (buttonState) {
    case "loading":
      buttonText = "Loading";
      break;
    case "success":
      buttonText = "Success!";
      className = "bg-green-500 text-gray-0 stroke-gray-0";
      break;
    case "error":
      buttonText = "Failed!";
      className = "bg-red-500 text-gray-0 stroke-gray-0";
      break;
  }

  return (
    <button
      id={id}
      className={`px-3 py-2 h-10  min-h-10 max-h-10 flex flex-row justify-center items-center gap-2 rounded-lg transition-colors duration-200 ${className} ${classNameIn} ${
        buttonState === "shake" ? "animate-shake" : ""
      }`}
      onClick={onClick}
      disabled={buttonState === "loading" || disabled}
    >
      {leftIcon && buttonState != "success" && (
        <Icon type={leftIcon} className="size-4" />
      )}
      {leftChild && buttonState != "success" && leftChild}
      {buttonState == "success" && <Icon type={"check"} className="size-4" />}
      {buttonState == "loading" ? (
        <CommonSpinner size={"sm"} />
      ) : (
        <div className={`${font ?? "font-gooper"} text-sm  md:text-md`}>
          {buttonText}
        </div>
      )}
      {rightIcon && <Icon type={rightIcon} className="size-4" />}
    </button>
  );
};

export default AnimatedButton;
