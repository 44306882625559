import { useState } from "react";
import { Portal } from "../../../models/Portal";
import { PortalVersion } from "../../../models/PortalVersion";
import Icon from "../../Icon";
import { VersionCard } from "./VersionCard";

export const VersionList: React.FC<{
  portal: Portal;
  versions: PortalVersion[];
  selectedVersion: (version: PortalVersion) => void;
  clickedVersionHistory: () => void;
  currentlySelected: PortalVersion;
  teamId: string;
}> = ({
  versions,
  selectedVersion,
  portal,
  currentlySelected,
  clickedVersionHistory,
  teamId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleButtonTitle = isOpen ? "Close List" : "Open List";

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`${
        isOpen
          ? "-mx-2 md:-mx-16 border-y border-r bg-blue-50"
          : "border rounded-lg bg-gray-0"
      } flex gap flex-col border-gray-200  pt-3 pb-2 transition-all duration-100`}
    >
      <div
        className={`max-w-full w-full min-w-full flex flex-row justify-between pl-2 pr-6`}
      >
        <div className="flex flex-row gap-2 items-center">
          <Icon type={"versions"} className="size-4 text-blue-500" />
          <div className={`font-gooper text-lg text-gray-600`}>
            Version history of this portal
          </div>
        </div>
        <div className={`flex flex-row gap-4`}>
          <button
            onClick={handleToggle}
            className={`flex flex-row items-center gap-1 font-gooper text-lg font-medium text-blue-500`}
          >
            <svg
              className={`w-6 h-6 fill-blue-50 stroke-blue-500 transition-transform duration-300 ${
                isOpen ? "" : "rotate-180"
              }`}
              viewBox="0 0 24 24"
            >
              <Icon type={"chevron-up"} />
            </svg>
            {toggleButtonTitle}
          </button>
          <button
            onClick={clickedVersionHistory}
            className={`flex flex-row items-center gap-1 font-gooper text-lg font-medium text-blue-500`}
          >
            <svg
              className={`w-4 h-4 fill-blue-400 stroke-blue-500 `}
              viewBox="0 0 24 24"
            >
              <Icon type={"expand"} />
            </svg>
            View All
          </button>
        </div>
      </div>
      {isOpen && (
        <div
          className={`max-w-ful pt-4 w-48 min-w-full overflow-y-visible overflow-x-scroll px-6 pb-4`}
        >
          <div className="flex gap-4 overflow-y-visible">
            {versions.map((version) => (
              <div key={version.id} className="w-48 flex-none">
                <VersionCard
                  version={version}
                  selectedVersion={selectedVersion}
                  portal={portal}
                  currentlySelected={currentlySelected}
                  teamId={teamId}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
