import { createContext, useContext } from "react";
import { TeamService } from "../services/TeamService";
const TeamContext = createContext<TeamService | undefined>(undefined);

export const useTeams = () => {
  const context = useContext(TeamContext);
  if (!context) {
    throw new Error("useTeams must be used within a TeamProvider");
  }
  return context;
};

export default TeamContext;
