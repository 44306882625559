import { CommonInputSegment } from "../../../Common/CommonInput";
import { useReactFlow } from "reactflow";
import { WebsiteNodeData } from "../../Map/Nodes/WebsiteNode";

export const SidebarWebsiteDetail: React.FC<{
  data: WebsiteNodeData;
}> = ({ data }) => {
  const { setNodes } = useReactFlow();

  const updateNode = (newData: Partial<WebsiteNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  return (
    <div className="flex flex-col gap-2">
      <CommonInputSegment
        title="Website Beam Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Beam Title"
        error={undefined}
        setError={undefined}
        id="beamTitle"
        className="font-sans text-gray-700"
      />

      <div className="flex flex-col gap-1">
        <CommonInputSegment
          title="URL"
          value={data.url ?? ""}
          onChange={(v) => updateNode({ url: v })}
          placeholder="https://www.something.com"
          error={undefined}
          setError={undefined}
          id="beamTitle"
          className="font-sans text-gray-700"
        />
      </div>
    </div>
  );
};
