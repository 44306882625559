export interface Team {
  id?: string;
  name: string;
  members: Members;
  tokenMetadata: { [id: string]: TokenMetadata };
  secretsUsed?: Secrets;
  createdAt: Date;
  lastEditedBy: string;
  modifiedAt: Date;
  variables?: { [id: string]: TeamVariable };
}

export type TeamVariable = {
  id: string;
};

export type Members = { [key: string]: Member };

export type Secrets = { [key: string]: boolean };

export interface Member {
  role: TeamRole;
  exists: boolean;
  name?: string;
  joinedAt: Date;
  email: string;
}

export const TeamRoles = {
  VIEWER: "viewer",
  TESTER: "tester",
  EDITOR: "editor",
  ADMIN: "admin",
} as const;

export type TeamRole = (typeof TeamRoles)[keyof typeof TeamRoles];

export const roleHierarchy: Record<TeamRole, number> = {
  [TeamRoles.VIEWER]: 1,
  [TeamRoles.TESTER]: 2,
  [TeamRoles.EDITOR]: 3,
  [TeamRoles.ADMIN]: 4,
};
export interface TokenMetadata {
  createdAt: Date;
  createdBy: string;
  endsWith: string;
}
