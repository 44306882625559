import React, { useEffect, useRef, useState } from "react";
import {
  PaymentOption,
  SubscriptionOption,
} from "../../models/SubscriptionOption";
import { TeamSubscription } from "../../models/TeamSubscription";
import SubscriptionCard from "../Teams/SubscriptionCard";
import Icon from "../Icon";

interface TeamSettingsBillingUpgradeModalProps {
  isOpen: boolean;
  onCancel: () => void;
  subscriptions: SubscriptionOption[];
  currentSubscription: TeamSubscription;
  handleSelected: (
    subscription: SubscriptionOption,
    price: PaymentOption,
    enterprise: boolean
  ) => void;
  isRenewal: boolean;
}

export const TeamSettingsBillingUpgradeModal: React.FC<
  TeamSettingsBillingUpgradeModalProps
> = ({
  subscriptions,
  isOpen,
  onCancel,
  currentSubscription,
  handleSelected,
  isRenewal,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [yearly, setYearly] = useState(true);

  const currentOption = subscriptions.find(
    (s) => s.productId == currentSubscription.currentEvent?.productId
  );

  const filteredSubscriptions = subscriptions
    .sort((s1, s2) => s1.index - s2.index)
    .filter((s) => s.index >= (currentOption?.index ?? 0));

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onCancel();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
      <div
        ref={wrapperRef}
        className="bg-gray-50 rounded-lg w-[95%] max-h-[90vh] shadow-lg flex flex-col overflow-hidden"
      >
        <div className="p-6 flex flex-col gap-6">
          <div className="text-gray-900 text-2xl font-medium text-center font-gooper leading-none">
            {`${isRenewal ? "Renew" : "Upgrade"} your Easybeam subscription`}
          </div>
          <div className="h-5 justify-center items-center gap-2 inline-flex">
            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
              <div className="text-gray-900 text-sm font-medium font-gooper leading-none">
                Monthly
              </div>
            </div>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={yearly}
                onChange={() => setYearly(!yearly)}
                className="sr-only peer"
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
              <div className="text-gray-900 text-sm font-medium font-gooper leading-none">
                Yearly
              </div>
            </div>
            <div className="text-gray-500 text-xs font-light leading-none">
              Save 33%
            </div>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto items-center p-6">
          <div className="flex flex-wrap justify-center gap-2">
            {filteredSubscriptions.map((sub) => (
              <SubscriptionCard
                features={sub.upsellFeatureList.split(",")}
                key={sub.id}
                subscription={sub}
                yearly={yearly}
                selected={handleSelected}
                enterprise={sub.isHidden}
                highlightFirstFeature={false}
              />
            ))}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <button
            onClick={onCancel}
            className="flex font-gooper py-4 text-gray-400 font-medium flex-row items-center gap-2"
          >
            <Icon
              type="arrow-left"
              className="size-4 bg-gray-200 text-gray-0 rounded-full"
            />
            Go back
          </button>
        </div>
      </div>
    </div>
  );
};
