import React, { useState, useEffect } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useParams, useSearchParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import FailureModal from "../FailureModal";
import { usePortalService } from "../../contexts/PortalContext";
import { usePortalLogService } from "../../contexts/PortalLogContext";
import AnalyticsChart, { ChartType } from "./AnalyticsChart";
import { PortalLog } from "../../models/PortalLog";
import { Portal } from "../../models/Portal";
import { Review } from "../../models/Review";
import CheckFilter, { CheckFilterItem } from "../Notifications/CheckFilter";

export type Timespan = "7-Days" | "30-Days" | "60-Days";

export interface AnalyticsData {
  [versionId: string]: { logs: PortalLog[]; reviews: Review[] };
}

export const Analytics: React.FC<{}> = ({}) => {
  const { teamId } = useParams<{
    teamId: string;
  }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const portalId = searchParams.get("portal");
  const versionIds = searchParams.getAll("versions");
  const timespan = searchParams.get("time");

  const [error, setError] = useState("");
  const portalService = usePortalService();
  const portalLogService = usePortalLogService();

  const [portals, setPortals] = useState<Portal[]>([]);
  const [portalFilterItems, setPortalFilterItems] = useState<CheckFilterItem[]>(
    []
  );
  const [versionFilterItems, setVersionFilterItems] = useState<
    CheckFilterItem[]
  >([]);

  const timespans: Timespan[] = ["7-Days", "30-Days", "60-Days"];
  const [timespanFilterItems, setTiemspanFilterItems] = useState<
    CheckFilterItem[]
  >(
    timespans.map((t) => {
      return { id: t, name: t, selected: t === "7-Days" };
    })
  );

  const [analyticsData, setAnalyticsData] = useState<AnalyticsData>();
  const [loadingPortals, setLoadingPortals] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  const [durationType, setDurationType] = useState<ChartType>("line");
  const [scoreType, setScoreType] = useState<ChartType>("line");
  const [errorRateType, setErrorRateType] = useState<ChartType>("line");
  const [tokenType, setTokenType] = useState<ChartType>("line");

  // Fetch portals once when the component mounts
  useEffect(() => {
    const fetchPortals = async () => {
      try {
        const portals = await portalService.portalRepo.getList(
          portalService.portalPath(teamId!)
        );
        setPortals(portals);
        setPortalFilterItems(
          portals.map((p) => {
            return { name: p.name, id: p.id, selected: portalId == p.id };
          })
        );
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      } finally {
        setLoadingPortals(false);
      }
    };

    fetchPortals();
  }, [teamId, portalService]);

  // Fetch versions when portalId changes
  useEffect(() => {
    const fetchVersions = async () => {
      if (!portalId) return;

      try {
        const versions = await portalService.portalVersionRepo.getList(
          portalService.portalVersionPath(teamId!, portalId),
          { name: "modifiedAt", descending: true }
        );

        let selectedVersionIds = versionIds;

        if (versionIds.length < 1) {
          const portal = portals.filter((p) => p.id == portalId)[0];
          let defaultVersions = versions.filter(
            (v) => v.id != portal.currentVersionId
          );
          if (portal.currentVersionData) {
            defaultVersions = [portal.currentVersionData]
              .concat(defaultVersions)
              .slice(0, 3);
          }
          selectedVersionIds = defaultVersions.map((v) => v.id ?? "");

          setSearchParams({
            portal: portalId,
            versions: selectedVersionIds,
          });
        }

        setVersionFilterItems(
          versions.map((v) => {
            return {
              name: v.name,
              id: v.id ?? "",
              selected: selectedVersionIds.includes(v.id ?? ""),
            };
          })
        );
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      }
    };

    fetchVersions();
  }, [portalId, teamId, portalService]);

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      if (!portalId || versionIds.length === 0 || loadingData || analyticsData)
        return;

      setLoadingData(true);
      try {
        const daysAgo = Number(timespan?.split("-")[0] ?? "7");
        const monthAgo = new Date(Date.now() - daysAgo * 24 * 60 * 60 * 1000);

        const logPromises = versionIds.map((versionId) =>
          portalLogService.portalLogRepo.getList(
            portalLogService.portalLogPath(teamId!),
            { name: "createdAt", descending: true },
            [
              { key: "versionId", filter: "==", value: versionId },
              { key: "createdAt", filter: ">", value: monthAgo },
            ],
            undefined
          )
        );

        const reviewPromises = versionIds.map((versionId) =>
          portalService.reviewRepo.getList(
            portalService.reviewPath(teamId!, portalId!, versionId),
            { name: "createdAt", descending: true },
            [{ key: "createdAt", filter: ">", value: monthAgo }],
            undefined
          )
        );

        const logs = await Promise.all(logPromises);
        const reviews = await Promise.all(reviewPromises);

        const newAnalyticsData: AnalyticsData = versionIds.reduce(
          (acc, versionId, index) => {
            acc[versionId] = {
              logs: logs[index],
              reviews: reviews[index],
            };
            return acc;
          },
          {} as AnalyticsData
        );

        setAnalyticsData(newAnalyticsData);
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      } finally {
        setLoadingData(false);
      }
    };

    fetchAnalyticsData();
  }, [portalId, versionIds, teamId, portalService, portalLogService, timespan]);

  const updatePortalFilter = (item: CheckFilterItem) => {
    setSearchParams({ portal: item.id });
    portalFilterItems.forEach((i) => (i.selected = false));
    item.selected = !item.selected;
    setPortalFilterItems([...portalFilterItems]);
  };

  const updateVersionFilter = (item: CheckFilterItem) => {
    item.selected = !item.selected;
    const allSelected = versionFilterItems
      .filter((i) => i.selected)
      .map((i) => i.id);
    setVersionFilterItems([...versionFilterItems]);
    setSearchParams(() => ({
      portal: portalId!,
      versions: allSelected,
    }));
  };

  const updateTimespanFilter = (item: CheckFilterItem) => {
    timespanFilterItems.forEach((i) => (i.selected = false));
    item.selected = !item.selected;
    setTiemspanFilterItems([...timespanFilterItems]);
    setSearchParams(() => ({
      portal: portalId!,
      versions: versionIds,
      time: item.id,
    }));
    setAnalyticsData(undefined);
  };

  return (
    <CommonContainer>
      <CommonHeader
        title={`Analytics`}
        subtitle={`Insights & Optimizations`}
        sections={[{ name: "Analytics", link: `/teams/${teamId}/analytics` }]}
        teamId={teamId!}
        actions={[]}
      />
      <FailureModal
        shows={error != ""}
        message={error}
        closed={() => setError("")}
      />
      <div className="grid grid-cols-3 gap-4 pt-2">
        <CheckFilter
          typeName="Portal"
          typeNamePlural="All Portals"
          items={portalFilterItems}
          updatedItem={updatePortalFilter}
          leftIcon="portals"
        />
        <CheckFilter
          typeName="Version"
          typeNamePlural="All Versions"
          items={versionFilterItems}
          updatedItem={updateVersionFilter}
          leftIcon="portals"
        />
        <CheckFilter
          typeName="Date Range"
          typeNamePlural="Dates"
          items={timespanFilterItems}
          updatedItem={updateTimespanFilter}
          leftIcon="clock"
        />
      </div>

      {loadingPortals && <p>Loading portals...</p>}
      {!loadingPortals && !portalId && (
        <div className="font-gooper text-gray-500">
          Select a portal to get started
        </div>
      )}
      {!loadingPortals && portalId && (
        <div>
          {loadingData && <p>Loading analytics data...</p>}
          {!loadingData && analyticsData && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4">
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={versionIds}
                metric={"score"}
                timespan={(timespan as Timespan) ?? "7-Days"}
                type={scoreType}
                setType={setScoreType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={versionIds}
                metric={"duration"}
                timespan={(timespan as Timespan) ?? "7-Days"}
                type={durationType}
                setType={setDurationType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={versionIds}
                metric={"tokens"}
                timespan={(timespan as Timespan) ?? "7-Days"}
                type={tokenType}
                setType={setTokenType}
              />
              <AnalyticsChart
                analyticsData={analyticsData}
                versionIds={versionIds}
                metric={"errors"}
                timespan={(timespan as Timespan) ?? "7-Days"}
                type={errorRateType}
                setType={setErrorRateType}
              />
            </div>
          )}
        </div>
      )}
    </CommonContainer>
  );
};
