import { useState, useRef, useEffect } from "react";
import ClearableInput from "../Onboarding/ClearableInput";
import { TeamRole, TeamRoles } from "../../models/Team";
import Icon from "../Icon";

const MemberInput: React.FC<{
  selectedRole: TeamRole;
  email: string;
  setSelectedRole: (role: TeamRole) => void;
  setEmail: (role: string) => void;
  index: number;
}> = ({ selectedRole, setSelectedRole, email, setEmail, index }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState("");
  const roles: TeamRole[] = [
    TeamRoles.ADMIN,
    TeamRoles.EDITOR,
    TeamRoles.TESTER,
    TeamRoles.VIEWER,
  ];
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleSelectRole = (role: TeamRole) => {
    setSelectedRole(role);
    setShowDropdown(false);
  };

  const onUnfocus = () => {
    setShowDropdown(false);
    if (!email.match(/.+@.+/) && email) {
      setError("Please enter a valid email");
    } else {
      setError("");
    }
  };

  const setAndResetError = (text: string) => {
    setError("");
    setEmail(text);
  };

  return (
    <ClearableInput
      text={email}
      setText={setAndResetError}
      error={error}
      placeholder="Email goes here"
      onUnfocus={onUnfocus}
      className="bg-gray-0 border-gray-200"
      index={index}
    >
      <div
        ref={wrapperRef}
        className={`flex items-center absolute inset-y-0 right-1 ${
          email == "" ? "pr-2" : "pr-10"
        }`}
      >
        <button
          id={`memberRolesButton${index}`}
          onClick={() => setShowDropdown(!showDropdown)}
          className="bg-blue-25 text-xs gap0.5 font-gooper text-gray-700 rounded p-0.5 leading-none flex flex-row items-center justify-between"
        >
          {selectedRole.charAt(0).toUpperCase() +
            selectedRole.slice(1).toLowerCase()}
          <Icon type="chevron-up" className="size-5 rotate-180" />
        </button>
        {showDropdown && (
          <div className="absolute mt-28 items-star text-left font-gooper bg-gray-100 right-0 z-10 w-24 rounded-md shadow-lg border">
            {roles.map((role) => (
              <div
                id={`${role}Button${index}`}
                key={role}
                className={`${
                  role == selectedRole ? "text-blue-500" : "text-gray-700"
                } px-4 py-2 hover:bg-blue-25 cursor-pointer`}
                onClick={() => handleSelectRole(role)}
              >
                {role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()}
              </div>
            ))}
          </div>
        )}
      </div>
    </ClearableInput>
  );
};

export default MemberInput;
