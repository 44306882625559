import { Workflow } from "../../models/Workflow";
import Icon, { IconType } from "../Icon";

export const WorkflowStatusBadge: React.FC<{
  workflow: Workflow;
}> = ({ workflow }) => {
  let className = "";
  let text = "";
  let icon: IconType = "star";
  switch (workflow.status) {
    case "LIVE":
      className =
        "bg-green-50 text-green-600 border-0 stroke-green-600 fill-gray-0";
      text = "Live";
      icon = "portals";
      break;
    case "READY":
      className =
        "bg-orange-50 text-orange-500 border border-orange-500 stroke-orange-500 fill-gray-0";
      text = "Ready";
      icon = "ready";
      break;
    case "DRAFT":
      className =
        "bg-bink-50 text-pink-500 border fill-pink-500 border-pink-500 stroke-pink-500";
      text = "Draft";
      icon = "draft";
      break;
    case "ARCHIVED":
      className = "border border-gray-400 text-gray-400 fill-gray-400";
      text = "Archived";
      icon = "archived";
      break;
  }

  return (
    <div
      className={`self-stretch p-1 rounded border ${className} justify-center items-center gap-1 flex`}
    >
      <Icon type={icon} className="size-3" />
      <div className="text-center  text-xs font-medium leading-none">
        {text}
      </div>
    </div>
  );
};
