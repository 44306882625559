import { CustomFlowbiteTheme, Flowbite } from "flowbite-react";
import AuthFooter from "./AuthFooter";
import FailureModal from "../FailureModal";
import SuccessModal from "../SuccessModal";
import { CommonLoading } from "../Common/CommonLoading";

const AuthPage: React.FC<{
  children: React.ReactNode;
  error: string;
  modalBackTitle: string;
  setError: (error: string) => void;
  isLoading: boolean;
  successMessage: string;
  setSuccessMessage: (error: string) => void;
  successTitle?: string;
}> = ({
  children,
  error,
  modalBackTitle,
  isLoading,
  setError,
  successMessage,
  setSuccessMessage,
  successTitle,
}) => {
  const customTheme: CustomFlowbiteTheme = {
    button: {
      color: {
        primary: "bg-blue-500 hover:bg-blue-600 text-gray-50",
      },
    },
  };

  return (
    <Flowbite theme={{ theme: customTheme }}>
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={modalBackTitle}
        shows={error != ""}
        closed={() => setError("")}
      />
      <SuccessModal
        title={successTitle ?? "Success!"}
        message={successMessage}
        backButtonTitle={modalBackTitle}
        shows={successMessage != ""}
        closed={() => setSuccessMessage("")}
      />
      <div className="flex flex-col min-h-screen bg-auth-desktop bg-cover bg-center">
        {isLoading && <CommonLoading />}
        <img
          src="/images/easybeam.ai.png"
          alt="Easybeam logo"
          className="absolute z-0 left-1/2 top-0 transform -translate-x-1/2 pt-20 px-6"
          style={{ maxWidth: "100%", height: "auto" }}
        />
        <div className="z-10 flex flex-col items-center pb-6 flex-grow px-4 pt-36 space-y-8">
          {children}
        </div>
        <AuthFooter textColor="text-gray-400" textHoverColor="text-gray-600" />
      </div>
    </Flowbite>
  );
};

export default AuthPage;
