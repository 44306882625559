import React, { useMemo } from "react";
import {
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
  BarChart,
  Bar,
} from "recharts";
import { ChartData, Metric } from "./AnalyticsChart";

interface AnalyticsBarChartProps {
  chartData: ChartData[];
  versionIds: string[];
  metric: Metric;
  hexColors: string[];
  timespan: Timespan;
}

export type Timespan = "7-Days" | "30-Days" | "60-Days";

export const AnalyticsBarChart: React.FC<AnalyticsBarChartProps> = ({
  chartData,
  versionIds,
  metric,
  hexColors,
  timespan,
}) => {
  const groupedData = useMemo(
    () => groupChartData(chartData, timespan, versionIds, metric),
    [chartData, timespan, versionIds, metric]
  );

  const xAxisDomain = useMemo(() => {
    if (groupedData.length > 0) {
      const minDate = groupedData[0].date;
      const maxDate = groupedData[groupedData.length - 1].date;
      const padding = (maxDate - minDate) * 0.05; // 5% padding on each side
      return [minDate - padding, maxDate + padding];
    }
    return ["dataMin", "dataMax"];
  }, [groupedData]);

  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        data={groupedData}
        margin={{
          top: 20,
          right: 30,
          left: 60,
          bottom: 20,
        }}
        barSize={20}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          type="number"
          domain={xAxisDomain}
          tickFormatter={(timestamp) =>
            formatDate(new Date(timestamp), timespan)
          }
          tick={<CustomXAxisTick timespan={timespan} />}
          ticks={groupedData.map((item) => item.date)}
          interval="preserveStartEnd"
          padding={{ left: 10, right: 10 }}
        />
        <YAxis tick={<CustomYAxisTick />} width={50} />
        <Tooltip
          labelFormatter={(timestamp) =>
            formatDate(new Date(timestamp), timespan)
          }
          formatter={(value: number) =>
            metric === "tokens" ? Math.round(value) : value.toFixed(2)
          }
        />
        {versionIds.map((versionId, index) => (
          <Bar
            key={versionId}
            dataKey={versionId}
            fill={hexColors[index % hexColors.length]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomYAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-10}
        textAnchor="end"
        fill="#666"
        fontSize={14}
        fontFamily="Inter, sans-serif"
      >
        {payload.value}
      </text>
    </g>
  );
};

export const CustomXAxisTick = (props: any) => {
  const { x, y, payload, timespan } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        fontSize={14}
        fontFamily="Inter, sans-serif"
      >
        {formatDate(new Date(payload.value), timespan)}
      </text>
    </g>
  );
};

const formatDate = (date: Date, timespan: Timespan): string => {
  switch (timespan) {
    case "7-Days":
    case "30-Days":
      return date.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
      });
    case "60-Days":
      return date.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    default:
      return date.toLocaleDateString();
  }
};

interface InternalGroupedDataItem {
  date: number;
  [key: string]: number | { sum: number; count: number };
}

const groupChartData = (
  data: ChartData[],
  timespan: Timespan,
  versionIds: string[],
  metric: Metric
): ChartData[] => {
  const groupedData: { [key: string]: InternalGroupedDataItem } = {};

  // Determine the start and end dates based on the timespan
  const endDate = new Date(Math.max(...data.map((item) => item.date)));
  const startDate = new Date(endDate);
  switch (timespan) {
    case "7-Days":
      startDate.setDate(endDate.getDate() - 6);
      break;
    case "30-Days":
      startDate.setDate(endDate.getDate() - 29);
      break;
    case "60-Days":
      startDate.setDate(endDate.getDate() - 59);
      break;
  }

  // Filter data to only include points within the timespan
  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate >= startDate && itemDate <= endDate;
  });

  filteredData.forEach((item) => {
    const date = new Date(item.date);
    let groupKey: string;

    if (timespan === "60-Days") {
      // Group by week
      const weekStart = new Date(date);
      weekStart.setDate(date.getDate() - date.getDay());
      groupKey = weekStart.toISOString().split("T")[0];
    } else {
      // Group by day for 7-Days and 30-Days
      groupKey = date.toISOString().split("T")[0];
    }

    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {
        date: new Date(groupKey).getTime(),
        ...Object.fromEntries(
          versionIds.map((id) => [id, { sum: 0, count: 0 }])
        ),
      };
    }

    versionIds.forEach((versionId) => {
      if (typeof item[versionId] === "number") {
        const groupItem = groupedData[groupKey][versionId] as {
          sum: number;
          count: number;
        };
        groupItem.sum += item[versionId] as number;
        groupItem.count += 1;
      }
    });
  });

  return Object.values(groupedData)
    .map((item) => {
      const result: ChartData = { date: item.date };
      versionIds.forEach((versionId) => {
        const groupItem = item[versionId] as { sum: number; count: number };
        if (metric === "tokens") {
          result[versionId] = groupItem.sum;
        } else {
          result[versionId] =
            groupItem.count > 0 ? groupItem.sum / groupItem.count : 0;
        }
      });
      return result;
    })
    .sort((a, b) => a.date - b.date);
};
