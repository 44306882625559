import { Portal } from "../../models/Portal";
import { PortalVersion } from "../../models/PortalVersion";
import Icon, { IconType } from "../Icon";

export const StatusBadge: React.FC<{
  portal: Portal;
  version: PortalVersion;
}> = ({ portal, version }) => {
  const isLive = portal?.currentVersionId == version.id;

  let className = "";
  let text = "";
  let icon: IconType = "star";
  switch (version?.status) {
    case "READY":
      if (isLive) {
        className =
          "bg-green-50 text-green-600 border-0 stroke-green-600 fill-gray-0";
        text = "Live";
        icon = "portals";
      } else {
        className =
          "bg-orange-50 text-orange-500 border border-orange-500 stroke-orange-500 fill-gray-0";
        text = "Ready";
        icon = "ready";
      }
      break;
    case "DRAFT":
      className =
        "bg-bink-50 text-pink-500 border fill-pink-500 border-pink-500 stroke-pink-500";
      text = "Draft";
      icon = "draft";
      break;
    case "ARCHIVED":
      className = "border border-gray-400 text-gray-400 fill-gray-400";
      text = "Archived";
      icon = "archived";
      break;
  }

  return (
    <div
      className={`self-stretch p-1 rounded border ${className} justify-center items-center gap-1 flex`}
    >
      <Icon type={icon} className="size-3" />
      <div className="text-center  text-xs font-medium leading-none">
        {text}
      </div>
    </div>
  );
};
