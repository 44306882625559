import React from "react";
import { AnalyticsData, Timespan } from "./Analytics";
import { AnalyticsLineChart } from "./AnalyticsLineChart";
import { AnalyticsBarChart } from "./AnalyticsBarChart";

export type Metric = "duration" | "tokens" | "score" | "errors";

export interface ChartData {
  date: number;
  [key: string]: number | string | null;
}
export type ChartType = "bar" | "line";

interface AnalyticsChartProps {
  analyticsData: AnalyticsData;
  versionIds: string[];
  metric: Metric;
  timespan: Timespan;
  type: ChartType;
  setType: (type: ChartType) => void;
}

// Tailwind colors
const tailwindColors = [
  "#ff9800", // orange-500
  "#ff99ff", // eb-pink-300
  "#A5BE00", // lime-500
];

const AnalyticsChart: React.FC<AnalyticsChartProps> = ({
  analyticsData,
  versionIds,
  metric,
  timespan,
  type,
  setType,
}) => {
  const processChartData = (): ChartData[] => {
    const data: { [date: number]: { [versionId: string]: number[] } } = {};

    Object.entries(analyticsData ?? {}).forEach(([versionId, versionData]) => {
      if (metric == "duration" || metric == "tokens" || metric == "errors") {
        // based on logss
        versionData.logs.forEach((log) => {
          const date = new Date(log.createdAt)!;
          const hour = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours()
          ).getTime();
          if (!data[hour]) {
            data[hour] = {};
          }
          if (!data[hour][versionId]) {
            data[hour][versionId] = [];
          }
          if (metric === "duration" && log.duration) {
            data[hour][versionId].push(log.duration);
          } else if (metric === "tokens" && log.tokensUsed) {
            data[hour][versionId].push(log.tokensUsed);
          } else if (metric === "errors") {
            if (log.status == "ERROR") {
              data[hour][versionId].push(0);
            } else {
              data[hour][versionId].push(100);
            }
          }
        });
      } else {
        // based on reviews
        versionData.reviews.forEach((review) => {
          const date = new Date(review.createdAt)!;
          const hour = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours()
          ).getTime();
          if (!data[hour]) {
            data[hour] = {};
          }
          if (!data[hour][versionId]) {
            data[hour][versionId] = [];
          }
          if (metric === "score") {
            data[hour][versionId].push(review.score);
          }
        });
      }
    });

    // Get the full range of hours between the minimum and maximum dates
    const allHours = Object.keys(data)
      .map((date) => parseInt(date))
      .sort((a, b) => a - b);
    const minDate = new Date(Math.min(...allHours));
    const maxDate = new Date(Math.max(...allHours));

    const completeData: ChartData[] = [];
    for (
      let date = new Date(minDate);
      date <= maxDate;
      date.setHours(date.getHours() + 1)
    ) {
      const hour = date.getTime();
      const entry: ChartData = { date: hour };
      versionIds.forEach((versionId) => {
        entry[versionId] = data[hour]?.[versionId]
          ? data[hour][versionId].reduce((sum, value) => sum + value, 0) /
            data[hour][versionId].length
          : null;
      });
      completeData.push(entry);
    }

    return completeData;
  };
  const chartData = processChartData();
  const calculate7DayAverage = (): string => {
    const dateMin = new Date();
    const daysAgo = (): number => {
      switch (timespan) {
        case "7-Days":
          return 7;
        case "30-Days":
          return 30;
        case "60-Days":
          return 60;
      }
    };
    dateMin.setDate(dateMin.getDate() - daysAgo());

    if (metric == "duration") {
      analyticsData;
    }

    const last7DaysData = chartData.filter((d) => new Date(d.date) >= dateMin);

    if (last7DaysData.length === 0) {
      if (metric == "errors") {
        return "N/A";
      }
      return "N/A";
    }

    let totalSum = 0;
    let totalCount = 0;

    last7DaysData.forEach((dayData) => {
      versionIds.forEach((id) => {
        const value = dayData[id];
        if (typeof value === "number" && !isNaN(value)) {
          totalSum += value;
          totalCount++;
        }
      });
    });

    if (totalCount === 0) {
      if (metric == "errors") {
        return "100%";
      }
      return "N/A";
    }

    const average = totalSum / totalCount;

    // Format the average based on the metric type
    if (metric === "duration") {
      return `${average.toFixed(0)}ms`;
    } else if (metric === "tokens") {
      return `${totalSum.toFixed(0)}`;
    } else if (metric == "errors") {
      return `${average.toFixed(0)}%`;
    } else {
      // score
      return average.toFixed(2);
    }
  };

  const calculatePretitle = (): string => {
    if (metric === "duration") {
      return `7-day average`;
    } else if (metric === "tokens") {
      return `7-day sum`;
    } else {
      return `7-day average`;
    }
  };

  const calculateTitle = (): string => {
    if (metric === "duration") {
      return `Duration`;
    } else if (metric === "tokens") {
      return `Tokens`;
    } else if (metric === "errors") {
      return `Error Free`;
    } else {
      return `Score`;
    }
  };

  const average = calculate7DayAverage();
  const pretitle = calculatePretitle();

  return (
    <div className="flex flex-col bg-gray-0 rounded-lg py-4 px-6 border border-gray-200 gap-4">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-1">
          <div className="text-sm text-gray-500">{pretitle}</div>
          <div className="text-3xl font-gooper text-gray-800">
            {calculateTitle()}
          </div>
        </div>
        <div className="flex items-center">
          <div
            className={`text-transparent bg-clip-text bg-gradient-to-br from-green-500 to-blue-500 text-4xl font-medium font-gooper`}
          >
            {average}
          </div>
        </div>
      </div>
      {type == "bar" ? (
        <AnalyticsBarChart
          chartData={chartData}
          hexColors={tailwindColors}
          metric={metric}
          versionIds={versionIds}
          timespan={timespan}
        />
      ) : (
        <AnalyticsLineChart
          chartData={chartData}
          hexColors={tailwindColors}
          metric={metric}
          versionIds={versionIds}
        />
      )}
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row gap-2">
          {versionIds.map((v, index) => {
            return (
              <div key={index} className="flex flex-row items-center gap-1">
                <div
                  style={{
                    backgroundColor:
                      tailwindColors[index % tailwindColors.length],
                  }}
                  className="rounded-full size-2"
                />

                <div className="text-sm text-gray-600">{`Version ${v}`}</div>
              </div>
            );
          })}
        </div>
        <select
          value={type}
          onChange={(e) => setType(e.target.value as ChartType)}
          className="bg-gray-100 border-none text-gray-500 py-1 px-2 rounded leading-tight"
        >
          <option value="bar">Bar Chart</option>
          <option value="line">Line Chart</option>
        </select>
      </div>
    </div>
  );
};

export default AnalyticsChart;
