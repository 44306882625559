import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import OnboardingPage from "./OnboardingPage";
import { OnboardingStep } from "./UserOnboarding";
import { useUserEvent } from "../../contexts/UserEventContext";

const UserCheckInvite: React.FC<{
  setStep: (step: OnboardingStep) => void;
}> = ({ setStep }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const authService = useAuth();
  const events = useUserEvent();

  const handleKeyUp = (event: React.KeyboardEvent, codeNumber?: number) => {
    if (event.key === "Backspace" && codeNumber) {
      (
        document.querySelector(`#code-${codeNumber - 1}`) as HTMLInputElement
      )?.focus();
    } else if (codeNumber) {
      if (codeNumber == 6) {
        checkFullCode();
      } else {
        (
          document.querySelector(`#code-${codeNumber + 1}`) as HTMLInputElement
        )?.focus();
      }
    }
  };

  const checkFullCode = async () => {
    const code1 = (document.getElementById("code-1") as HTMLInputElement).value;
    const code2 = (document.getElementById("code-2") as HTMLInputElement).value;
    const code3 = (document.getElementById("code-3") as HTMLInputElement).value;
    const code4 = (document.getElementById("code-4") as HTMLInputElement).value;
    const code5 = (document.getElementById("code-5") as HTMLInputElement).value;
    const code6 = (document.getElementById("code-6") as HTMLInputElement).value;

    const fullCode = code1 + code2 + code3 + code4 + code5 + code6;

    if (fullCode.length === 6) {
      setError("");

      if (!fullCode.trim()) {
        setError("Please enter a valid invite code");
        return;
      }
      setLoading(true);

      try {
        await authService.checkCode(fullCode);
        await events.createEvent(authService, "OnboardingStart");
        setStep(OnboardingStep.AddName);
      } catch (err) {
        if (err instanceof Error) {
          setError("Invalid code");
        } else {
          setError("An unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <OnboardingPage
      error={error}
      title={"Invitation code"}
      headline="Paste your code"
      message="We're in early access, for now..."
      isLoading={loading}
      progress={0}
      setError={setError}
    >
      <div className="flex my-4 space-x-2 sm:space-x-4 md:my-6">
        <div>
          <input
            id="code-1"
            maxLength={1}
            onKeyUp={(event) => handleKeyUp(event, 1)}
            required
            type="text"
            className="font-gooper block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          />
        </div>
        <div>
          <label htmlFor="code-2" className="sr-only">
            Second code
          </label>
          <input
            id="code-2"
            maxLength={1}
            onKeyUp={(event) => handleKeyUp(event, 2)}
            type="text"
            required
            className="font-gooper block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          />
        </div>
        <div>
          <label htmlFor="code-3" className="sr-only">
            Third code
          </label>
          <input
            type="text"
            maxLength={1}
            id="code-3"
            onKeyUp={(event) => handleKeyUp(event, 3)}
            className="font-gooper block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            required
          />
        </div>
        <div>
          <label htmlFor="code-4" className="sr-only">
            Fourth code
          </label>
          <input
            id="code-4"
            maxLength={1}
            onKeyUp={(event) => handleKeyUp(event, 4)}
            required
            type="text"
            className="font-gooper block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          />
        </div>
        <div>
          <label htmlFor="code-5" className="sr-only">
            Fifth code
          </label>
          <input
            id="code-5"
            maxLength={1}
            onKeyUp={(event) => handleKeyUp(event, 5)}
            required
            type="text"
            className="font-gooper block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          />
        </div>
        <div>
          <label htmlFor="code-6" className="sr-only">
            Sixth code
          </label>
          <input
            type="text"
            maxLength={1}
            id="code-6"
            onKeyUp={(event) => handleKeyUp(event, 6)}
            className="font-gooper block w-12 h-12 py-3 text-2xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg sm:py-4 sm:text-4xl sm:w-16 sm:h-16 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            required
          />
        </div>
      </div>
    </OnboardingPage>
  );
};

export default UserCheckInvite;
