import React from "react";
import { Spinner } from "flowbite-react";

interface CommonLoadingProps {}

export const CommonLoading: React.FC<CommonLoadingProps> = ({}) => {
  return (
    <div
      className="fixed inset-0 z-30 flex justify-center items-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <CommonSpinner />
    </div>
  );
};

export const CommonSpinner: React.FC<{ size?: string }> = ({ size }) => {
  return <Spinner className="fill-pink-600" size={size ?? "xl"} light={true} />;
};
