import React from "react";
import { ChangeLogService } from "../services/ChangeLogService";

const ChangeLogContext = React.createContext<ChangeLogService | undefined>(
  undefined
);

export const useChangeLogService = (): ChangeLogService => {
  const context = React.useContext(ChangeLogContext);
  if (!context) {
    throw new Error(
      "useChangeLogService must be used within a ChangeLogServiceProvider"
    );
  }
  return context;
};

export default ChangeLogContext;
