import React from "react";
import { WorkflowService } from "../services/WorkflowService";
import WorkflowServiceContext from "../contexts/WorkflowContext";

type Props = {
  children: React.ReactNode;
  workflowService: WorkflowService;
};

export const WorkflowServiceProvider: React.FC<Props> = ({
  children,
  workflowService,
}) => {
  return (
    <WorkflowServiceContext.Provider value={workflowService}>
      {children}
    </WorkflowServiceContext.Provider>
  );
};

export default WorkflowServiceProvider;
