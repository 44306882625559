import React, { useEffect, useState } from "react";
import GetStartedCard from "./GetStartedCard";
import { usePortalService } from "../../contexts/PortalContext";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS } from "../../utils/StorageKeys";

interface GetStartedPortalsProps {
  teamId: string;
}

const GetStartedPortals: React.FC<GetStartedPortalsProps> = ({ teamId }) => {
  const portalService = usePortalService();
  const navigate = useNavigate();

  const [completed, setCompleted] = useState(
    localStorage.getItem(STORAGE_KEYS.COMPLETED_PORTALS) == "true"
  );

  const [loading, setLoading] = useState(
    localStorage.getItem(STORAGE_KEYS.COMPLETED_PORTALS) != "true"
  );

  useEffect(() => {
    if (localStorage.getItem(STORAGE_KEYS.COMPLETED_PORTALS) == "true") {
      return;
    }
    const loadData = async () => {
      const portals = await portalService.portalRepo.getList(
        portalService.portalPath(teamId),
        undefined,
        undefined,
        1
      );
      const completed = portals.length > 0;
      setCompleted(completed);
      if (completed) {
        localStorage.setItem(STORAGE_KEYS.COMPLETED_PORTALS, "true");
      }
      setLoading(false);
    };
    loadData();
  }, [portalService]);

  const handlePortalAction = async () => {
    setLoading(true);
    const result = await portalService.createPortal(teamId);
    setCompleted(true);
    navigate(`../portals/${result.portal.id}/version/${result.version.id}`);
  };

  return (
    <GetStartedCard
      sectionTitle="Portals"
      actionTitle="Create portal"
      title="Create Your First Portal"
      subtitle="Use the test environment to see how it all work"
      number="1"
      iconType="plus"
      completed={completed}
      completedMessage="Great job!"
      action={() => handlePortalAction()}
      loading={loading}
    />
  );
};

export default GetStartedPortals;
