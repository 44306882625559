import React from "react";
import { WorkflowDemoStartResult } from "../../../../../models/WorkflowDemoResult";
import Icon from "../../../../Icon";
import { formatDate, formatTime } from "../../../../../utils/DateUtils";
import { MessagesCard } from "./MessagesCard";

interface StartResultModalProps {
  result: WorkflowDemoStartResult;
  shows: boolean;
  onClose: () => void;
}

export const StartResultModal: React.FC<StartResultModalProps> = ({
  result,
  onClose,
  shows,
}) => {
  const statusColor = (): string => {
    switch (result.status) {
      case "COMPLETED":
        return "bg-green-50 text-green-400";
      case "ERROR":
        return "bg-yellow-50 text-yellow-400";
      case "IN_PROGRESS":
        return "bg-red-50 text-red-400";
      default:
        return "";
    }
  };

  const statusTitle = (): string => {
    switch (result.status) {
      case "COMPLETED":
        return "success";
      case "ERROR":
        return "error";
      case "IN_PROGRESS":
        return "security";
      default:
        return "";
    }
  };

  if (!shows) {
    return null;
  }
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-scroll"
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <div className="bg-white rounded-lg flex flex-col shadow-lg w-[544px] relative overflow-y-auto">
        <div className="flex justify-end">
          <button
            className="text-gray-200 hover:text-gray-300 pt-8 pr-6"
            onClick={onClose}
          >
            <Icon type="x" className="size-5" />
          </button>
        </div>
        <div className="flex flex-col w-full overflow-y-auto p-6 gap-6">
          <div className="flex justify-between items-center">
            <div className="flex justify-between text-xs text-gray-500 gap-2">
              <span> Created</span>
              <span>{formatDate(result.createdAt)}</span>
              <span>{formatTime(result.createdAt)}</span>
            </div>
            <div
              className={`text-xs font-medium px-2 py-1 rounded ${statusColor()}`}
            >
              {statusTitle()}
            </div>
          </div>

          <MessagesCard messages={result.messages} />

          <div className="flex flex-col gap-1 justify-center items-center">
            <div className="text-gray-500 text-xs ">{`Start Result`}</div>
            <span className="text-gray-900 text-2xl font-gooper">
              {result.name}
            </span>
          </div>
          {Object.keys(result.parameters).map((key) => {
            return (
              <VariableBlock
                keyValue={key}
                key={key}
                value={result.parameters[key]}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const VariableBlock: React.FC<{
  keyValue: string;
  value: string;
}> = ({ keyValue, value }) => {
  return (
    <div className="border border-gray-200 w-full bg-gray-50 rounded-lg flex-col inline-flex">
      <div className="font-gooper text-gray-700 text-sm w-full border-b border-gray-200 px-5 py-2">{`${keyValue}`}</div>
      <div className="text-gray-700 text-base leading-normal p-2">{`${value}`}</div>
    </div>
  );
};
