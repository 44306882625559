import React from "react";
import { Connection, Handle, Position } from "reactflow";
import Icon from "../../../Icon";

export interface DecisionHandlerParams {
  type: "source" | "target";
  isSuccess: boolean;
  isConnected: boolean;
  id: string;
  position: "left" | "right";
  onClick?: (handlerId: string) => void;
}

export const DecisionHandler: React.FC<DecisionHandlerParams> = ({
  type,
  isSuccess,
  isConnected,
  id,
  position,
  onClick,
}) => {
  const isValidConnection = (connection: Connection) => {
    if (connection.source === connection.target) {
      return false; // Prevent self-connections
    }
    return !isConnected;
  };

  return (
    <Handle
      onClick={() => {
        if (isConnected) {
          return;
        }
        onClick?.(id);
      }}
      type={type}
      position={Position.Bottom}
      id={id}
      isValidConnection={isValidConnection}
      style={{
        background: "transparent",
        zIndex: 999,
        border: "none",
        width: "24px",
        height: "24px",
        bottom: "-12px",
        left: position === "left" ? "25%" : "75%",
        transform: "translateX(-50%)",
      }}
    >
      {/* {!isConnected && ( */}
      <Icon
        type={isSuccess ? "check-line" : "x-no-circle"}
        className={`size-5 p-0.5 opacity-0 group-hover:opacity-100 pointer-events-none rounded-full text-gray-0 ${
          isSuccess ? "bg-green-500" : "bg-red-500"
        }`}
      />
      {/* )} */}
    </Handle>
  );
};
