// Libraries and Frameworks
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";

// Firebase Initialization
import "./Firebase";

// Styles
import "./index.css";

// Sentry Initialization
Sentry.init({
  sampleRate: import.meta.env.VITE_REACT_APP_MODE == "local" ? 0 : 1,
  dsn: import.meta.env.VITE_SENTRY_DSN as string,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  environment: import.meta.env.VITE_REACT_APP_MODE as string,
});

// Components and Services
import App from "./App.tsx";
import { FirebaseAuthService } from "./services/AuthService.ts";
import AuthProvider from "./providers/AuthProvider.tsx";
import { FirestoreTeamService } from "./services/TeamService.ts";
import TeamProvider from "./providers/TeamProvider.tsx";
import { FirestorePortalService } from "./services/PortalService.ts";
import { PortalServiceProvider } from "./providers/PortalProvider.tsx";
import { FirestoreTestService } from "./services/TestService.ts";
import { TestServiceProvider } from "./providers/TestProvider.tsx";
import PortalLogServiceProvider from "./providers/PortalLogProvider.tsx";
import { FirestorePortalLogService } from "./services/PortalLogService.ts";
import { FirestoreChangeLogService } from "./services/ChangeLogService.ts";
import ChangeLogServiceProvider from "./providers/ChangeLogProvider.tsx";
import { FirestoreWorkflowService } from "./services/WorkflowService.ts";
import WorkflowServiceProvider from "./providers/WorkflowProvider.tsx";
import { ReactFlowWorkflowConverter } from "./services/WorkflowConverter.ts";
import WorkflowConverterProvider from "./providers/WorkflowConverterProvider.tsx";
import { FirestoreUserEventService } from "./services/UserEventService.ts";
import UserEventProvider from "./providers/UserEventProvider.tsx";

import { PostHogProvider } from "posthog-js/react";
import { FirestoreRepository } from "./repos/FirestoreRepository.ts";
import { initializeFirestore } from "firebase/firestore";
import firebaseApp from "./Firebase";
import { NetworkHelper } from "./utils/NetworkHelper.ts";
import { FirebaseFunctionRepository } from "./repos/FirebaseFunctionRepository.ts";
const postHogKey = import.meta.env.VITE_POSTHOG_KEY as string;
const options = {
  api_host: "https://us.posthog.com",
};

const firestore = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
});

const portalService = new FirestorePortalService(
  new FirestoreRepository(firestore),
  new FirestoreRepository(firestore),
  new FirestoreRepository(firestore),
  new FirestoreRepository(firestore),
  new NetworkHelper(),
  new FirebaseFunctionRepository()
);

const workflowService = new FirestoreWorkflowService(
  new FirestoreRepository(firestore),
  new FirestoreRepository(firestore),
  new FirebaseFunctionRepository(),
  new NetworkHelper()
);

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <UserEventProvider service={new FirestoreUserEventService()}>
      <WorkflowConverterProvider
        workflowConverter={new ReactFlowWorkflowConverter()}
      >
        <WorkflowServiceProvider workflowService={workflowService}>
          <ChangeLogServiceProvider
            changeLogService={new FirestoreChangeLogService()}
          >
            <PortalLogServiceProvider
              portalLogService={new FirestorePortalLogService()}
            >
              <TestServiceProvider testService={new FirestoreTestService()}>
                <PortalServiceProvider portalService={portalService}>
                  <AuthProvider service={new FirebaseAuthService()}>
                    <TeamProvider service={new FirestoreTeamService()}>
                      <PostHogProvider apiKey={postHogKey} options={options}>
                        <App />
                      </PostHogProvider>
                    </TeamProvider>
                  </AuthProvider>
                </PortalServiceProvider>
              </TestServiceProvider>
            </PortalLogServiceProvider>
          </ChangeLogServiceProvider>
        </WorkflowServiceProvider>
      </WorkflowConverterProvider>
    </UserEventProvider>
  );
}
