import React from "react";
import { TeamAlert } from "../../models/TeamAlert";
import Icon from "../Icon";

interface NotificationRowParams {
  alert: TeamAlert;
}

const NotificationRow: React.FC<NotificationRowParams> = ({ alert }) => {
  return (
    <div
      key={alert.id}
      className="w-full items-center bg-gray-0 justify-center flex flex-col shadow-sm rounded-md"
    >
      <div className="w-full px-4 py-4 flex gap-2 items-center flex-row">
        <div className="flex bg-gray-100 rounded-full w-11 h-11 items-center justify-center">
          <Icon type="bell" className="text-gray-400" />
        </div>

        <div className="w-full flex gap-1 p-0 flex-col">
          <div className="text-gray-500 text-base font-normal">
            {alert.message}
          </div>
          <div className="w-full items-center flex gap-2 flex-row">
            <Icon type="clock" className="text-gray-200 size-4" />
            <div className="text-gray-300 text-xs ">
              {new Date(alert.createdAt)
                .toLocaleString("en-GB", {
                  day: "2-digit",
                  month: "long",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })
                .replace(",", " at")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationRow;
