import { getFirestore } from "firebase/firestore";
import firebaseApp from "../Firebase";
import { Repository } from "../repos/Repository";
import { FirestoreRepository } from "../repos/FirestoreRepository";
import { PortalLog } from "../models/PortalLog";

export interface PortalLogService {
  portalLogRepo: Repository<PortalLog>;
  portalLogPath(teamId: string): string;
}

export class FirestorePortalLogService implements PortalLogService {
  portalLogRepo = new FirestoreRepository<PortalLog>(getFirestore(firebaseApp));
  portalLogPath(teamId: string): string {
    return `teams/${teamId}/logs`;
  }
}
