import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useTeams } from "../../contexts/TeamContext";
import OnboardingPage from "./OnboardingPage";
import ClearableInput from "./ClearableInput";
import { OnboardingStep } from "./UserOnboarding";
import { NextButton } from "./NextButton";

const UserAddName: React.FC<{
  progress: number;
  setStep: (step: OnboardingStep) => void;
}> = ({ progress, setStep }) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const authService = useAuth();
  const teamService = useTeams();

  const handleSubmit = async () => {
    if (name.length < 2) {
      setNameError("Please add a public name");
    }

    setIsLoading(true);

    try {
      const currentUser = await authService.currentUser();
      if (!currentUser) return;
      await authService.userRepo.update(
        { name },
        authService.userPath(),
        currentUser.id
      );
      await teamService.updateTeamMembershipInfo({ ...currentUser, name });
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setIsLoading(false);
    }
    setStep(OnboardingStep.Role);
  };

  const onUnfocus = () => {
    if (name.length < 2) {
      setNameError("Please add a public name");
    }
  };

  const setAndResetError = (text: string) => {
    setNameError("");
    setName(text);
  };

  return (
    <OnboardingPage
      error={error}
      title={"Let’s get you setup"}
      headline="What’s your name?"
      message="This is what your teammates will see"
      isLoading={isLoading}
      progress={progress}
      setError={() => undefined}
    >
      <div className="w-96 items-center">
        <ClearableInput
          text={name}
          setText={setAndResetError}
          error={nameError}
          placeholder="Your public name"
          onUnfocus={onUnfocus}
        />
      </div>

      <NextButton onClick={handleSubmit} />
    </OnboardingPage>
  );
};

export default UserAddName;
