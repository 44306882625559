import React from "react";
import { TestService } from "../services/TestService";
import TestServiceContext from "../contexts/TestContext";

type Props = {
  children: React.ReactNode;
  testService: TestService;
};

export const TestServiceProvider: React.FC<Props> = ({
  children,
  testService,
}) => {
  return (
    <TestServiceContext.Provider value={testService}>
      {children}
    </TestServiceContext.Provider>
  );
};

export default TestServiceProvider;
