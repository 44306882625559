import React from "react";
import { WorkflowService } from "../services/WorkflowService";

const WorkflowContext = React.createContext<WorkflowService | undefined>(
  undefined
);

export const useWorkflowService = (): WorkflowService => {
  const context = React.useContext(WorkflowContext);
  if (!context) {
    throw new Error(
      "useWorkflowService must be used within a WorkflowServiceProvider"
    );
  }
  return context;
};

export default WorkflowContext;
