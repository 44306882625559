import { useState } from "react";
import { ChatConfigurationArrayInput } from "../../../../models/ChatConfigurationTemplate";
import Icon from "../../../Icon";
import { Tooltip } from "./Tooltip";

export const ArrayInput: React.FC<{
  input: ChatConfigurationArrayInput;
  values: string[];
  update: (values: string[]) => void;
}> = ({ input, values, update }) => {
  const [newValue, setNewValue] = useState("");
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      update([...values, newValue]);
      setNewValue("");
    }
  };
  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row gap-1 items-center">
        <div className={`text-sm font-gooper text-gray-700`}>{input.name}</div>
        <Tooltip title={input.name} description={input.description}>
          <svg
            data-tooltip-target="tooltip-default"
            className="w-3 h-3 fill-current text-gray-300"
            viewBox="0 0 24 24"
          >
            <Icon type={"info"} />
          </svg>
        </Tooltip>
      </div>{" "}
      <div className="flex flex-row justify-between">
        {<div className="text-gray-500 text-xs">{input.shortDescription}</div>}
        {<div className="text-gray-400 text-xs">{`press enter to add`}</div>}
      </div>
      <input
        className="bg-gray-50 border border-gray-200 text-sm rounded-lg"
        type="text"
        value={newValue}
        onChange={(v) => setNewValue(v.target.value)}
        onKeyDown={handleKeyDown}
      />
      <div className="flex flex-wrap -mb-4 gap-2">
        {values.map((value, index) => {
          return (
            <div
              key={index}
              className={`flex-row justify-between p-1 rounded-md items-center gap-1 flex bg-gray-200`}
            >
              <div
                className={`text-center text-xs  font-medium leading-none text-gray-600`}
              >
                {value}
              </div>
              <button onClick={() => update(values.filter((v) => v != value))}>
                <svg
                  className="w-4 h-4 fill-gray-600 stroke-gray-600"
                  viewBox="0 0 24 24"
                >
                  <Icon type={"close"} />
                </svg>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
