import { Status } from "../../models/TestRun";
import Icon, { IconType } from "../Icon";

export const StatusBadge: React.FC<{
  status: Status;
}> = ({ status }) => {
  const statusTitle = () => {
    if (status == "COMPLETED") {
      return "Success";
    } else if (status == "ERROR") {
      return "Error";
    } else if (status == "IN_PROGRESS") {
      return "In Progress";
    }
  };

  const className = () => {
    if (status == "COMPLETED") {
      return "bg-green-50 text-green-500";
    } else if (status == "ERROR") {
      return "bg-red-50 text-red-500";
    } else if (status == "IN_PROGRESS") {
      return "bg-yellow-50 text-yellow-500";
    }
  };

  const icon = (): IconType => {
    if (status == "COMPLETED") {
      return "check";
    } else if (status == "ERROR") {
      return "x";
    } else {
      return "sun";
    }
  };
  return (
    <div
      className={`px-2.5 py-1 rounded-md justify-center items-center gap-1 flex ${className()}`}
    >
      <Icon type={icon()} className="size-4" />
      <div className={`text-center text-xs font-medium leading-none`}>
        {statusTitle()}
      </div>
    </div>
  );
};
