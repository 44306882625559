import React from "react";
import Breadcrumb, { BreadcrumbSection } from "../Navigation/Breadcrumb";

interface CommonHeaderProps {
  sections: BreadcrumbSection[];
  title: string;
  subtitle: string;
  teamId: string;
  actions: React.ReactNode[];
}

const CommonHeader: React.FC<CommonHeaderProps> = ({
  sections,
  title,
  subtitle,
  teamId,
  actions,
}) => {
  return (
    <div className="flex flex-col md:flex-row w-full">
      <div className="flex flex-col w-full">
        <Breadcrumb sections={sections} teamId={teamId} />
        <div
          className="pt-3 text-gray-800 text-lg md:text-3xl font-medium font-gooper"
          id={"headerTitle"}
        >
          {title}
        </div>
        <div className="pt-2 text-gray-500 text-sm md:text-lg">{subtitle}</div>
      </div>
      <div className="flex pt-4 gap-2 w-56 flex-col">{actions}</div>
    </div>
  );
};

export default CommonHeader;
