import { WorkflowDemoPortalResult } from "../../../../../models/WorkflowDemoResult";
import { StatusBadge } from "../../../../TestCenter/StatusBadge";
import { PortalBadge } from "../../../Map/Nodes/PortalNode";
import { LogModal } from "../../../../PortalLogs/LogModal";
import { useState } from "react";
import { PortalLog } from "../../../../../models/PortalLog";

export const PortalResultCard: React.FC<{
  result: WorkflowDemoPortalResult;
}> = ({ result }) => {
  const [portalLog, setPortalLog] = useState<PortalLog>();

  return (
    <div
      onClick={() => setPortalLog(result.log)}
      key={result.id}
      className="h-24 shadow-sm cursor-pointer hover:shadow-md flex flex-col rounded-lg bg-gray-50 p-2 gap-2 border border-gray-200 transition-all"
    >
      <div className="flex flex-row justify-between">
        <PortalBadge />
        <StatusBadge status={result.status} />
      </div>
      <div className="text-2xl font-gooper text-gray-900">{result.name}</div>
      <LogModal portalLog={portalLog} onClose={() => setPortalLog(undefined)} />
    </div>
  );
};
