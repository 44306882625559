import { useReactFlow } from "reactflow";
import { WorkflowSwitchOperator } from "../../../../models/Workflow";
import { AddNodeHandler } from "../Handlers/AddNodeHandler";
import Icon from "../../../Icon";
import { SelectedInput } from "../../Sidebar/VariableInputSelector";
import { DecisionHandler } from "../Handlers/DecisionHandler";
import { createSelectorNode } from "./SelectorNode";

export const DecisionNodeType = "decisionNode";

export interface DecisionNodeData {
  type: typeof DecisionNodeType;
  id: string;
  title: string;
  teamId: string;

  selectedInput?: SelectedInput | undefined;
  selectedOperator?: WorkflowSwitchOperator;
  selectedOperand?: SelectedInput | undefined;
  hasProblem?: Boolean;
}

export interface DecisionNodeProps {
  data: DecisionNodeData;
}

export const trueNodeHandlerId = (nodeId: string): string => {
  return `${nodeId}_success`;
};

export const falseNodeHandlerId = (nodeId: string): string => {
  return `${nodeId}_false`;
};

export const DecisionNode: React.FC<DecisionNodeProps> = ({ data }) => {
  const { getNode, getEdges, setNodes } = useReactFlow();

  const targetConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.target === data.id);
  };

  const selected = getNode(data.id)?.selected;
  const nodeClasses = `shadow group box-border border-2 ${
    data.hasProblem
      ? "border-red-500"
      : selected
      ? "border-blue-500"
      : "border-transparent hover:border-blue-500 transition-all"
  } flex flex-col gap-1 rounded-lg p-3 bg-gray-0 w-56`;

  const handleAdd = (handleId: string) => {
    const currentNode = getNode(data.id);
    if (!currentNode) return;

    const selectionNode = createSelectorNode(
      currentNode,
      data.teamId,
      undefined,
      undefined,
      handleId
    );

    setNodes((nds) => nds.concat(selectionNode));
  };

  const isConnected = (handlerId: string) => {
    const edges = getEdges();
    return edges.some((edge) => edge.sourceHandle === handlerId);
  };

  return (
    <div className={nodeClasses}>
      <AddNodeHandler
        type="target"
        isConnected={targetConnected()}
        id={`${data.id}_target`}
      />
      <div className="h-6 justify-start items-start inline-flex">
        <DecisionBadge />
      </div>
      <div className="text-2xl font-gooper text-gray-900 h-12 truncate">
        {data.title}
      </div>
      <div className="grid grid-cols-3">
        <div className="text-sm text-gray-500 truncate">
          {`${data.selectedInput?.type ?? ""}`}
        </div>
        <div className="text-sm text-gray-500 truncate text-center">
          {` ${data.selectedOperator ?? ""}`}
        </div>
        <div className="text-sm text-gray-500 truncate text-right">
          {data.selectedOperand?.type ?? ""}
        </div>
      </div>

      <DecisionHandler
        type="source"
        isSuccess={true}
        isConnected={isConnected(trueNodeHandlerId(data.id))}
        id={trueNodeHandlerId(data.id)}
        position="left"
        onClick={handleAdd}
      />
      <DecisionHandler
        type="source"
        isSuccess={false}
        isConnected={isConnected(falseNodeHandlerId(data.id))}
        id={falseNodeHandlerId(data.id)}
        position="right"
        onClick={handleAdd}
      />
    </div>
  );
};
export const DecisionBadge: React.FC = () => {
  return (
    <div className="px-2.5 py-0.5 bg-orange-50 rounded justify-center items-center gap-1 flex">
      <Icon type="api" className="size-3 text-orange-400" />
      <div className="text-center text-orange-400 text-xs font-medium leading-[18px]">
        Decision
      </div>
    </div>
  );
};
