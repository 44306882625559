import { Workflow } from "../../../../models/Workflow";
import { Team } from "../../../../models/Team";
import { SidebarWorkflowMetaDetail } from "./SidebarWorkflowMetaDetail";
import { StartNodeData } from "../../Map/Nodes/StartNode";

export const SidebarWorkflowMetaContainer: React.FC<{
  workflow: Workflow;
  setWorkflow: (workflow: Workflow) => void;
  setTeam: (team: Team) => void;
  team: Team;
  data: StartNodeData;
}> = ({ workflow, team, setWorkflow, setTeam, data }) => {
  return (
    <div className="flex flex-col gap-2">
      <SidebarWorkflowMetaDetail
        team={team}
        workflow={workflow}
        setTeam={setTeam}
        data={data}
        setWorkflow={setWorkflow}
      />
    </div>
  );
};
