import { ChatConfigurationTemplate } from "../models/ChatConfigurationTemplate";
import { PortalConfig } from "../models/PortalVersion";

export function extractDefaultConfig(
  chatConfig: ChatConfigurationTemplate
): PortalConfig {
  const defaultConfig: PortalConfig = {};

  for (const inputKey in chatConfig.inputs) {
    const input = chatConfig.inputs[inputKey];

    switch (input.input) {
      case "slider":
        defaultConfig[inputKey] = input.defaultValue;
        break;
      case "model":
        const defaultModel = input.values.filter(
          (m) => m.id == input.defaultValue
        )[0];
        defaultConfig[inputKey] = defaultModel.id;
        defaultConfig[`maxLength`] = defaultModel.maxLength / 2;
        break;
      case "array":
        defaultConfig[inputKey] = input.defaultValue;
        break;
      default:
        console.warn(`Unknown input type: ${input}`);
    }
  }

  return defaultConfig;
}
