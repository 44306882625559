import React from "react";
import { CommonSpinner } from "../Common/CommonLoading";

interface TeamSettingsBillingUpgradeLoadingPrps {
  shows: boolean;
}

export const TeamSettingsBillingUpgradeLoading: React.FC<
  TeamSettingsBillingUpgradeLoadingPrps
> = ({ shows }) => {
  if (!shows) {
    return;
  }
  return (
    <div
      className="fixed inset-0 z-30 flex justify-center items-center"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="bg-gray-0 rounded-lg flex flex-col gap-2 shadow-lg p-6 items-center">
        <div className="font-gooper text-2xl text-gray-700">Upgrading Team</div>
        <div className="text-sm font-medium text-gray-700">
          Just a few more seconds and then you're all set...
        </div>
        <CommonSpinner />
      </div>
    </div>
  );
};
