import { getAuth, User } from "firebase/auth";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

export type NetworkMethod = "PUT" | "POST" | "DELETE" | "GET";

export interface Networking {
  sendRequest(endpoint: string, method: string, body?: any): Promise<Response>;
  sendStream(
    endpoint: string,
    method: NetworkMethod,
    body: any,
    onMessage: (message: string) => void, // Callback for handling each message
    onClose: () => void, // Callback when the stream is closed
    onError: (error: any) => void // Error handling callback
  ): Promise<void>;
  cancelStream(): void;
}

export class NetworkHelper {
  private auth = getAuth();

  get currentUser(): User | null {
    return this.auth.currentUser;
  }
  async sendRequest(
    endpoint: string,
    method: string,
    body?: any
  ): Promise<Response> {
    if (!this.currentUser) {
      throw new Error("User is not authenticated");
    }

    const userToken = await this.currentUser.getIdToken();
    const response = await fetch(`${BACKEND_URL}${endpoint}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      cache: "no-store", // Prevent caching
      body: body ? JSON.stringify(body) : undefined,
    });

    if (!response.ok) {
      throw new Error(`Failed to process ${method} request to ${endpoint}`);
    }

    return response;
  }

  currentStreamAbortController?: AbortController;

  async sendStream(
    endpoint: string,
    method: NetworkMethod,
    body: any,
    onMessage: (message: string) => void, // Callback for handling each message
    onClose: () => void, // Callback when the stream is closed
    onError: (error: any) => void // Error handling callback
  ): Promise<void> {
    if (!this.currentUser) {
      throw new Error("User is not authenticated");
    }

    const userToken = await this.currentUser.getIdToken();
    const controller = new AbortController();
    const { signal } = controller;

    this.currentStreamAbortController = controller;

    try {
      const response = await fetch(`${BACKEND_URL}${endpoint}`, {
        method,
        headers: {
          "Content-Type": "application/json",
          Accept: "text/event-stream",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(body),
        cache: "no-store", // Prevent caching
        keepalive: true,
        signal,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      if (!response.body) {
        throw new Error("Failed to get readable stream");
      }

      const reader = response.body.getReader();
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          onClose();
          break;
        }
        onMessage(new TextDecoder().decode(value));
      }
    } catch (error) {
      if (error instanceof Error && error.name === "AbortError") {
        onClose();
      } else {
        onError(error);
      }
    }
  }

  cancelStream() {
    if (this.currentStreamAbortController) {
      this.currentStreamAbortController.abort();
      this.currentStreamAbortController = undefined;
    }
  }
}
