export const formatDate = (date: Date | undefined): string => {
  if (!date) {
    return "";
  }
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("default", options).format(date);
};

export function getDaysUntil(targetDate: Date): number {
  const now = new Date();
  const difference = targetDate.getTime() - now.getTime();
  return Math.ceil(difference / (1000 * 3600 * 24));
}

export const formatTime = (date: Date | undefined): string => {
  if (!date) {
    return "";
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: undefined, // This will use the locale's default preference
  };

  // Get the user's locale, defaulting to 'en-US' if not available
  const locale =
    typeof navigator !== "undefined" ? navigator.language : "en-US";

  // Create a formatter using the locale and options
  const formatter = new Intl.DateTimeFormat(locale, options);

  // Format the date
  return formatter.format(date);
};

export function parseDates<T>(obj: T): T {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (
        typeof value === "string" &&
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)
      ) {
        (obj as any)[key] = new Date(value);
      } else if (typeof value === "object") {
        parseDates(value);
      }
    }
  }

  return obj;
}
