import React, { useEffect, useState } from "react";
import CommonContainer from "../Common/CommonContainer";
import { useParams } from "react-router-dom";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import { Workflow } from "../../models/Workflow";
import { SelectableNodeData, WorkflowMap } from "./Map/WorkflowMap";
import { CommonLoading } from "../Common/CommonLoading";
import { Sidebar } from "./Sidebar/Sidebar";
import { Team } from "../../models/Team";
import { useTeams } from "../../contexts/TeamContext";
import { ReactFlowProvider } from "reactflow";
import { usePortalService } from "../../contexts/PortalContext";
import { ChatConfigurationTemplate } from "../../models/ChatConfigurationTemplate";
import { FloatingBreadcrumb } from "./FloatingBreadcrumb";
import { AppPath } from "../../models/AppPath";
import FailureModal from "../FailureModal";
import { useDebounce } from "../../utils/Debounce";

interface WorkflowsProps {}

export const WorkflowDetail: React.FC<WorkflowsProps> = ({}) => {
  const { teamId, workflowId } = useParams<{
    teamId: string;
    workflowId: string;
  }>();

  const workflowService = useWorkflowService();
  const teamService = useTeams();
  const portalService = usePortalService();

  const [workflow, setWorkflow] = useState<Workflow>();
  const [selectedNodeData, setSelectedNodeData] =
    useState<SelectableNodeData>();
  const [team, setTeam] = useState<Team>();
  const [templates, setTemplates] = useState<ChatConfigurationTemplate[]>();
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const loadData = async () => {
      const fetchWorkflow = workflowService.workflowRepo.get(
        workflowService.workflowPath(teamId!),
        workflowId!
      );
      const fetchTeam = teamService.teamRepo.get(
        teamService.teamPath(),
        teamId!
      );

      const getTempaltes = portalService.configTemplateRepo.getList(
        portalService.configTemplatePath()
      );

      const getWorkflows = workflowService.workflowRepo.getList(
        workflowService.workflowPath(teamId!)
      );
      try {
        const [
          fetchedWorkflow,
          fetchedTeam,
          fetchedTemplates,
          fetchedWorkflows,
        ] = await Promise.all([
          fetchWorkflow,
          fetchTeam,
          getTempaltes,
          getWorkflows,
        ]);
        if (!fetchedWorkflow || !fetchedTeam) {
          return;
        }

        setWorkflow(fetchedWorkflow);
        setTeam(fetchedTeam);
        setTemplates(fetchedTemplates);
        setWorkflows(fetchedWorkflows);
        setLoading(false);
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      }
    };

    loadData();
  }, [workflowService]);

  const handleTeamUpdate = async (team: Team) => {
    setTeam(team);
    await teamService.teamRepo.update(team, teamService.teamPath(), teamId!);
  };

  const updateWorkflow = async (newWorkflow: Workflow) => {
    const updatedWorkflow: Workflow = {
      ...newWorkflow,
      modifiedAt: new Date(),
    };
    setWorkflow(updatedWorkflow);
    await workflowService.workflowRepo.update(
      updatedWorkflow,
      workflowService.workflowPath(teamId!),
      workflowId!
    );
  };

  const mapUpdateDebounce = useDebounce(async (newWorkflow: Workflow) => {
    await updateWorkflow(newWorkflow);
  }, 2000);

  const sidebarUpdateDebounce = useDebounce(async (newWorkflow: Workflow) => {
    await updateWorkflow(newWorkflow);
  }, 1000);

  const handleDataStringUpdate = async (uiDataString: string) => {
    mapUpdateDebounce({ ...workflow!, uiDataString: uiDataString });
  };

  const handleSetWorkflow = (workflow: Workflow) => {
    sidebarUpdateDebounce(workflow);
  };

  return (
    <CommonContainer className="!p-0 relative overflow-x-hidden">
      <FailureModal
        message={error}
        shows={error != ""}
        closed={() => setError("")}
      />
      {loading && <CommonLoading />}
      {workflow && team && templates && !loading && (
        <ReactFlowProvider>
          <div className="flex w-full h-full">
            <div className="flex w-full h-full relative">
              <WorkflowMap
                saveMap={handleDataStringUpdate}
                workflow={workflow}
                teamId={teamId!}
                selectedNode={(data) => {
                  setSelectedNodeData(data);
                }}
                uiString={workflow.uiDataString}
              />
              <div className="absolute flex top-6 left-4">
                <FloatingBreadcrumb
                  teamId={teamId!}
                  sections={[
                    { name: "Workflows", link: AppPath.workflows(teamId!) },
                    {
                      name: `${workflow?.name}`,
                      link: ``,
                    },
                  ]}
                />
              </div>
            </div>
            <Sidebar
              workflow={workflow}
              selectedNodeData={selectedNodeData}
              team={team}
              setTeam={handleTeamUpdate}
              setWorkflow={handleSetWorkflow}
              templates={templates}
              workflows={workflows}
            />
          </div>
        </ReactFlowProvider>
      )}
    </CommonContainer>
  );
};
