import React, { useState } from "react";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import Icon from "../Icon";

interface TeamSettingsNewTokenModalProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  token: string;
}

export const TeamSettingsNewTokenModal: React.FC<
  TeamSettingsNewTokenModalProps
> = ({ shows, setShows, token }) => {
  const [confirmState, setConfirmState] = useState<AnimationState>("ready");
  if (!shows) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-scroll">
      <div className="bg-white rounded-lg gap-4 flex flex-col shadow-lg p-6 w-96">
        <div className="flex flex-row items-center justify-between">
          <h2 className="text-xl font-medium font-gooper text-left text-gray-700">
            Access token successfully created
          </h2>
          <button onClick={() => setShows(false)}>
            <Icon type={"x-no-circle"} className="size-4 text-gray-500" />
          </button>{" "}
        </div>

        <div className="border-b"></div>
        <p className=" text-gray-800 text-sm ">
          Use this in your application to securely connect to the Easybeam API.
        </p>
        <div className="flex flex-col gap-2">
          <div className="text-sm font-medium text-gray-500">API key</div>
          <p
            id="tokenText"
            className="p-2 bg-gray-50 border-gray-300 border rounded text-left text-slate-900 text-xs break-words whitespace-pre-wrap font-gooper font-medium"
          >
            {token}
          </p>
          <div className="text-sm font-gooper text-gray-400">
            This will only be displayed once.
          </div>
        </div>
        <AnimatedButton
          title={`Copy to Clipboard & Close`}
          onClick={() => {
            navigator.clipboard.writeText(token);
            setConfirmState("success");
          }}
          buttonState={confirmState}
          setButtonState={setConfirmState}
          style={"action"}
          key="confirm"
          font="font-sans"
          id="confirmButton"
          classNameIn={`${
            confirmState == "ready" ? "border border-blue-500" : ""
          }`}
          after={() => {
            setShows(false);
          }}
        />
      </div>
    </div>
  );
};
