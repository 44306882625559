import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTeams } from "../contexts/TeamContext";
import { useAuth } from "../contexts/AuthContext";
import FailureModal from "./FailureModal";
import { STORAGE_KEYS } from "../utils/StorageKeys";
import AuthFooter from "./Auth/AuthFooter";
import { CommonLoading } from "./Common/CommonLoading";
import { CommonConfirmationModal } from "./Common/CommonConfirmationModal";

const ConfirmInvite: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const navigate = useNavigate();
  const teamService = useTeams();
  const authService = useAuth();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    async function fetchInvite() {
      try {
        const currentUser = await authService.currentUser();
        if (!currentUser) {
          localStorage.setItem(STORAGE_KEYS.INVITED_TEAM_ID, teamId!);
          setShowConfirmation(true);
          return;
        }

        const existingTeams = currentUser?.teams ?? {};
        if (existingTeams[teamId!] == true) {
          navigate(`/teams/${teamId!}`);
          return;
        }
        await teamService.acceptInvite(teamId!);
        localStorage.removeItem(STORAGE_KEYS.INVITED_TEAM_ID);
        await authService.refreshToken();
        navigate(`/teams/${teamId!}`);
      } catch (e) {
        setIsLoading(false);
        if (e instanceof Error) {
          setError(e.message);
        }
      }
    }
    fetchInvite();
  }, []);

  return (
    <>
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to Home"}
        shows={error != ""}
        closed={() => navigate("/home")}
      />
      <CommonConfirmationModal
        message="We're excited to have you, now let's go make you an account"
        title="Join that team!"
        isOpen={showConfirmation}
        onCancel={() => navigate("/signup")}
        onConfirm={() => navigate("/signup")}
      />

      {isLoading && <CommonLoading />}
      <div className="bg-general-desktop bg-cover bg-center min-h-screen flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center pt-24 gap-4">
          <div className="text-center text-indigo-50 text-9xl font-semibold font-gooper leading-none">
            Team invite
          </div>
          <div className="text-center text-blue-950 text-4xl font-bold font-gooper leading-none">
            Join a team on Easybeam.
          </div>
          <div className="text-center text-gray-50 text-lg font-normal leading-relaxed tracking-tight">
            Give us a sec to process this. &nbsp;
          </div>
        </div>
        <AuthFooter textColor="text-gray-50" textHoverColor="text-gray-600" />
      </div>
    </>
  );
};

export default ConfirmInvite;
