export const InputStyle = {
  control: {
    backgroundColor: "", // Set as needed
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "20px",
  },

  input: {
    borderRadius: "8px",
  },

  "&multiLine": {
    control: {
      fontFamily: "", // Ensure fontFamily is reset
      minHeight: 63,
      lineHeight: "20px", // Consistent line height
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent", // No visible border
    },
    input: {
      padding: 9,
      border: "none", // Removed border
      outline: "none", // Remove focus outline
      lineHeight: "20px",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "1px solid transparent", // Consistent transparency
    },
    input: {
      padding: 1,
      border: "none", // Removed border
      outline: "none", // Remove focus outline
      lineHeight: "20px",
    },
  },

  suggestions: {
    backgroundColor: "transparent",
    list: {
      fontSize: 14,
      borderRadius: "8px",
    },
    item: {
      borderBottom: "none", // Removed border on items
      color: "#374151",
      "&focused": {
        backgroundColor: "#eaefff",
        outline: "none", // No outline on focus
        border: "none", // No border on focus
      },
    },
  },
};
