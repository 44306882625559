import React from "react";

const AuthFooter: React.FC<{ textColor: string; textHoverColor: string }> = ({
  textColor,
  textHoverColor,
}) => {
  return (
    <footer className="mt-auto w-full bg-transparent p-4">
      <div className="max-w-screen-lg mx-auto flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
        <div className="flex flex-row justify-center sm:justify-start space-x-12">
          <a
            href="https://www.easybeam.ai/privacy"
            className={`underline font-medium ${textColor} hover:${textHoverColor}`}
          >
            Privacy
          </a>
          <a
            href="https://www.easybeam.ai/terms"
            className={`underline font-medium ${textColor} hover:${textHoverColor}`}
          >
            Terms
          </a>
          <a
            href="https://www.easybeam.ai/cookies"
            className={`underline font-medium ${textColor} hover:${textHoverColor}`}
          >
            Cookies
          </a>
        </div>
        <span className={`${textColor} text-sm text-center sm:text-left`}>
          © 2024 Easybeam. All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default AuthFooter;
