import React, { ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";
import NavigationLink from "./NavigationLink";
import TeamSelector from "./TeamSelector";
import NavigationNoteCard from "./NavigationNoteCard";
import { Team } from "../../models/Team";
import NavigationUpsellButton from "./NavigationUpsellButton";
import NavigationFooter from "./NavigationFooter";
import NavigationHeader from "./NavigationHeader";
import { STORAGE_KEYS } from "../../utils/StorageKeys";
import { TeamAlert } from "../../models/TeamAlert";
import NotificationsLink from "./NotificationsLink";
import { User } from "../../models/User";
import { TeamSubscription } from "../../models/TeamSubscription";
import { NavigationResubButton } from "./NavigationResubButton";

interface VerticalNavLayoutProps {
  children: ReactNode;
  team: Team | undefined;
  alerts: TeamAlert[];
  currentUser: User | undefined;
  teamSubscription: TeamSubscription | undefined;
}

const VerticalNavLayout: React.FC<VerticalNavLayoutProps> = ({
  children,
  team,
  alerts,
  currentUser,
  teamSubscription,
}) => {
  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem(STORAGE_KEYS.SIDEBAR_COLLAPSED) == "true" ||
      localStorage.getItem(STORAGE_KEYS.SIDEBAR_COLLAPSED) == null
  );

  const [showsNote, setShowsNote] = useState(true);

  const setSaveCollapsed = (isCollapsed: boolean) => {
    localStorage.setItem(
      STORAGE_KEYS.SIDEBAR_COLLAPSED,
      isCollapsed ? "true" : "false"
    );
    setIsCollapsed(isCollapsed);
  };

  const isActive = (path: string) => {
    return location.pathname.includes(path);
  };

  return (
    <div className="flex h-[calc(100dvh)] overflow-y-hidden overflow-x-hidden overscroll-none">
      <div
        className={`${
          isCollapsed ? "w-16 px-0" : "w-48 px-2"
        } flex flex-col gap-y-4 pt-3 bg-gray-100 transition-all duration-75 border-r border-gray-200`}
        id="sidebar-wrapper"
      >
        <NavigationHeader isCollapsed={isCollapsed} />

        <TeamSelector
          isCollapsed={isCollapsed}
          currentTeam={team}
          currentUser={currentUser}
        />

        <div className="flex-grow pt-2">
          <div className="list-none">
            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="home"
              destination="home"
              title="Home"
              isActive={isActive("home")}
            />
            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="portals"
              destination="portals"
              title="Portals"
              isActive={isActive("portals")}
              actionLink="portals"
            />
            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="workflows"
              destination="workflows"
              title="Workflows"
              isActive={isActive("workflows")}
              actionLink="workflows/new"
            />
            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="logs"
              destination="logs"
              title="Portal Logs"
              isActive={isActive("logs")}
            />
            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="chart-pie"
              destination="analytics"
              title="Analytics"
              isActive={isActive("analytics")}
            />
            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="flask"
              destination="test-center"
              title="Test Center"
              isActive={isActive("test-center")}
            />
          </div>
        </div>
        <div className="w-full flex flex-col gap-y-2 ">
          {showsNote && (
            <div className="hidden sm:block">
              <NavigationNoteCard
                isCollapsed={isCollapsed}
                badge="Beta"
                message="Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur."
                cta="Cta something blabla!"
                ctaLink="www.google.com"
                clickedExit={() => setShowsNote(false)}
              />
            </div>
          )}

          <div>
            <NavigationLink
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="profile"
              destination="profile"
              title="Profile"
              isActive={isActive("/profile")}
            />
            <NotificationsLink
              isCollapsed={isCollapsed}
              title="Notifications"
              isActive={isActive("notifications")}
              alerts={alerts}
              teamId={team?.id!}
            />
            <NavigationLink
              id={"teamSettingsButton"}
              isCollapsed={isCollapsed}
              teamId={team?.id!}
              icon="settings"
              destination="settings"
              title="Settings"
              isActive={isActive("settings")}
            />
          </div>
          <div className={`px-2 hidden sm:block`}>
            {teamSubscription?.currentEvent?.willRenew == false ? (
              <NavigationResubButton
                teamId={team?.id ?? ""}
                isCollapsed={isCollapsed}
              />
            ) : (
              <NavigationUpsellButton isCollapsed={isCollapsed} />
            )}
          </div>

          <div className={`pb-4 pt-2 ${isCollapsed ? "px-0" : "px-1"}`}>
            <NavigationFooter
              isCollapsed={isCollapsed}
              toggleCollapse={() => setSaveCollapsed(!isCollapsed)}
            />
          </div>
        </div>
      </div>
      <div className="w-full" id="page-content-wrapper">
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default VerticalNavLayout;
