import FailureModal from "../FailureModal";
import { useAuth } from "../../contexts/AuthContext";
import { CommonLoading } from "../Common/CommonLoading";
import { STORAGE_KEYS } from "../../utils/StorageKeys";
import { useNavigate } from "react-router-dom";

const TeamOnboardingPage: React.FC<{
  children: React.ReactNode;
  error: string;
  title: string;
  headline: string;
  message: string;
  setError: (error: string) => void;
  isLoading: boolean;
  progress: number;
  skipMessage?: string;
  didSkip?: () => void;
  backMessage?: string;
  highlightTitle?: boolean;
  didClickBack?: () => void;
}> = ({
  children,
  error,
  title,
  headline,
  message,
  isLoading,
  setError,
  progress,
  didSkip,
  skipMessage,
  backMessage,
  didClickBack,
  highlightTitle,
}) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    await auth.signOut();
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col justify-between">
      {isLoading && <CommonLoading />}
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to onboarding"}
        shows={error != ""}
        closed={() => setError("")}
      />
      <div className="flex px-4 pt-24 flex-col w-full items-center gap-4 text-center">
        <div className="flex-col space-y-6 sm:space-y-4 gap-4">
          {highlightTitle && (
            <div className="bg-lime-500 rounded-md inline-flex items-center justify-center px-2 py-1 gap-1">
              <svg
                className="w-4 h-4 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16.872 9.687 20 6.56 17.44 4 4 17.44 6.56 20 16.873 9.687Zm0 0-2.56-2.56M6 7v2m0 0v2m0-2H4m2 0h2m7 7v2m0 0v2m0-2h-2m2 0h2M8 4h.01v.01H8V4Zm2 2h.01v.01H10V6Zm2-2h.01v.01H12V4Zm8 8h.01v.01H20V12Zm-2 2h.01v.01H18V14Zm2 2h.01v.01H20V16Z"
                />
              </svg>

              <div className="text-center text-white text-xs font-normal leading-none">
                {title}
              </div>
            </div>
          )}
          {!highlightTitle && (
            <div className="text-gray-500 text-xl font-normal leading-loose">
              {title}
            </div>
          )}
          <div className="text-gray-900 text-4xl md:text-6xl font-medium font-gooper leading-10">
            {headline}
          </div>
          <div className="text-gray-500 text-xl font-normal leading-loose">
            {message}
          </div>
        </div>
        {children}
        <div className="flex justify-between gap-4 items-stretch">
          {didClickBack && (
            <button
              className="mt-16 text-center gap-1 flex justify-between items-center text-gray-500 text-sm font-normal leading-tight bg-transparent border-none cursor-pointer"
              onClick={didClickBack}
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5ZM3.5099 6.69474C3.49003 6.79925 3.50021 6.90758 3.53915 7.00602C3.56484 7.07145 3.60259 7.13093 3.65024 7.18107L5.70751 9.33556C5.75496 9.38701 5.81171 9.42804 5.87446 9.45627C5.93721 9.4845 6.0047 9.49936 6.07299 9.49998C6.14128 9.5006 6.209 9.48697 6.27221 9.45989C6.33542 9.43281 6.39284 9.39281 6.44113 9.34224C6.48942 9.29167 6.52761 9.23153 6.55347 9.16533C6.57934 9.09914 6.59235 9.02821 6.59175 8.9567C6.59116 8.88518 6.57697 8.8145 6.55002 8.74879C6.52306 8.68307 6.48388 8.62364 6.43476 8.57395L5.25543 7.33889H10.1857C10.3221 7.33889 10.4529 7.28214 10.5494 7.18113C10.6458 7.08012 10.7 6.94312 10.7 6.80027C10.7 6.65742 10.6458 6.52042 10.5494 6.41941C10.4529 6.31839 10.3221 6.26165 10.1857 6.26165H5.25543L6.43527 5.02605C6.4844 4.97636 6.52358 4.91693 6.55053 4.85121C6.57749 4.7855 6.59168 4.71482 6.59227 4.6433C6.59286 4.57178 6.57985 4.50086 6.55399 4.43466C6.52813 4.36847 6.48994 4.30833 6.44165 4.25776C6.39336 4.20719 6.33593 4.16719 6.27273 4.14011C6.20952 4.11303 6.14179 4.0994 6.0735 4.10002C6.00521 4.10064 5.93772 4.1155 5.87497 4.14373C5.81222 4.17196 5.75547 4.21299 5.70803 4.26443L3.65075 6.41892C3.57878 6.49425 3.52977 6.59023 3.5099 6.69474Z"
                  fill="#E5E7EB"
                />
              </svg>

              {backMessage ?? "Go Back"}
            </button>
          )}
          {didSkip && (
            <button
              className="mt-16 text-center gap-1 flex justify-between items-center text-gray-500 text-sm font-normal leading-tight bg-transparent border-none cursor-pointer"
              onClick={didSkip}
            >
              {skipMessage ?? "skip"}
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.5 12.5C9.81371 12.5 12.5 9.81371 12.5 6.5C12.5 3.18629 9.81371 0.5 6.5 0.5C3.18629 0.5 0.5 3.18629 0.5 6.5C0.5 9.81371 3.18629 12.5 6.5 12.5ZM9.4901 6.69474C9.50997 6.79925 9.49979 6.90758 9.46085 7.00602C9.43516 7.07145 9.39741 7.13093 9.34976 7.18107L7.29249 9.33556C7.24504 9.38701 7.18829 9.42804 7.12554 9.45627C7.06279 9.4845 6.9953 9.49936 6.92701 9.49998C6.85872 9.5006 6.791 9.48697 6.72779 9.45989C6.66458 9.43281 6.60716 9.39281 6.55887 9.34224C6.51058 9.29167 6.47239 9.23153 6.44653 9.16533C6.42066 9.09914 6.40765 9.02821 6.40825 8.9567C6.40884 8.88518 6.42303 8.8145 6.44998 8.74879C6.47694 8.68307 6.51612 8.62364 6.56524 8.57395L7.74457 7.33889H2.81432C2.67791 7.33889 2.54709 7.28214 2.45064 7.18113C2.35419 7.08012 2.3 6.94312 2.3 6.80027C2.3 6.65742 2.35419 6.52042 2.45064 6.41941C2.54709 6.31839 2.67791 6.26165 2.81432 6.26165H7.74457L6.56473 5.02605C6.5156 4.97636 6.47642 4.91693 6.44947 4.85121C6.42251 4.7855 6.40832 4.71482 6.40773 4.6433C6.40714 4.57178 6.42015 4.50086 6.44601 4.43466C6.47187 4.36847 6.51006 4.30833 6.55835 4.25776C6.60664 4.20719 6.66407 4.16719 6.72727 4.14011C6.79048 4.11303 6.85821 4.0994 6.9265 4.10002C6.99479 4.10064 7.06228 4.1155 7.12503 4.14373C7.18778 4.17196 7.24453 4.21299 7.29197 4.26443L9.34925 6.41892C9.42122 6.49425 9.47023 6.59023 9.4901 6.69474Z"
                  fill="#E5E7EB"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-row pb-4 flex-nowrap justify-center items-center text-gray-400 px-16 w-full">
        {localStorage.getItem(STORAGE_KEYS.LAST_ACCESSED_TEAM) ? (
          <button
            className="text-sm text-left font-medium flex-1 flex-grow hover:text-gray-500"
            onClick={() => navigate(-1)}
          >
            Go back
          </button>
        ) : (
          <button
            className="text-sm text-left font-medium flex-1 flex-grow hover:text-gray-500"
            onClick={() => handleLogOut()}
          >
            Log out
          </button>
        )}

        <div className="flex-1 flex-grow mx-4">
          <div className="h-1 w-full max-w-xs mx-auto rounded-sm bg-gray-200">
            <div
              style={{ width: `${progress}%` }}
              className="h-full rounded-sm bg-green-600"
            ></div>
          </div>
        </div>
        <div className="text-sm text-right flex-1 flex-grow">
          Trying to join a team? Check your emails.
        </div>
      </div>
    </div>
  );
};

export default TeamOnboardingPage;
