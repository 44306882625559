import React, { useEffect, useState } from "react";
import { usePortalService } from "../../contexts/PortalContext";
import { Review } from "../../models/Review";
import { CommonSpinner } from "../Common/CommonLoading";
import { useNavigate } from "react-router-dom";

interface ReviewModalProps {
  reviewId: string | undefined;
  teamId: string;
  portalId: string;
  versionId: string;
}

export const ReviewModal: React.FC<ReviewModalProps> = ({
  reviewId,
  teamId,
  portalId,
  versionId,
}) => {
  const portalService = usePortalService();
  const [review, setReview] = useState<Review>();
  const navigate = useNavigate();

  useEffect(() => {
    setReview(undefined);
    if (!reviewId) {
      return;
    }
    const loadData = async () => {
      const review = await portalService.reviewRepo.get(
        portalService.reviewPath(teamId, portalId, versionId),
        reviewId
      );
      if (review) {
        setReview(review);
      }
    };
    loadData();
  }, [portalService, reviewId]);

  const handleClose = () => {
    navigate(
      `/teams/${teamId}/portals/${portalId}/version/${versionId}/reviews`
    );
  };

  if (!reviewId) return null;
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-scroll"
      onClick={handleClose}
    >
      <div
        className="bg-white rounded-lg gap-4 flex flex-col shadow-lg p-6 w-96 relative"
        onClick={(e) => e.stopPropagation()} // Prevent click from propagating to the background
      >
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={handleClose}
        >
          &times;
        </button>
        {!review && <CommonSpinner />}
        {review != undefined && (
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between">
              <div className="text-md font-gooper text-gray-700">Score:</div>
              <div className="text-md text-gray-500">{`${review.score}`}</div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="text-md font-gooper text-gray-700">
                Created At:
              </div>
              <div className="text-md text-gray-500">
                {review.createdAt.toLocaleString()}
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="text-md font-gooper text-gray-700">UserId:</div>
              <div className="text-md text-gray-500">{`${review.userId}`}</div>
            </div>
            <div className="flex flex-row gap-2">
              <div className="text-md font-gooper text-gray-700">Text:</div>
              <div className="text-md text-gray-500 max-h-52 overflow-y-scroll">{`${review.text}`}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
