import React from "react";
import { PortalVersion } from "../../../../models/PortalVersion";
import { VersionDemoer } from "../../../Portals/Portal Detail/VersionDemor";
import { Team } from "../../../../models/Team";
import Icon from "../../../Icon";

interface PortalDemoModalProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  version: PortalVersion;
  variables: { [id: string]: string };
  setVariables: (variables: { [id: string]: string }) => void;
  team: Team;
}

export const PortalDemoModal: React.FC<PortalDemoModalProps> = ({
  shows,
  version,
  team,
  variables,
  setVariables,
  setShows,
}) => {
  if (!shows) return null;

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setShows(false);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50"
      onClick={handleBackgroundClick}
    >
      <div className="relative w-1/3 h-full bg-transparent rounded-lg">
        <button
          onClick={() => setShows(false)}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          aria-label="Close modal"
        >
          <Icon type="close" />
        </button>
        <div className="p-4 h-full overflow-y-auto">
          <VersionDemoer
            data={{ version: version, type: "portal" }}
            teamId={team.id!}
            demoConfigVariables={variables}
            setDemoConfigVariables={setVariables}
            teamName={team.name}
          />
        </div>
      </div>
    </div>
  );
};
