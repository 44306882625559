import React from "react";
import Icon, { IconType } from "../Icon";
import { CommonSpinner } from "../Common/CommonLoading";

interface GetStartedCardProps {
  sectionTitle: string;
  actionTitle: string;
  title: string;
  subtitle: string;
  number: string;
  iconType: IconType;
  completed: boolean;
  completedMessage: string;
  action: () => void;
  loading: boolean;
}

const GetStartedCard: React.FC<GetStartedCardProps> = ({
  sectionTitle,
  actionTitle,
  action,
  iconType,
  title,
  subtitle,
  number,
  completed,
  completedMessage,
  loading,
}) => {
  const backgroundColor = completed ? "bg-gray-50" : "bg-gray-0";
  const actionIcon: IconType = completed ? "check" : iconType;
  const actionFillColor = completed ? "fill-green-500" : "fill-blue-500";
  const actionTextColor = completed ? "text-green-500" : "text-blue-500";
  const actionFinalTitle = completed ? completedMessage : actionTitle;

  return (
    <div
      className={`${backgroundColor} flex flex-col h-52 border-gray-200 border rounded-lg `}
    >
      <div className="flex flex-row px-4 pt-4 pb-2 border-b justify-between">
        <div className="text-gray-700 text-base font-medium font-gooper">
          {sectionTitle}
        </div>
        <button
          id={`getStartedAction${number}`}
          type="submit"
          className={`flex ${actionFillColor} gap-1 items-center justify-center text-sm font-gooper ${actionTextColor}`}
          onClick={() => action()}
        >
          {actionFinalTitle}
          <Icon type={actionIcon} className="size-4" />
        </button>
      </div>
      {loading ? (
        <div className="flex items-center w-full justify-center h-full">
          <CommonSpinner />
        </div>
      ) : (
        <div className="flex flex-row px-4 justify-between items-start pt-4 lg:pt-8">
          <div className="flex flex-col ">
            <div className="text-transparent bg-clip-text bg-gradient-to-br from-blue-500 to-green-400 text-xl font-semibold font-gooper">
              {title}
            </div>
            <div className="text-gray-700 text-sm font-normal">{subtitle}</div>
          </div>
          <div
            className={`${
              completed ? "opacity-50" : ""
            } text-transparent pr-2 bg-clip-text bg-gradient-to-br from-green-500 to-blue-400 md:text-7xl md:-my-2 text-9xl font-bold font-gooper -my-4`}
          >
            {number}
          </div>
        </div>
      )}
    </div>
  );
};

export default GetStartedCard;
