import React from "react";
import { PortalService } from "../services/PortalService";
import PortalServiceContext from "../contexts/PortalContext";

type Props = {
  children: React.ReactNode;
  portalService: PortalService;
};

export const PortalServiceProvider: React.FC<Props> = ({
  children,
  portalService: portalService,
}) => {
  return (
    <PortalServiceContext.Provider value={portalService}>
      {children}
    </PortalServiceContext.Provider>
  );
};

export default PortalServiceProvider;
