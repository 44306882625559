import { Modal } from "flowbite-react";
import { type FC } from "react";
import Icon, { IconType } from "./Icon";

const ActionModal: FC<{
  iconType: IconType;
  title: string;
  message: string;
  backButtonTitle: string;
  shows: boolean;
  actionTitle: string;
  style: "normal" | "destructive";
  closed: () => void;
  action: () => void;
}> = function ({
  title,
  message,
  backButtonTitle,
  shows,
  actionTitle,
  closed,
  iconType,
  style,
  action,
}) {
  const actionBackground =
    style == "destructive" ? "bg-red-500" : "bg-gray-100";
  const actionTextColor =
    style == "destructive" ? "text-gray-0" : "text-gray-700";
  const actionFillColor =
    style == "destructive" ? "fill-gray-0" : "fill-gray-700";

  return (
    <>
      <Modal onClose={() => closed()} popup size="md" show={shows}>
        <Modal.Body className="outline outline-transparent space-y-6 p-4 focus:ring-0 focus:outline-none relativetext-center bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="flex justify-center items-center">
            <div className="w-12 h-12 bg-gray-50 rounded-full justify-center items-center flex">
              <svg
                className="w-6 h-6 fill-gray-400 stroke-gray-400"
                viewBox="0 0 24 24"
              >
                <Icon type={iconType} />
              </svg>
            </div>
          </div>
          <div>
            <div className="text-2xl text-center font-semibold font-gooper text-gray-900">
              {title}
            </div>
            <div className="text-m pt-1 text-center font-normal text-gray-500">
              {message}
            </div>
          </div>
          <div className="flex gap-2 flex-row w-full font-gooper font-normal">
            <button
              type="submit"
              className="flex bg-gray-100 text-gray-700 items-center justify-center w-full px-4 py-2 text-sm text-center rounded-lg "
              onClick={() => closed()}
            >
              {backButtonTitle}
            </button>
            <button
              type="submit"
              className={`flex ${actionBackground} ${actionTextColor} gap-2 items-center justify-center w-full px-4 py-2 text-sm text-center rounded-lg `}
              onClick={() => action()}
            >
              <svg className={`w-3 h-3 ${actionFillColor}`} viewBox="0 0 24 24">
                <Icon type={iconType} />
              </svg>
              {actionTitle}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ActionModal;
