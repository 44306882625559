const ClearableInput: React.FC<{
  error: string;
  text: string;
  setText: (text: string) => void;
  placeholder: string;
  onUnfocus?: () => void;
  children?: React.ReactNode;
  className?: string;
  index?: number;
}> = ({
  text,
  setText,
  error,
  placeholder,
  onUnfocus,
  children,
  className,
  index,
}) => {
  const borderColor = error ? "border-red-500" : "";
  return (
    <div className="space-y-0 items-center">
      <div className="flex items-center relative">
        <input
          className={`${borderColor} border h-12 focus:border-blue-600 focus:ring-0 focus:outline-none bg-gray-0 text-slate-900 rounded-lg w-full py-2 px-3 leading-tightfont-normal placeholder-gray-400 ${className}`}
          value={text}
          placeholder={placeholder}
          onBlur={() => onUnfocus?.()}
          onChange={(e) => setText(e.target.value)}
          id={`pageInput${index ?? ""}`}
        />
        {children}
        {text != "" && (
          <button
            onClick={() => setText("")}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-transparent text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}
      </div>
      {error && (
        <div className="text-left text-sm text-red-600 font-normal pt-1 pl-1">
          {error}
        </div>
      )}
    </div>
  );
};

export default ClearableInput;
