import { ChatConfigurationTemplate } from "../../models/ChatConfigurationTemplate";
import { Team } from "../../models/Team";
import Icon from "../Icon";

export const ProviderStatusBadge: React.FC<{
  team: Team;
  config: ChatConfigurationTemplate;
}> = ({ config, team }) => {
  const configured = (team.secretsUsed ?? {})[config.id] != undefined;
  const text = configured ? "Active" : "Set up API keys";
  return (
    <div
      key={config.id}
      className={`flex felx-row items-center gap-1 px-2 py-1 rounded text-xs ${
        configured
          ? "bg-green-50 text-green-600"
          : "text-blue-500 border border-blue-500"
      }`}
    >
      <Icon
        type={configured ? "check-line" : "share-nodes"}
        className="size-3"
      />
      {text}
    </div>
  );
};
