import { doc, getDoc, Firestore } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";

export function docToData<T>(doc: any): T {
  const data = doc.data();

  const convertTimestamps = (input: any): any => {
    if (input instanceof Timestamp) {
      return input.toDate();
    } else if (typeof input === "object") {
      for (const key in input) {
        if (input.hasOwnProperty(key)) {
          input[key] = convertTimestamps(input[key]);
        }
      }
    }
    return input;
  };

  const convertedData = convertTimestamps(data);

  return {
    id: doc.id,
    ...convertedData,
  } as T;
}

export async function getDocData<T>(
  path: string,
  db: Firestore
): Promise<T | null> {
  const docSnapshot = await getDoc(doc(db, path));
  if (!docSnapshot.exists()) return null;
  return docToData<T>(docSnapshot);
}
