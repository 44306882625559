import { ReactNode } from "react";

export const CommonCard: React.FC<{
  children: ReactNode;
  padding?: "sm" | "md";
  className?: string;
}> = ({ children, padding, className }) => {
  const paddingToUse = padding == "sm" ? "p-3" : "p-6";

  return (
    <div
      className={`${className} w-full flex gap-4 flex-col border rounded-lg border-gray-200 bg-gray-0 ${paddingToUse}`}
    >
      {children}
    </div>
  );
};
