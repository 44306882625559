import { Label } from "flowbite-react";
import { type FC, useState } from "react";
import Icon from "../Icon";

export type ValidatedInputState = "default" | "error" | "validated";

export const ValidatedInput: FC<{
  title: string;
  placeholder: string;
  error: string;
  onChange: (text: string) => void;
  onUnfocus: () => void;
  inputId: string;
  inputType: string;
  state: ValidatedInputState;
  children?: React.ReactNode;
}> = function ({
  title,
  placeholder,
  error,
  onChange,
  state,
  onUnfocus,
  inputType,
  inputId,
  children,
}) {
  let showCheck = false;
  let inputTextColor = "text-gray-600";
  let inputBorderColor = "border-gray-300";

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  switch (state) {
    case "validated":
      inputBorderColor = "border-green-700";
      inputTextColor = "text-green-700";
      showCheck = true;
      break;
    case "error":
      inputBorderColor = "border-red-500";
      inputTextColor = "text-red-700";
      break;
  }

  return (
    <div className="space-y-0">
      <Label
        htmlFor={inputType}
        className="mb-2 block font-medium text-gray-600"
      >
        {title}
      </Label>
      <div className="relative w-full">
        <input
          className={`${inputBorderColor} ${inputTextColor} h-10 border-1 focus:border-blue-600 focus:ring-0 focus:outline-none bg-gray-50 rounded w-full py-2 px-3 leading-tight font-gooper font-normal placeholder-gray-400`}
          id={inputId}
          type={showPassword ? "text" : inputType}
          placeholder={placeholder}
          onBlur={() => onUnfocus()}
          onChange={(value) => onChange(value.target.value)}
        />
        {inputType === "password" && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            onClick={togglePasswordVisibility}
          >
            <Icon
              type={`${!showPassword ? "eye" : "eye-slash"}`}
              className="size-4 text-gray-500"
            />
          </button>
        )}
        {children}
        {showCheck && inputType !== "password" && (
          <img
            src="/images/check-circle-outline.svg"
            className="absolute w-4 h-4 text-green-500 right-4 top-1/2 transform -translate-y-1/2"
          />
        )}
      </div>
      {error && (
        <Label className="pt-2 font-normal text-sm text-red-600">{error}</Label>
      )}
    </div>
  );
};
