import React, { useState } from "react";
import AnimatedButton, {
  AnimatedButtonStyle,
  AnimationState,
} from "../AnimatedButton";
import { IconType } from "../Icon";

interface CommonTypedConfirmationModalProps {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmStyle?: AnimatedButtonStyle;
  confirmIcon?: IconType;
  confirmationPhrase: string;
}

export const CommonTypedConfirmationModal: React.FC<
  CommonTypedConfirmationModalProps
> = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  confirmStyle,
  confirmIcon,
  confirmationPhrase,
}) => {
  if (!isOpen) return null;

  const [confirmState, setConfirmState] = useState<AnimationState>("ready");
  const [text, setText] = useState("");

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 className="text-2xl font-bold mb-4 font-gooper text-center text-gray-900">
          {title}
        </h2>
        <p className="mb-6 text-center text-gray-500">{message}</p>
        <input
          className={`border-gray-300 focus:border-blue-500 bg-gray-50 text-gray-700 w-full mb-4 border rounded-md p-2 leading-tight text-sm focus:ring-0 focus:outline-none h-10`}
          value={text}
          onChange={(value) => setText(value.target.value)}
          placeholder={`Type ${confirmationPhrase} & click confirm if you're sure.`}
          id={"confirmationInput"}
        />
        <div className="grid grid-cols-2 w-full gap-2">
          <AnimatedButton
            title={`Cancel`}
            onClick={() => onCancel()}
            buttonState={"ready"}
            style={"normal"}
            key="cancel"
            font="font-sans"
            id="cancelButton"
          />
          <AnimatedButton
            title={`Confirm`}
            onClick={() => onConfirm()}
            buttonState={confirmState}
            setButtonState={setConfirmState}
            style={confirmStyle ?? "action"}
            key="action"
            leftIcon={confirmIcon}
            font="font-sans"
            disabled={text != confirmationPhrase}
            id="confirmButton"
          />
        </div>
      </div>
    </div>
  );
};
