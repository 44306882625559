import React, { useState } from "react";
import { User } from "../../models/User";
import useImagePreloader from "./ImagePreloader";

interface BannerPageData {
  title: string;
  imageUrl: string;
  subtitle: string;
  actionTitle: string;
  actionLink: string;
  actionColor: "white" | "blue";
  backgroundColor: "red" | "pink" | "lime";
}

interface BannerCarouselProps {
  currentUser: User | undefined;
}

const BannerCareousel: React.FC<BannerCarouselProps> = () => {
  const bannerPages: BannerPageData[] = [
    {
      title: "Easybeam in 60 seconds",
      subtitle: "Watch this short intro video to get started",
      actionTitle: "Watch the video",
      actionLink: "https://www.google.com",
      actionColor: "white",
      backgroundColor: "red",
      imageUrl: "https://i.ibb.co/vv6v35p/Frame-266.png",
    },
    {
      title: "Invite your team",
      subtitle: "Get em all in once place",
      actionTitle: "Invite Team Now",
      actionLink: "https://www.google.com",
      actionColor: "blue",
      backgroundColor: "pink",
      imageUrl: "https://i.ibb.co/3TxS2m8/Frame-264.png",
    },
    {
      title: "A-P-I in 1-2-3",
      subtitle: "It's gunna be that e-z",
      actionTitle: "Read the guide step by step",
      actionLink: "https://www.google.com",
      actionColor: "blue",
      backgroundColor: "lime",
      imageUrl: "https://i.ibb.co/3dMtMLc/Frame-265.png",
    },
  ];

  useImagePreloader(bannerPages.map((bp) => bp.imageUrl));

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const data = bannerPages[currentPageIndex];

  let backgroundColor = "";
  switch (data.backgroundColor) {
    case "red":
      backgroundColor = "bg-red-400";
      break;
    case "pink":
      backgroundColor = "bg-pink-400";
      break;
    case "lime":
      backgroundColor = "bg-lime-400";
      break;
  }
  const actionTextColor =
    data.actionColor == "white" ? "text-gray-0" : "text-blue-500";
  const actionBorderColor =
    data.actionColor == "white" ? "border-gray-0" : "border-blue-500";

  const handleNext = () => {
    const nextIndex =
      currentPageIndex + 1 == bannerPages.length ? 0 : currentPageIndex + 1;
    setCurrentPageIndex(nextIndex);
  };

  const handleBack = () => {
    const backIndex =
      currentPageIndex == 0 ? bannerPages.length - 1 : currentPageIndex - 1;
    setCurrentPageIndex(backIndex);
  };

  return (
    <div
      className={`${backgroundColor} transition-all duration-200 flex px-0 overflow-clip items-center w-full h-48 rounded-lg`}
    >
      <div className="px-6 flex items-center justify-center">
        <BannerNavButton onClick={() => handleBack()} back={true} />
      </div>

      <div className="flex px-4 flex-row items-center w-full justify-between">
        <div className="flex flex-col gap-2">
          <div className="font-gooper text-lg md:text-3xl text-gray-900">
            {data.title}
          </div>
          <div className="text-sm md:text-lg text-gray-700">
            {data.subtitle}
          </div>
          <div className="pt-2">
            <button
              className={`${actionTextColor} ${actionBorderColor} items-center text-sm gap-1 border-1 flex flex-row px-3 py-2 rounded-lg`}
              onClick={() => window.open(data.actionLink, "_blank")} // Added for navigation
            >
              {data.actionTitle}
              <svg
                className="w-5 h-5 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </button>
          </div>
        </div>
        <></>
        <div className="w-80 h-80 relative items-center justify-center hidden lg:block">
          <img
            className="absolute z-10 h-full w-full object-cover object-center"
            src={data.imageUrl}
            alt="nature image"
          />
        </div>
      </div>
      <div className="w-24 flex items-center justify-center">
        <BannerNavButton onClick={() => handleNext()} />
      </div>
    </div>
  );
};

const BannerNavButton: React.FC<{ back?: boolean; onClick: () => void }> = ({
  onClick,
  back,
}) => {
  return (
    <button
      onClick={onClick}
      className="w-9 h-9 items-center flex justify-center bg-gray-50 rounded-full"
    >
      <svg
        className="w-6 h-6 text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d={`${back ? "m15 19-7-7 7-7" : "m9 5 7 7-7 7"}`}
        />
      </svg>
    </button>
  );
};

export default BannerCareousel;
