export const AppPath = {
  login(): string {
    return "/login";
  },
  signup(): string {
    return "/signup";
  },
  onboarding(): string {
    return "/onboarding";
  },
  postOnboarding(): string {
    return "/postonboarding";
  },
  newTeam(): string {
    return `/newTeam`;
  },

  team(teamId: string): string {
    return `/teams/${teamId!}`;
  },

  portals(teamId: string): string {
    return `${this.team(teamId)}/portals`;
  },
  portal(teamId: string, portalId: string): string {
    return `${this.portals(teamId)}/${portalId}/version`;
  },
  portalVersion(teamId: string, portalId: string, versionId: string): string {
    return `${this.portal(teamId, portalId)}/${versionId}`;
  },

  workflows(teamId: string): string {
    return `${this.team(teamId)}/workflows`;
  },
  workflow(teamId: string, workflowId: string): string {
    return `${this.workflows(teamId)}/${workflowId}`;
  },

  settings(teamId: string): string {
    return `${this.team(teamId)}/settings`;
  },
  billing(teamId: string): string {
    return `${this.team(teamId)}/settings/billing`;
  },
  testCenter(
    teamId: string,
    portalId?: string,
    testId?: string,
    versions?: string[]
  ): string {
    let url = `${this.team(encodeURIComponent(teamId))}/test-center`;

    const params: string[] = [];

    if (portalId) {
      params.push(`portal=${encodeURIComponent(portalId)}`);
    }

    if (testId) {
      params.push(`testId=${encodeURIComponent(testId)}`);
    }

    if (versions && versions.length > 0) {
      params.push(`versions=${versions.map(encodeURIComponent).join(",")}`);
    }

    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return url;
  },
};
