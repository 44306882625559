import React, { useState, useEffect, useRef } from "react";

import { PortalTest } from "../../models/PortalTest";
import { useTestService } from "../../contexts/TestContext";
import Icon from "../Icon";
import { CommonSpinner } from "../Common/CommonLoading";
import { formatDate, formatTime } from "../../utils/DateUtils";
import { CommonInputSegment } from "../Common/CommonInput";
import { usePortalService } from "../../contexts/PortalContext";
import { Portal } from "../../models/Portal";
import { VariableInput } from "../Portals/Portal Detail/VersionDemor";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { CommonConfirmationModal } from "../Common/CommonConfirmationModal";
import { useNavigate } from "react-router-dom";
import { AppPath } from "../../models/AppPath";

interface TestDetailProps {
  closed: () => void;
  testId: string;
  teamId: string;
  portalId: string;
}

export const TestDetail: React.FC<TestDetailProps> = ({
  closed,
  testId,
  teamId,
  portalId,
}) => {
  const testService = useTestService();
  const portalService = usePortalService();
  const navigate = useNavigate();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [test, setTest] = useState<PortalTest>();
  const [portal, setPortal] = useState<Portal>();
  const [error, setError] = useState("");

  const [saveState, setSaveState] = useState<AnimationState>("ready");
  const [deleteState, setDeleteState] = useState<AnimationState>("ready");

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        closed();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const fetchTest = testService.testRepo.get(
          testService.testPath(teamId, portalId),
          testId
        );
        const fetchPortal = portalService.portalRepo.get(
          portalService.portalPath(teamId),
          portalId
        );

        const [fetchedTest, fetchedPortal] = await Promise.all([
          fetchTest,
          fetchPortal,
        ]);
        if (!fetchedTest) {
          throw Error("No test");
        }
        if (!fetchedPortal) {
          throw Error("No portal");
        }
        setTest(fetchedTest);
        setPortal(fetchedPortal);
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      }
    };
    loadData();
  }, [testService]);

  const handleClose = () => {
    closed();
  };

  const handleSave = async () => {
    setSaveState("loading");
    try {
      testService.testRepo.set(
        test!,
        testService.testPath(teamId, portalId),
        testId
      );
      setSaveState("success");
    } catch (e) {
      setSaveState("error");
    }
  };

  const handleDelete = async () => {
    setDeleteState("loading");
    try {
      testService.testRepo.delete(
        testService.testPath(teamId, portalId),
        testId
      );
      setDeleteState("success");
      closed();
    } catch (e) {
      setDeleteState("error");
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-scroll"
      onClick={handleClose}
    >
      <CommonConfirmationModal
        isOpen={deleteModalOpen}
        title="Are you sure?"
        message="Deleting this test will be permanent."
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={() => handleDelete()}
        confirmStyle={"destructive"}
        confirmIcon="trash"
      />
      <div
        className="bg-white max-h-[90%] rounded-lg flex flex-col shadow-lg w-[544px] relative overflow-y-auto"
        onClick={(e) => e.stopPropagation()} // Prevent click from propagating to the background
      >
        <div className="flex justify-end">
          <button
            className="text-gray-200 hover:text-gray-300 pt-8 pr-6"
            onClick={handleClose}
          >
            <Icon type="x" className="size-5" />
          </button>
        </div>
        {!test && (
          <div className="items-center justify-center flex pb-4">
            <CommonSpinner />
          </div>
        )}
        {error && (
          <div className="flex flex-col w-full overflow-y-auto p-6 gap-6">
            <div className="font-gooper text-lg">{error}</div>
          </div>
        )}
        {test && portal && (
          <div className="flex flex-col w-full overflow-y-auto p-6 gap-6">
            <div className="flex justify-between items-center">
              <div className="flex justify-between text-xs text-gray-500 gap-2">
                <span> Created</span>
                <span>{formatDate(test.createdAt)}</span>
                <span>{formatTime(test.createdAt)}</span>
              </div>
            </div>
            <div
              className="text-gray-500 text-sm text-center cursor-pointer hover:text-gray-700"
              onClick={() => navigate(AppPath.portal(teamId, portalId))}
            >
              {portal.name}
            </div>
            <CommonInputSegment
              title="Test Name"
              value={test.name}
              className="font-sans text-gray-700"
              onChange={(name) => setTest({ ...test, name })}
            />
            <div className="flex flex-row justify-between items-center">
              <div className="text-gray-700 font-medium text-sm">
                Test variables
              </div>{" "}
              <div className="font-normal text-[10px] text-gray-500">
                {`${Object.keys(portal.usedVariables ?? {}).length} variables`}
              </div>
            </div>
            <div className="overflow-y-auto max-h-48 lg:max-h-80 rounded-lg -mt-2 ">
              <div className="grid grid-cols-2 gap-2">
                {Object.keys(portal.usedVariables ?? {})
                  .sort((a, b) => a.localeCompare(b))
                  .map((variable, index) => {
                    return (
                      <div className="w-full" key={variable}>
                        <VariableInput
                          setNewValue={(value) =>
                            setTest({
                              ...test,
                              parameterValues: {
                                ...test.parameterValues,
                                [variable]: value,
                              },
                            })
                          }
                          value={test.parameterValues[variable] || ""} // Provide a default empty string
                          title={variable}
                          id={`variable${index + 1}`}
                          className="bg-gray-50"
                        />
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <AnimatedButton
                style="action"
                setButtonState={setSaveState}
                buttonState={saveState}
                title="Save test"
                onClick={handleSave}
                font="font-sans"
              />
              <AnimatedButton
                style="transparent-destructive"
                setButtonState={setDeleteState}
                buttonState={deleteState}
                title="Delete Test"
                onClick={() => setDeleteModalOpen(true)}
                font="font-sans"
                leftIcon="trash"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
