import { getFirestore } from "firebase/firestore";
import firebaseApp from "../Firebase";
import { PortalTest } from "../models/PortalTest";
import { TestRun } from "../models/TestRun";
import { FirestoreRepository } from "../repos/FirestoreRepository";
import { Repository } from "../repos/Repository";
import { PortalTestSuite } from "../models/PortalTestSuite";
import { NetworkHelper } from "../utils/NetworkHelper";

export interface TestService {
  testRunRepo: Repository<TestRun>;
  testRunPath(teamId: string, portalId: string): string;
  testRepo: Repository<PortalTest>;
  testPath(teamId: string, portalId: string): string;
  testSuiteRepo: Repository<PortalTestSuite>;
  testSuitePath(teamId: string, portalId: string): string;
  runTest(testRun: TestRun, portalId: string, teamId: string): Promise<void>;
}

export class FirestoreTestService implements TestService {
  networkHelper = new NetworkHelper();

  testRunRepo = new FirestoreRepository<TestRun>(getFirestore(firebaseApp));
  testRepo = new FirestoreRepository<PortalTest>(getFirestore(firebaseApp));
  testSuiteRepo = new FirestoreRepository<PortalTestSuite>(
    getFirestore(firebaseApp)
  );

  testRunPath(teamId: string, portalId: string): string {
    return `teams/${teamId}/portals/${portalId}/test-runs`;
  }
  testPath(teamId: string, portalId: string): string {
    return `teams/${teamId}/portals/${portalId}/tests`;
  }
  testSuitePath(teamId: string, portalId: string): string {
    return `teams/${teamId}/portals/${portalId}/testSuites`;
  }

  async runTest(
    testRun: TestRun,
    portalId: string,
    teamId: string
  ): Promise<void> {
    await this.networkHelper.sendRequest("/portal-tests/run", "POST", {
      testRun,
      teamId,
      portalId,
      messages: testRun.messages,
    });
  }
}
