import React, { ReactNode } from "react";
import UserEventContext from "../contexts/UserEventContext";
import { UserEventService } from "../services/UserEventService";

interface UserEventProviderProps {
  service: UserEventService;
  children: ReactNode;
}

const UserEventProvider: React.FC<UserEventProviderProps> = ({
  service,
  children,
}) => {
  return (
    <UserEventContext.Provider value={service}>
      {children}
    </UserEventContext.Provider>
  );
};

export default UserEventProvider;
