export const extractVariables = (prompt: string): string[] => {
  const regex = /@\[[^\]]+\]\(([^)]+)\)/g;
  const matches = [];
  let match;

  while ((match = regex.exec(prompt)) !== null) {
    matches.push(match[1]);
  }

  return matches;
};

export const extractVariableValues = (
  prompt: string
): { [key: string]: string } => {
  return extractVariables(prompt).reduce((acc, id) => {
    acc[id] = "";
    return acc;
  }, {} as { [key: string]: string });
};
