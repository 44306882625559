import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";

interface CookieConsentProps {}

const CookieConsent: React.FC<CookieConsentProps> = ({}) => {
  const posthog = usePostHog();
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsAnimating(true);
    posthog.opt_in_capturing();
    setTimeout(() => setIsVisible(false), 500); // match the animation duration
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "false");
    setIsAnimating(true);
    posthog.opt_out_capturing();
    setTimeout(() => setIsVisible(false), 500); // match the animation duration
  };

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    } else {
      posthog.opt_in_capturing();
    }
  }, [posthog]);

  return (
    isVisible && (
      <div
        className={`bg-gray-800 opacity-90 fixed bottom-0 w-full text-white p-4 flex justify-between items-center transition-transform transform ${
          isAnimating
            ? "animate-slide-out"
            : "translate-y-full animate-slide-in"
        }`}
      >
        <span>
          We use cookies to help improve our service, but you can opt out if you
          want.
        </span>
        <div className="flex flex-row gap-16">
          <button
            id={"acceptCookiesButton"}
            onClick={handleAccept}
            className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded transition-colors duration-200"
          >
            Accept
          </button>
          <button
            id={"declineCookiedButton"}
            onClick={handleDecline}
            className="bg-transparent hover:bg-blue-700 text-white font-medium py-2 px-4 rounded transition-colors duration-200"
          >
            Opt out
          </button>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
