import { useAuth } from "../../contexts/AuthContext";

import { Card } from "flowbite-react";
import { useState, type FC, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AuthPage from "./AuthPage";

const ForgotPassword: FC = function () {
  const auth = useRef(useAuth());
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const resetPassword = async () => {
    setIsLoading(true);
    try {
      await auth.current.resetPassword(email);
      setSuccessMessage("Please check your inbox.");
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthPage
      error={error}
      modalBackTitle="return to forgot password"
      setError={setError}
      isLoading={isLoading}
      successMessage={successMessage}
      setSuccessMessage={setSuccessMessage}
      successTitle="Password reset link sent!"
    >
      <Card className="shadow-none max-w-lg rounded-2xl">
        <div className="space-y-4">
          <h1 className="text-2xl font-gooper font-black text-gray-800 sm:text-5xl">
            Reset your password
          </h1>
          <p className="text-sm font-light text-gray-700">
            Need a password refresh? Check your email for a link to set a new
            one. Easy peasy!&nbsp;
          </p>

          <form className="space-y-4 lg:space-y-6" action="#">
            <div>
              <input
                className="border-gray-300 border-1 focus:border-blue-600 focus:ring-0 focus:outline-none  bg-gray-50 rounded w-full py-2 px-3 leading-tight font-gooper font-normal text-gray-600 placeholder-gray-400"
                id="email"
                type="text"
                placeholder="Enter your email"
                onChange={(value) => setEmail(value.target.value)}
              />
            </div>
            <button
              className="h-10 w-full py-2.5 bg-blue-600 rounded-lg justify-center items-center gap-2 inline-flex"
              onClick={() => resetPassword()}
            >
              <div className="text-white text-sm font-medium font-['General Sans'] leading-tight">
                Reset password
              </div>
            </button>

            <button
              onClick={() => navigate("/login")}
              className="h-10 w-full py-2.5 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 inline-flex"
            >
              <div className="text-gray-900 text-sm font-medium font-['General Sans'] leading-tight">
                Go back to login
              </div>
            </button>

            <p className="text-sm text-center font-light text-gray-700">
              If you still need help, contact&nbsp;
              <a
                href="signup"
                className="font-normal text-blue-600 hover:underline"
              >
                easybeam support
              </a>
            </p>
          </form>
        </div>
      </Card>
    </AuthPage>
  );
};

export default ForgotPassword;
