export const CommonBadge: React.FC<{
  text: string;
  background?: string;
  textColor?: string;
  onClick?: () => void;
}> = ({ text, background, textColor, onClick }) => {
  const textColorDefaulted = textColor ?? "text-gray-700";
  const backgroundDefaulted = background ?? "bg-gray-100";
  return (
    <div
      className={`${
        onClick ? "cursor-pointer" : "cursor-default"
      } px-2.5 py-1  rounded-sm justify-center items-center gap-1 flex ${backgroundDefaulted}`}
      onClick={onClick}
    >
      <div
        className={`text-center text-xs ${textColorDefaulted} font-medium leading-none`}
      >
        {text}
      </div>
    </div>
  );
};
