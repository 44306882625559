import { FC } from "react";
import AuthFooter from "./AuthFooter";
import { useNavigate } from "react-router-dom";
import CommonButton from "../Common/CommonButton";

const OkayBye: FC = function () {
  const navigate = useNavigate();
  return (
    <div className="bg-general-desktop bg-cover bg-center min-h-screen flex flex-col justify-center items-center gap-4">
      <div className="absolute flex flex-col justify-center items-center gap-4">
        <div className="text-center text-indigo-50 text-9xl font-semibold font-gooper leading-none">
          Okay bye.
        </div>
        <div className="text-center text-blue-950 text-4xl font-bold font-gooper leading-none">
          You've been signed out of easybeam.
        </div>
        <CommonButton
          title={"Log in again"}
          onClick={() => navigate("/login")}
        />
      </div>
      <AuthFooter textColor="text-gray-50" textHoverColor="text-gray-600" />
    </div>
  );
};

export default OkayBye;
