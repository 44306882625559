import { useState } from "react";
import { ChatMessage } from "../../../../../models/ChatMessage";
import Icon from "../../../../Icon";
import { ChatBubble } from "../../../../Portals/Portal Detail/ChatBubble";

export const MessagesCard: React.FC<{
  messages: ChatMessage[];
}> = ({ messages }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      className="border p-2 border-gray-200 w-full bg-blue-25 rounded-lg flex-col transition-none"
      onClick={(e) => {
        e.stopPropagation();
        setExpanded(!expanded);
      }}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row gap-2 items-center">
          <div className="font-gooper text-gray-700 text-sm ">
            Message History
          </div>
          <div className="text-gray-500 text-xs ">Review User Messages</div>
        </div>
        <button>
          <Icon
            type="chevron-up"
            className={`${expanded ? "rotate-180" : ""}  transition-all`}
          />
        </button>
      </div>
      <div
        className={`${
          expanded ? "max-h-48" : "h-0"
        } flex flex-col overflow-y-scroll`}
      >
        {messages.map((message) => {
          return (
            <ChatBubble
              message={message}
              teamName=""
              loading={false}
              isLast={false}
            />
          );
        })}
      </div>
    </div>
  );
};
