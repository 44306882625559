import React from "react";
import { TeamAlert } from "../../models/TeamAlert";
import { useNavigate } from "react-router-dom";
import Icon from "../Icon";

interface NotificationsLinkProps {
  teamId: string;
  title: string;
  isActive: boolean;
  isCollapsed: boolean;
  alerts: TeamAlert[];
}

const NotificationsLink: React.FC<NotificationsLinkProps> = ({
  title,
  isActive,
  isCollapsed,
  alerts,
  teamId,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/teams/${teamId}/notifications`);
  };

  const unreadAlerts = alerts.filter((alert) => !alert.isRead);

  return (
    <div
      role="button"
      tabIndex={0}
      className={`flex items-center ${
        isCollapsed ? "justify-center" : "justify-between"
      } gap-2 py-2 px-1 hover:bg-blue-50 rounded-lg font-gooper ${
        isActive ? "text-blue-500" : "text-gray-800"
      }`}
      onClick={() => handleClick()}
    >
      <div className="flex relative items-center gap-2">
        <Icon type={"bell"} className="text-gray-300 size-5" />
        {!isCollapsed && (
          <span className={`text-base font-medium leading-normal`}>
            {title}
          </span>
        )}
      </div>
      {unreadAlerts.length > 0 && (
        <div
          className={`w-5 ${
            isCollapsed ? "absolute" : ""
          } ml-5 z-0 h-5 px-2.5 py-0.5 bg-blue-50 rounded-3xl justify-center items-center inline-flex`}
        >
          <div className="text-center text-blue-600 text-xs font-medium leading-none">
            {unreadAlerts.length}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsLink;
