import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import { Spinner } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { usePortalLogService } from "../../contexts/PortalLogContext";
import { PortalLog } from "../../models/PortalLog";

interface LogsCardProps {
  teamId: string;
}

const LogsCard: React.FC<LogsCardProps> = ({ teamId }) => {
  const portalLogService = usePortalLogService();
  const navigate = useNavigate();

  const [logs, setLogs] = useState<PortalLog[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPortals = async () => {
      setLoading(true);
      const logs = await portalLogService.portalLogRepo.getList(
        portalLogService.portalLogPath(teamId!),
        { name: "createdAt", descending: true },
        undefined,
        5
      );
      setLoading(false);

      setLogs(logs);
    };

    fetchPortals();
  }, []);

  const handlePortalAction = () => {
    navigate(`../portals/new`);
  };

  const handleOpen = (log: PortalLog) => {
    navigate(`../logs/${log.id}`);
  };

  return (
    <div className="bg-gray-0 w-full min-h-52 rounded-lg border border-gray-200 flex flex-col">
      <div className="flex flex-row px-4 pt-4 pb-2 border-b justify-between">
        <div className="text-gray-700 text-base font-medium font-gooper">
          Logs
        </div>
        <button
          type="submit"
          className={`flex fill-blue-500 gap-1 items-center justify-center text-sm font-gooper text-blue-500`}
          onClick={() => handlePortalAction()}
        >
          View all
          <Icon type={"logs"} className="size-4" />
        </button>
      </div>
      {loading ? (
        <div className="w-full h-full items-center flex justify-center">
          <Spinner className="fill-pink-500" size="xl" light={true} />
        </div>
      ) : (
        <div className="flex w-full flex-col gap-2 border-0 p-4">
          {logs.map((log) => {
            return (
              <div key={log.id} className="flex flex-row w-full bg-green">
                <div className="flex flex-row w-full gap-2 items-center">
                  <div className="text-gray-700 font-gooper text-xs font-medium">
                    {log.portalName}
                  </div>
                </div>
                <div className="flex justify-end flex-row w-full gap-1 text-xs font-normal items-center">
                  <div className="text-gray-400 ">
                    {new Date(log.createdAt).toLocaleString()}
                  </div>
                  <button
                    type="submit"
                    className={`flex items-center justify-center text-xs font-gooper text-blue-500 underline`}
                    onClick={() => handleOpen(log)}
                  >
                    View Log
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LogsCard;
