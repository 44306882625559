import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import OnboardingPage from "./OnboardingPage";
import SelectionBoard from "./SelectionBoard";
import { OnboardingStep } from "./UserOnboarding";
import { NextButton } from "./NextButton";

const sizes = ["Just me", "2-10", "11-100", "101+"];

const UserOrgSize: React.FC<{
  setStep: (step: OnboardingStep) => void;
  skipSurvey: () => void;
  progress: number;
}> = ({ setStep, skipSurvey, progress }) => {
  const authService = useAuth();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSize, setSelectedSize] = useState<string>("");

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const currentUser = await authService.currentUser();
      if (!currentUser) return;

      await authService.userRepo.update(
        { orgSize: selectedSize ?? "SKIPPED" },
        authService.userPath(),
        currentUser.id
      );
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setIsLoading(false);
    }
    setStep(OnboardingStep.AiExp);
  };

  return (
    <OnboardingPage
      error={error}
      title={"Question 2/4"}
      headline="How big is your organization?"
      message="We’re a tool for all teams big and small, but this is super insightful for us."
      isLoading={isLoading}
      progress={progress}
      setError={() => undefined}
      didSkip={skipSurvey}
    >
      <SelectionBoard
        items={sizes}
        selected={selectedSize}
        setSelected={setSelectedSize}
        columns={4}
        textSize="text-2xl"
      />
      <NextButton onClick={handleSubmit} />
    </OnboardingPage>
  );
};

export default UserOrgSize;
