export function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime();
  }

  if (
    (typeof obj1 !== "object" || obj1 === null) &&
    (typeof obj2 !== "object" || obj2 === null)
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1) as string[];
  const keys2 = Object.keys(obj2) as string[];

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }

    const val1 = obj1[key];
    const val2 = obj2[key];

    // Handle string, number, and string[]
    if (
      (typeof val1 === "string" || typeof val1 === "number") &&
      (typeof val2 === "string" || typeof val2 === "number")
    ) {
      if (val1 !== val2) {
        return false;
      }
    } else if (Array.isArray(val1) && Array.isArray(val2)) {
      if (
        val1.length !== val2.length ||
        !val1.every((item, index) => item === val2[index])
      ) {
        return false;
      }
    } else if (!deepEqual(val1, val2)) {
      return false;
    }
  }

  return true;
}
