import React from "react";
import { PortalLogService } from "../services/PortalLogService";

const PortalLogContext = React.createContext<PortalLogService | undefined>(
  undefined
);

export const usePortalLogService = (): PortalLogService => {
  const context = React.useContext(PortalLogContext);
  if (!context) {
    throw new Error(
      "usePortalLogService must be used within a PortalLogServiceProvider"
    );
  }
  return context;
};

export default PortalLogContext;
