export function deepCopy<T>(obj: T): T {
  if (obj === null || typeof obj !== "object" || obj === undefined) {
    return obj;
  }

  let copy: Partial<T>;

  if (typeof obj === "object") {
    if (obj instanceof Date) {
      copy = new Date(obj.getTime()) as unknown as Partial<T>;
      return copy as T;
    }

    if (Array.isArray(obj)) {
      copy = [] as unknown as Partial<T>;
      obj.forEach((item, index) => {
        (copy as unknown as T[])[index] = deepCopy(item);
      });
      return copy as T;
    }

    copy = Object.create(Object.getPrototypeOf(obj));
    for (const attr in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, attr)) {
        const value = (obj as { [key: string]: any })[attr];
        // Check if the value is a valid date string
        if (typeof value === "string" && isValidDateString(value)) {
          (copy as { [key: string]: any })[attr] = new Date(value);
        } else {
          (copy as { [key: string]: any })[attr] = deepCopy(value);
        }
      }
    }
    // Handle symbol typed keys
    Object.getOwnPropertySymbols(obj).forEach((symbol) => {
      const value = (obj as { [key: symbol]: any })[symbol];
      if (typeof value === "string" && isValidDateString(value)) {
        (copy as { [key: symbol]: any })[symbol] = new Date(value);
      } else {
        (copy as { [key: symbol]: any })[symbol] = deepCopy(value);
      }
    });
    return copy as T;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

// Helper function to validate date strings
function isValidDateString(dateString: string): boolean {
  // ISO 8601 date format regex
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z?$/;
  return iso8601Regex.test(dateString);
}
