import React from "react";
import { ChangeLogService } from "../services/ChangeLogService";
import ChangeLogServiceContext from "../contexts/ChangeLogContext";

type Props = {
  children: React.ReactNode;
  changeLogService: ChangeLogService;
};

export const ChangeLogServiceProvider: React.FC<Props> = ({
  children,
  changeLogService,
}) => {
  return (
    <ChangeLogServiceContext.Provider value={changeLogService}>
      {children}
    </ChangeLogServiceContext.Provider>
  );
};

export default ChangeLogServiceProvider;
