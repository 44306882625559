import React from "react";

interface NavigationUpsellButtonProps {
  isCollapsed: boolean;
}

const NavigationUpsellButton: React.FC<NavigationUpsellButtonProps> = ({
  isCollapsed,
}) => {
  return (
    <button
      className={`${
        isCollapsed ? "invisible" : ""
      } py-2.5 w-full px-3 bg-blue-50 border-1 border-blue-100 hover:bg-blue-100 rounded-lg justify-center items-center gap-2 flex`}
    >
      <div className="text-center text-blue-400 text-xs font-medium leading-none">
        Upgrade to Pro
      </div>
    </button>
  );
};

export default NavigationUpsellButton;
