import FailureModal from "../FailureModal";
import { useAuth } from "../../contexts/AuthContext";
import { CommonLoading } from "../Common/CommonLoading";

const OnboardingPage: React.FC<{
  children: React.ReactNode;
  error: string;
  title: string;
  headline: string;
  message: string;
  setError: (error: string) => void;
  isLoading: boolean;
  progress: number;
  didSkip?: () => void;
}> = ({
  children,
  error,
  title,
  headline,
  message,
  isLoading,
  setError,
  progress,
  didSkip,
}) => {
  const auth = useAuth();

  const handleLogOut = async () => {
    await auth.signOut();
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col justify-between">
      {isLoading && <CommonLoading />}
      <FailureModal
        title={"Something went wrong"}
        message={`${error}`}
        backButtonTitle={"Back to onboarding"}
        shows={error != ""}
        closed={() => setError("")}
      />
      <div className="flex px-4 pt-24 flex-col w-full items-center gap-4 text-center">
        <div className="flex flex-col space-y-6 sm:space-y-4 gap-4">
          <div className="text-gray-500 text-xl font-normal leading-loose">
            {title}
          </div>
          <div className="text-gray-900 text-4xl md:text-6xl font-medium font-gooper leading-10">
            {headline}
          </div>
          <div className="text-gray-500 text-xl font-normal leading-loose">
            {message}
          </div>
        </div>
        {children}
        {didSkip && (
          <button
            className="mt-16 text-center text-gray-500 text-sm font-normal leading-tight bg-transparent border-none cursor-pointer"
            onClick={didSkip}
          >
            Skip survey
          </button>
        )}
      </div>
      <div className="flex flex-row pb-4 flex-nowrap justify-center items-center text-gray-400 px-16 w-full">
        <button
          className="text-sm text-left font-medium flex-1 flex-grow"
          onClick={() => handleLogOut()}
        >
          Log out
        </button>
        <div className="flex-1 flex-grow mx-4">
          <div className="h-1 w-full max-w-xs mx-auto rounded-sm bg-gray-200">
            <div
              style={{ width: `${progress}%` }}
              className="h-full rounded-sm bg-green-600"
            ></div>
          </div>
        </div>
        <div className="text-sm text-right flex-1 flex-grow">
          Trying to join a team? Check your emails.
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;
