import Icon, { IconType } from "../Icon";

export const CredentialInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
}> = ({ value, onChange, disabled, placeholder }) => {
  const handleOnChange = (newValue: string) => {
    onChange(newValue);
  };
  const containsWhitespace = /\s/.test(value);

  let rightIconType: IconType = "share-nodes";
  let iconClassName = "text-gray-300";
  if (value.length > 0 && containsWhitespace) {
    rightIconType = "alert";
    iconClassName = "text-red-500";
  } else if (value.length > 0) {
    rightIconType = "check-line";
    iconClassName = "text-green-500";
  }
  return (
    <div className="w-full gap-2 flex flex-col bg-gray-0 rounded-lg">
      <div className="relative items-center justify-center flex w-full">
        <Icon
          type={rightIconType}
          className={`${iconClassName} absolute right-0 mr-4 text-gray-300 transition-all duration-200`}
        />
        <input
          className={`border border-gray-200 rounded-md p-2 leading-tight  text-sm focus:ring-0 focus:outline-none h-10 w-full`}
          value={value}
          onChange={(value) => handleOnChange(value.target.value)}
          disabled={disabled}
          type={"text"}
          placeholder={placeholder}
          id={"credentialInput"}
        />
      </div>
    </div>
  );
};
