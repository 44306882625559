import { Workflow } from "../../../../models/Workflow";
import { PortalNodeData } from "../../Map/Nodes/PortalNode";
import { useState } from "react";
import { Team } from "../../../../models/Team";
import { ChatConfigurationTemplate } from "../../../../models/ChatConfigurationTemplate";
import { TabComponent } from "../../TabComponent";
import { SidebarPortalDetail } from "./SidebarPortalDetail";
import { SidebarPortalInputs } from "./SidebarPortalInputs";
import { PortalDemoModal } from "./PortalDemoModal";
import AnimatedButton, { AnimationState } from "../../../AnimatedButton";
import { useReactFlow } from "reactflow";

type PortalTab = "detail" | "inputs";

export const SidebarPortalContainer: React.FC<{
  workflow: Workflow;
  team: Team;
  setTeam: (team: Team) => void;
  data: PortalNodeData;
  templates: ChatConfigurationTemplate[];
}> = ({ workflow, team, data, templates, setTeam }) => {
  const { setNodes } = useReactFlow();

  const [currentTab, setCurrentTab] = useState<PortalTab>("detail");
  const [demoState, setDemoState] = useState<AnimationState>("ready");
  const [showDemoModal, setShowDemoModal] = useState(false);

  const updateNode = (newData: Partial<PortalNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  const content = (): React.ReactNode => {
    switch (currentTab) {
      case "detail":
        return (
          <SidebarPortalDetail
            team={team}
            data={data}
            templates={templates}
            setTeam={setTeam}
          />
        );
      case "inputs":
        return <SidebarPortalInputs workflow={workflow} data={data} />;
    }
  };

  return (
    <div className="flex flex-col gap-2 -mt-2">
      {data.currentVersion && (
        <PortalDemoModal
          shows={showDemoModal}
          version={data.currentVersion}
          variables={data.demoVariables ?? {}}
          setVariables={(demoVariables) => updateNode({ demoVariables })}
          team={team}
          setShows={setShowDemoModal}
        />
      )}
      <TabComponent
        tabs={[
          { id: "detail", label: "Detail" },
          {
            id: "inputs",
            label: "Inputs",
            disabled: data.currentVersion == undefined,
          },
        ]}
        onTabSelect={(tab) => setCurrentTab(tab as PortalTab)}
        selected={currentTab}
      />
      {data.currentVersion && (
        <AnimatedButton
          title="Demo Portal"
          buttonState={demoState}
          setButtonState={setDemoState}
          onClick={() => setShowDemoModal(true)}
          style="secondary"
          disabled={data.currentVersion.prompt == ""}
          font="font-sans font-medium"
          leftIcon="play"
        />
      )}
      {content()}
    </div>
  );
};
