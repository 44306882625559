import { createContext, useContext } from "react";
import { AuthService } from "../services/AuthService";

const AuthContext = createContext<AuthService | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthContext;
