import { createContext, useContext } from "react";
import { UserEventService } from "../services/UserEventService";

const UserEventContent = createContext<UserEventService | undefined>(undefined);

export const useUserEvent = () => {
  const context = useContext(UserEventContent);
  if (!context) {
    throw new Error("useEvent must be used within an UserEventProvider");
  }
  return context;
};

export default UserEventContent;
